
import { Question } from "shared-alva/models";
import { Component, Watch, Vue, Prop, Emit } from "vue-property-decorator";
import MultipleChoice from "./choices/MultipleChoice.vue";
import GapTest from "./choices/GapTest.vue";
import ChooseOne from "./choices/ChooseOne.vue";
import OsdiSnackbar from "shared-alva/components/snackbars/OsdiSnackbar.vue";
import PublishDialog from "../PublishDialog.vue";
import QuestionModule from "@/store/modules/questions-store";
import { VContainer, VRow, VCol, VToolbar, VBtn, VIcon } from "vuetify/lib";
import { getModule } from "vuex-module-decorators";
import Sorting from "./choices/Sorting.vue";
import OsdiButton from "osdi-design-system/molecules/button/button.vue";
import OsdiConfirmDialog from "shared-alva/components/dialogs/OsdiConfirmDialog.vue";
import { ROUTES } from "@/router";
import _ from "lodash";
import { first } from "lodash";
import { deleteQuestion } from "shared-alva/api/graphql/mutations";

@Component({
  components: {
    OsdiButton,
    MultipleChoice,
    OsdiConfirmDialog,
    ChooseOne,
    GapTest,
    OsdiSnackbar,
    PublishDialog,
    Sorting,
  },
})
export default class QuestionDetails extends Vue {
  question = {} as Question;
  language = this.$route.query.language as string;
  version = this.$route.query.version as string;
  id = this.$route.params.questionId as string;
  questionsStore = getModule(QuestionModule);
  initialQuestion: Question = {} as Question;

  public save: boolean = false;
  loaded = false;
  canBePublished = false;
  canBeSaved = false;
  snackMessage = "";
  showPublish = false;

  $refs!: {
    confirmDialog: OsdiConfirmDialog;
    snackbar: OsdiSnackbar;
  };

  async created() {
    const question = await this.questionsStore.getQuestion({
      id: this.id,
      language: this.language,
      version: this.version,
      owner: {
        siteId: "global",
        tenant: "fettecompacting",
      },
    });
    this.question = question as Question;
    if (this.question.status !== "published") {
      this.canBePublished = true;
    }
    this.initialQuestion = _.cloneDeep(this.question);
  }

  public onCloseDialog() {
    if (this.initialQuestion.status != this.question.status || this.canBeSaved) {
      this.$refs.confirmDialog.open();
      return;
    }
    this.close();
  }

  @Emit()
  public async close() {
    setTimeout(() => {
      this.$router.push({ name: ROUTES.SmartCollegeQuestions });
    }, 200);
  }

  async saveAndClose() {
    await this.saveQuestion();
    await this.close();
  }

  publish() {
    this.showPublish = true;
  }

  onPublishCanceled() {
    this.showPublish = false;
  }
  onPublished() {
    this.showPublish = false;
    this.onCloseDialog();
  }

  async saveQuestion() {
    this.save = !this.save;
    this.canBeSaved = false;
    this.canBePublished = true;
  }

  async deleteQuestion() {
    await this.questionsStore.deleteQuestion({
      owner: this.question.owner,
      id: this.question.id,
      language: this.question.language,
      version: this.question.version,
    });
    await this.close();
  }

  onLoaded() {
    this.loaded = true;
    this.canBeSaved = false;
  }

  onChanged() {
    if (!this.loaded) {
      return;
    }
    this.canBeSaved = true;
  }
}
