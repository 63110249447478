
import { Component, Emit, Vue, Watch, VModel } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import DialogStore from "@/store/modules/dialog-store";
import { TrainingElementInterface, Quiz, Question } from "shared-alva/models";
import SublessonsModule from "@/store/modules/sublessons-store_v2";
import LessonsModule from "@/store/modules/lessons-store_v2";
import TrainingsModule from "@/store/modules/trainings-store_v2";
import LearningPathModule from "@/store/modules/learningPath-store";
import { languages, availableLanguages } from "shared-alva/languages";

import { ROUTES } from "@/router";
import QuizModule from "@/store/modules/quiz-store";
import QuestionModule from "@/store/modules/questions-store";
import { VProgressLinear, VBtn, VIcon, VSelect, VRadioGroup, VRadio, VTooltip } from "vuetify/lib";

const dialogStore = getModule(DialogStore);

interface NewVersionDialogProps {
  baseElement: TrainingElementInterface & { type: string; title: string };
  translate: boolean;
}

@Component({
  components: {},
})
export default class NewVersionDialog extends Vue {
  public quizStore = getModule(QuizModule);
  public questionStore = getModule(QuestionModule);
  @VModel()
  props!: NewVersionDialogProps;

  draftExists = false;

  get baseElement() {
    return this.props.baseElement;
  }

  get translate() {
    return this.props.translate;
  }

  get allLanguages() {
    return availableLanguages;
  }

  get elementType(): string {
    if (this.baseElement.type == "lesson") return "Lesson";
    if (this.baseElement.type == "sublesson") return "Sublesson";
    if (this.baseElement.type == "training") return "Training";
    if (this.baseElement.type == "quizzes") return "Quizzes";
    if (this.baseElement.type == "question") return "Question";
    return this.baseElement.type;
  }

  get baseVersion(): string {
    if (this.translate) return this.latestExistingVersion;
    else return this.baseElement.version;
  }

  get isLoading(): boolean {
    return TrainingsModule.isLoading || SublessonsModule.isLoading || LessonsModule.isLoading;
  }

  @Watch("newLanguage", { immediate: true })
  async fetchLatestVersion() {
    if (this.elementType == "Lesson") {
      const lessons = await LessonsModule.getAllLessonVersions(this.newLanguage as languages);
      this.latestVersion = lessons.find((el) => el.id == this.baseElement.id)?.latest;
    }
    if (this.elementType == "Sublesson") {
      const sublessons = await SublessonsModule.getAllSublessonVersions(
        this.newLanguage as languages
      );
      this.latestVersion = sublessons.find((el) => el.id == this.baseElement.id)?.latest;
    }
    if (this.elementType == "Training") {
      const trainings = await TrainingsModule.getAllTrainingVersions(this.newLanguage as languages);
      this.latestVersion = trainings.find((el) => el.id == this.baseElement.id)?.latest;
    }
    if (this.elementType == "Quizzes") {
      const quizzes = await this.quizStore.getAllQuizzesVersions(this.newLanguage as languages);
      this.latestVersion = quizzes.find((el) => el.id == this.baseElement.id)?.latest;
    }
    if (this.elementType == "Question") {
      const questions = await this.questionStore.getAllQuestionsVersions(
        this.newLanguage as languages
      );
      this.latestVersion = questions.find((el) => el.id == this.baseElement.id)?.latest;
    }
    this.draftExists = !this.latestVersion
      ? false
      : (this.latestVersion?.status ?? "") !== "published";
  }

  get latestExistingVersion(): string {
    return this.latestVersion?.version || "0.0";
  }

  radioVersionType: string = "";
  newLanguage: string = "";
  private latestVersion: TrainingElementInterface | undefined = {
    owner: { tenant: "", siteId: "" },
    id: "",
    language: "",
    version: "",
  };

  get majorPreview() {
    const [major, minor] = this.baseVersion.split(".");
    return `${parseInt(major) + 1}.0`;
  }

  get minorPreview() {
    const [major, minor] = this.baseVersion.split(".");
    return `${major}.${parseInt(minor) + 1}`;
  }

  get newVersion() {
    if (this.radioVersionType == "major") return this.majorPreview;
    else if (this.radioVersionType == "minor") return this.minorPreview;
    else return "";
  }

  async createNewVersion() {
    const params = {
      owner: this.baseElement.owner,
      id: this.baseElement.id,
      srcLang: this.baseElement.language as languages,
      srcVersion: this.baseElement.version,
      newLang:
        this.newLanguage != ""
          ? (this.newLanguage as languages)
          : (this.baseElement.language as languages),
      newVersion: this.newVersion,
    };

    if (this.elementType == "Lesson") {
      await LessonsModule.createLessonDraft(params);
      this.$router.push({
        name: ROUTES.SmartCollegeLessonDetail,
        params: {
          lessonId: params.id,
        },
        query: {
          language: params.newLang,
          version: params.newVersion,
        },
      });
      this.close();
    } else if (this.elementType == "Training") {
      await TrainingsModule.createTrainingDraft(params);
      this.$router.push({
        name: ROUTES.SmartCollegeTrainingDetail,
        params: {
          trainingId: params.id,
        },
        query: {
          language: params.newLang,
          version: params.newVersion,
        },
      });
      this.close();
    } else if (this.elementType == "Sublesson") {
      await SublessonsModule.createSublessonDraft(params);
      this.$router.push({
        name: ROUTES.SmartCollegeSublessonDetail,
        params: {
          sublessonId: params.id,
        },
        query: {
          language: params.newLang,
          version: params.newVersion,
        },
      });
      this.close();
    } else if (this.elementType == "Quizzes") {
      const quizzes = await this.quizStore.quizList[this.baseElement.language];
      const quiz = quizzes
        .find((q) => q.id == this.baseElement.id)
        ?.allVersions.find((v) => v.version == this.baseElement.version) as Quiz;
      if (!quiz) return;
      const quizParams = {
        title: quiz.title,
        description: quiz.description,
        element: quiz.element,
        owner: this.baseElement.owner,
        id: this.baseElement.id,
        questions: quiz.questions,
        quizLength: quiz.quizLength,
        status: "draft",
        language: params.newLang,
        version: params.newVersion,
        type: "quiz",
        lastUpdated: 0,
      } as Quiz;
      await this.quizStore.saveQuiz(quizParams);
      this.$router.push({
        name: ROUTES.SmartCollegesQuizDetails,
        params: {
          quizId: params.id,
        },
        query: {
          language: params.newLang,
          version: params.newVersion,
        },
      });
      this.close();
    } else if (this.elementType == "Question") {
      const questions = await this.questionStore.questionList[this.baseElement.language];
      const question = questions
        .find((q) => q.id == this.baseElement.id)
        ?.allVersions.find((v) => v.version == this.baseElement.version) as Question;

      if (!question) return;

      const questionParams = {
        owner: this.baseElement.owner,
        id: this.baseElement.id,
        status: "draft",
        version: params.newVersion,
        language: params.newLang,
        element: question.element,
        title: question.title,
        text: question.text,
        description: question.description,
        actionLabel: question.actionLabel,
        type: question.type,
        lastUpdated: 0,
        choices: question.choices,
        points: question.points,
        media: question.media,
        references: question.references,
      } as Question;

      await this.questionStore.saveQuestion(questionParams);
      this.$router.push({
        name: ROUTES.SmartCollegeQuestionDetails,
        params: {
          questionId: params.id,
        },
        query: {
          language: params.newLang,
          version: params.newVersion,
        },
      });

      this.close();
    }
  }

  @Emit()
  public close() {}
  public onCloseDialog() {
    this.$emit("close");
  }
}
