
import { Component, Vue } from "vue-property-decorator";
import { Auth } from "@aws-amplify/auth";
import { getModule } from "vuex-module-decorators";
import AuthModule, { AuthenticationState } from "@/store/modules/auth-store";
import OsdiButton from "shared-alva/components/buttons/OsdiButton.vue";
import OsdiTextField from "shared-alva/components/inputs/OsdiTextField.vue";
import OsdiMailField from "shared-alva/components/inputs/OsdiMailField.vue";
import OsdiPasswordField from "shared-alva/components/inputs/OsdiPasswordField.vue";

const authStore = getModule(AuthModule);

@Component({
  components: { OsdiButton, OsdiTextField, OsdiPasswordField, OsdiMailField },
})
export default class ResetPassword extends Vue {
  private username = "";
  private password = "";
  private code = "";
  private valid = false;
  private error = "";
  private codeSent = false;
  private snackbar = false;
  private emailRules = [(v: string) => !!v || "required"];
  private codeRules = [(v: string) => !!v || "required"];
  private newPasswordRules = [(v: string) => !!v || "required"];

  requestCode() {
    this.snackbar = true;
    this.doRequestCode();
  }

  async doRequestCode() {
    try {
      await Auth.forgotPassword(this.username.toLowerCase());
      this.codeSent = true;
      this.error = "";
    } catch (error) {
      this.codeSent = false;
      this.error = this.$t("auth.errors.couldNotSendCode").toString();
    }
  }

  resetPassword() {
    this.doResetPassword();
  }

  async doResetPassword() {
    try {
      await Auth.forgotPasswordSubmit(this.username.toLowerCase(), this.code, this.password);
      authStore.updateAuthn(AuthenticationState.signIn);
    } catch (error) {
      this.error = this.$t("auth.errors.couldNotVerify").toString();
    }
  }
}
