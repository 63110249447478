import logger from "@/logger";
import { getCurrentAuthenticatedUser } from "@/authentication/authentication.service";
import store from "@/store/index";
import type { CognitoUser } from "@aws-amplify/auth";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import { ProductPermissions } from "shared-alva/models";
import { MiscAPI } from "shared-alva";
import awsconfig from "@/aws-config";

export enum AuthenticationState {
  forgotPassword = "forgotPassword",
  signIn = "signIn",
  requireNewPassword = "requireNewPassword",
  signedOut = "signedOut",
  signedIn = "signedIn",
}

@Module({ dynamic: true, store, name: "auth" })
export default class AuthModule extends VuexModule {
  private miscApi: MiscAPI = new MiscAPI(awsconfig.API_V2);

  loggedInUser: (CognitoUser & { attributes: any }) | null = null;
  authenticationState: AuthenticationState = AuthenticationState.signedOut;
  userPermission: ProductPermissions | null = null;
  changelog: any = null;

  get isLoggedIn(): boolean {
    return this.loggedInUser !== null && this.authenticationState === AuthenticationState.signedIn;
  }

  get user(): (CognitoUser & { attributes: any }) | null {
    return this.loggedInUser;
  }

  get authnState(): AuthenticationState {
    return this.authenticationState;
  }

  get userPermissions(): string[] {
    return this.userPermission?.permissions || [];
  }

  get groups(): { [key: string]: any } {
    return this.loggedInUser?.getSignInUserSession()?.getIdToken().payload["cognito:groups"];
  }

  get tenant(): string {
    const tenantString = this.groups.find((g: string) => {
      return g.includes("tenant:");
    });
    return tenantString.substring(tenantString.indexOf(":") + 1);
  }

  @Mutation
  login(cognitoUser: (CognitoUser & { attributes: any }) | null) {
    this.loggedInUser = cognitoUser;
  }

  @Mutation
  logout() {
    this.loggedInUser = null;
    this.authenticationState = AuthenticationState.signedOut;
    this.userPermission = null;
  }

  @Mutation
  updateAuthn(authnState: AuthenticationState) {
    this.authenticationState = authnState;
  }

  @Mutation
  SET_PERMISSIONS(userPermission: ProductPermissions) {
    this.userPermission = userPermission;
  }

  @Action
  async reloadUser() {
    try {
      const currentUser = await getCurrentAuthenticatedUser();
      this.context.commit("login", currentUser);
      this.context.commit("updateAuthn", AuthenticationState.signedIn);
    } catch (error) {
      logger.error("Could not find a logged in user.");
      this.context.commit("logout");
    }
  }

  @Action({ commit: "SET_PERMISSIONS" })
  async requestUserPermissions() {
    try {
      const userPermissions = (await this.miscApi.getUserPermissions("AlvaEditor")) || [];
      return userPermissions.find((userPermission) => userPermission.product === "AlvaEditor");
    } catch (err) {
      logger.error("No Permissions");
    }
  }
}
