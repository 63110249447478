import { Module, getModule, VuexModule, Mutation, Action } from "vuex-module-decorators";
import awsconfig from "@/aws-config";
import store from "@/store/index";
import { QuizAPI } from "shared-alva/api/quiz";
import {
  MediaEntity,
  Owner,
  Question,
  QuestionFilter,

  
  TrainingElementReference,
} from "shared-alva/models";
import { languages } from "shared-alva/languages";
import { GroupedTrainingElements } from "@/models";
import { groupElements, groupElementVersions } from "@/utils";

@Module({ dynamic: true, namespaced: true, store, name: "questions" })
class QuestionModule extends VuexModule {
  private client = new QuizAPI(awsconfig.API_V2);
  private questions: { [quizId: string]: Question } = {};
  public isLoading = false;
  private allQuestions = new Map<string, { [questionId: string]: Question }>();

  // state
  public questionList: GroupedTrainingElements = {};

  get getQuestions() {
    return this.questions;
  }

  get getLoadingStatus() {
    return this.isLoading;
  }

  get getAllQuestions() {
    return this.allQuestions;
  }

  @Mutation
  SET_LOADING(loading: boolean) {
    this.isLoading = loading;
  }

  @Mutation
  SET_QUESTION_LIST({ questionList }: { questionList: GroupedTrainingElements }) {
    this.questionList = questionList;
  }

  @Action({})
  async fetchQuestion(question: TrainingElementReference) {
    this.SET_LOADING(true);
    const questionData = await this.client.getQuestion(question);

    this.SET_LOADING(false);
    return questionData;
  }

  @Action({ rawError: true })
  async getQuestion(question: TrainingElementReference) {
    const questions = this.questionList[question.language];

    if (!questions) {
      return await this.fetchQuestion(question);
    }

    const filteredQuestion = questions.find((el) => {
      return el.id == question.id;
    });

    if (!filteredQuestion) {
      return await this.fetchQuestion(question);
    }

    const targetVersion = filteredQuestion.allVersions.find((el) => {
      return el.version == question.version;
    });

    if (targetVersion) {
      return targetVersion;
    }
  }

  @Action
  async getAllQuestionsVersions(language: languages) {
    if (!language) return [];

    if (!(language in this.questionList)) {
      this.SET_LOADING(true);
      await this.fetchQuestions(language);
    }
    this.SET_LOADING(false);
    return this.questionList[language];
  }

  @Action({ rawError: true })
  async fetchQuestions(language: languages) {
    const owner = { tenant: "fettecompacting", siteId: "global" };
    const questionFilter: QuestionFilter = {
      language,
      owner,
    };
    this.SET_LOADING(true);
    const questions = (await this.client.getQuestions(questionFilter)) || [];

    const questionList = { ...this.questionList };

    questionList[language] = await this.groupElements({ questions });

    this.SET_QUESTIONS({ questions: questions || [] });
    this.SET_QUESTION_LIST({ questionList });
    this.SET_LOADING(false);
  }

  @Action({ rawError: true })
  groupElements({ questions }: { questions: Question[] }) {
    return groupElements<Question>({ elements: questions });
  }

  @Action
  async saveQuestion(question: Question) {
    question.points = 1;
    if (!question.description) {
      question.description = "";
    }
    if (!question.language) {
      question.language = "en";
    }
    if (!question.status) {
      question.status = "draft";
    }
    let media: MediaEntity | undefined = undefined;
    const choices = [...question.choices];
    if (question.media) {
      const { signedThumbnailUrl, signedUrl, ...mediaWithoutSigned } = question.media!;
      media = mediaWithoutSigned;
      delete media.signedThumbnailUrl;
      delete media.signedUrl;
    }

    choices.forEach((choice) => {
      if (choice.mediaValue) {
        delete choice.mediaValue.signedThumbnailUrl;
        delete choice.mediaValue.signedUrl;
      }
      if (!choice.status) {
        choice.status = question.status;
      }
    });

    let success = false;
    if (question.status !== "published") {
      success = await this.client.saveQuestion({
        ...question,
        media: media,
        choices: choices,
      });
    } else {
      // TODO - implement modal for publishing
      success = await this.client.publishQuestion(
        {
          id: question.id,
          owner: question.owner,
          version: question.version,
          language: question.language,
        } as TrainingElementReference,
        "",
        ""
      );
    }

    if (success && this.questionList[question.language]) {
      this.ADD_QUESTION(question);
      const currentQuestionList = { ...this.questionList };
      const questionsForLanguage = currentQuestionList[question.language];
      const questionVersionsIndex = questionsForLanguage.findIndex((el) => el.id === question.id);

      if (questionVersionsIndex == -1) {
        questionsForLanguage.push({
          allVersions: [question],
          id: question.id,
          latest: question,
          draft: question,
          publishedVersions: {},
        });
        this.SET_QUESTION_LIST({ questionList: currentQuestionList });
      } else {
        const newQuestions = questionsForLanguage[questionVersionsIndex].allVersions.filter(
          (el) => {
            return el.version != question.version;
          }
        );
        newQuestions.push(question);
        questionsForLanguage[questionVersionsIndex] = await groupElementVersions<Question>({
          id: question.id,
          language: question.language as languages,
          elements: newQuestions as Question[],
        });
        this.SET_QUESTION_LIST({ questionList: currentQuestionList });
      }
    }
  }

  @Action
  async publishQuestion({
    reference,
    changelogMessage,
    editorEmail,
  }: {
    reference: TrainingElementReference;
    changelogMessage: string;
    editorEmail: string;
  }) {
    const { id, language, version } = reference;
    this.SET_LOADING(true);
    const success = await this.client.publishQuestion(reference, changelogMessage, editorEmail);

    if (success && this.questionList[language]) {
      // Update full sublessons

      const question = this.questions[reference.id];

      if (!question) {
        this.SET_LOADING(false);
        return;
      }

      question.status = "published";
      this.ADD_QUESTION(question);

      // update grouped sublesson list
      const currentQuestionList = { ...this.questionList } || {};
      const questionForLanguage = currentQuestionList[language] || [];
      const questionVersionsIndex = questionForLanguage.findIndex((el) => {
        return el.id == id;
      });

      if (questionVersionsIndex == -1) {
        this.SET_LOADING(false);
        return;
      } else {
        delete questionForLanguage[questionVersionsIndex].draft;
      }
      questionForLanguage[questionVersionsIndex].publishedVersions[version] = question;
      delete questionForLanguage[questionVersionsIndex].draft;
      this.SET_QUESTION_LIST({ questionList: currentQuestionList });
    }
    this.SET_LOADING(false);
  }

  @Action
  async removeQuestion({
    id,
    language,
    version,
  }: {
    id: string;
    language: languages | string;
    version: string;
  }) {
    // Make query call
    //const success = await this.client.deleteQuestion(questionId);
    const question = this.questions[id];
    const success = await this.client.deleteQuestion(
      question.id,
      question.owner,
      language,
      version
    );

    if (success) {
      // const currentQuestionList = { ...this.questionList };
      // const questionsForLanguage = currentQuestionList[language] || [];
      // const questionVersionsIndex = questionsForLanguage.findIndex((el) => {
      //   return el.id == id;
      // });

      // if (questionVersionsIndex == -1) return;
      // else {
      //   questionsForLanguage[questionVersionsIndex].allVersions = questionsForLanguage[
      //     questionVersionsIndex
      //   ].allVersions.filter((e) => {
      //     return e.id != id && e.language != language && e.version != version;
      //   });
      //   delete questionsForLanguage[questionVersionsIndex].draft;
      //   if (questionsForLanguage[questionVersionsIndex].allVersions.length == 0) {
      //     questionsForLanguage.splice(questionVersionsIndex, 1);
      //   }
      // }
      // this.SET_QUESTION_LIST({ questionList: currentQuestionList });
      this.fetchQuestions(language as languages);
    }
  }

  @Action
  getAvailableVersions(question: TrainingElementReference): string[] {
    const allVersions = this.questionList[question.language].find((el) => {
      return el.id == question.id;
    });
    const draftVersions = allVersions?.draft?.version ? [allVersions.draft.version] : [];
    const publishedVersions = Object.keys(allVersions?.publishedVersions || {});
    return [...publishedVersions, ...draftVersions];
  }

  @Action
  getAllPublishedVersions(question: TrainingElementReference) {
    const allVersions = this.questionList[question.language].find((el) => {
      return el.id == question.id;
    });
    return Object.keys(allVersions?.publishedVersions || {});
  }

  @Mutation
  REMOVE_QUESTION(questionId: string) {
    const questionCopy = { ...this.questions };
    delete questionCopy[questionId];
    this.questions = questionCopy;
  }

  @Mutation
  ADD_QUESTION(question: Question) {
    const questionCopy = { ...this.questions };

    questionCopy[question.id] = question;
    this.questions = questionCopy;
  }

  @Mutation
  SET_QUESTIONS({ questions, language }: { questions: Question[]; language?: string }) {
    const questionsCopy = { ...this.questions };
    if (questions.length > 0) {
      questions.forEach((question) => {
        questionsCopy[question.id] = question;
      });
      this.questions = questionsCopy;
    } else {
      if (language) {
        const allCopy = new Map(this.allQuestions);
        allCopy.set(language, { ...this.questions });
        this.allQuestions = allCopy;
      }
      this.questions = {};
    }
  }

  @Action
  async deleteQuestion({
    owner,
    id,
    language,
    version,
  }: {
    owner: Owner;
    id: string;
    language: languages | string;
    version: string;
  }) {
    this.SET_LOADING(true);

    const success = await this.client.deleteQuestion(id, owner, language, version);

    if (success) {
      const currentQuestionList = { ...this.questionList } || {};
      const questionsForLanguage = currentQuestionList[language] || [];
      const questionVersionsIndex = questionsForLanguage.findIndex((el) => {
        return el.id == id;
      });

      if (questionVersionsIndex == -1) return;

      questionsForLanguage[questionVersionsIndex].allVersions = questionsForLanguage[
        questionVersionsIndex
      ].allVersions.filter((e) => {
        return e.id != id && e.language != language && e.version != version;
      });
      delete questionsForLanguage[questionVersionsIndex].draft;
      if (questionsForLanguage[questionVersionsIndex].allVersions.length == 0) {
        questionsForLanguage.splice(questionVersionsIndex, 1);
      }

      this.SET_QUESTION_LIST({ questionList: currentQuestionList });
    }

    this.SET_LOADING(false);
  }
}

export default QuestionModule;
