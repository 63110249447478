import { Module, getModule, VuexModule, Mutation, Action } from "vuex-module-decorators";
import store from "@/store/index";
import { Owner, Sublesson, SublessonMetadata, TrainingElementReference } from "shared-alva/models";
import { languages } from "shared-alva/languages";
import { SublessonAPI } from "shared-alva";
import { GroupedTrainingElements, TrainingElementVersions } from "@/models";
import awsconfig from "@/aws-config";
import * as semver from "semver";

@Module({ dynamic: true, namespaced: true, store, name: "sublessons_v2" })
class SublessonsModule_v2 extends VuexModule {
  private client = new SublessonAPI(awsconfig.API_V2);

  // State
  public sublessons: Sublesson[] = [];
  public sublessonList: GroupedTrainingElements = {};
  public isLoading = false;

  // Mutations
  @Mutation
  setSublessons(sublessons: Sublesson[]) {
    this.sublessons = sublessons;
  }

  @Mutation
  setSublessonList(sublessonList: GroupedTrainingElements) {
    this.sublessonList = sublessonList;
  }

  @Mutation
  setLoading(loading: boolean) {
    this.isLoading = loading;
  }

  // Actions

  @Action
  getAvailableVersions(sublesson: TrainingElementReference): string[] {
    const allVersions = this.sublessonList[sublesson.language].find((el) => {
      return el.id == sublesson.id;
    });
    const draftVersions = allVersions?.draft?.version ? [allVersions.draft.version] : [];
    const publishedVersions = Object.keys(allVersions?.publishedVersions || {});
    return [...publishedVersions, ...draftVersions];
  }

  @Action
  getSublessonById(id: string) {
    return this.sublessons.find((el) => {
      return el.id == id;
    });
  }

  @Action
  getAvailableSublessonIds(language: languages): string[] {
    return this.sublessonList[language].map((el) => {
      return el.id;
    });
  }

  @Action({ commit: "setSublessonList" })
  async fetchSublessons(params: { owner: Owner; language: languages; isPublished: boolean }) {
    this.setLoading(true);
    const sublessons = await this.client.getSublessons(
      params.owner,
      params.language,
      params.isPublished
    );
    const sublessonList = { ...this.sublessonList };
    sublessonList[params.language] = await this.groupSublessons({ sublessons });
    this.setLoading(false);
    return sublessonList;
  }

  @Action
  async getAllSublessonVersions(language: languages): Promise<TrainingElementVersions[]> {
    if (!language) return [];
    if (!(language in this.sublessonList)) {
      await this.fetchSublessons({
        owner: { tenant: "fettecompacting", siteId: "global" },
        language: language,
        isPublished: false,
      });
    }
    return this.sublessonList[language];
  }

  @Action
  async createSublessonDraft({
    owner,
    id,
    srcLang,
    srcVersion,
    newLang,
    newVersion,
  }: {
    owner: Owner;
    id: string;
    srcLang: languages;
    srcVersion: string;
    newLang: languages;
    newVersion: string;
  }) {
    this.setLoading(true);
    const success = await this.client.createSublessonDraft(
      owner,
      id,
      srcLang,
      srcVersion,
      newLang,
      newVersion
    );

    if (success) {
      const srcSublesson = this.sublessonList[srcLang]
        .find((el: TrainingElementVersions) => {
          return el.id == id;
        })
        ?.allVersions.find((el) => {
          return el.version == srcVersion;
        });

      if (srcSublesson) {
        const newSublesson = {
          ...srcSublesson,
          language: newLang,
          version: newVersion,
        } as SublessonMetadata;

        const currentSublessonList = { ...this.sublessonList } || {};
        const sublessonsForNewLang = currentSublessonList[newLang] || [];
        const sublessonVersionsIndex = sublessonsForNewLang.findIndex((el) => {
          return el.id == id;
        });

        if (sublessonVersionsIndex == -1) {
          sublessonsForNewLang.push(
            this.groupSublessonVersions({ id, language: newLang, sublessons: [newSublesson] })
          );
        } else {
          sublessonsForNewLang[sublessonVersionsIndex] = this.groupSublessonVersions({
            id,
            language: newLang,
            sublessons: sublessonsForNewLang[sublessonVersionsIndex]
              .allVersions as SublessonMetadata[],
          });
        }

        this.setSublessonList(currentSublessonList);
      }
    }
    this.setLoading(false);
    return success;
  }

  @Action
  async saveSublesson(sublesson: Sublesson) {
    const success = await this.client.saveSublesson(sublesson);

    if (success) {
      // Update complete sublesson
      const sublessonIndex = this.sublessons.findIndex((t) => {
        return (
          t.id == sublesson.id && t.language == sublesson.language && t.version == sublesson.version
        );
      });
      const newSublessons = [...this.sublessons];
      if (sublessonIndex != -1) {
        newSublessons[sublessonIndex] = sublesson;
      } else newSublessons.push(sublesson);
      this.setSublessons(newSublessons);

      // update grouped sublesson list
      const currentSublessonList = { ...this.sublessonList } || {};
      const sublessonsForLanguage = currentSublessonList[sublesson.language] || [];
      const sublessonVersionsIndex = sublessonsForLanguage.findIndex((el) => {
        return el.id == sublesson.id;
      });

      if (sublessonVersionsIndex == -1) {
        sublessonsForLanguage.push({
          allVersions: [sublesson],
          id: sublesson.id,
          draft: sublesson,
          latest: sublesson,
          publishedVersions: {},
        });
        this.setSublessonList(currentSublessonList);
      } else {
        const newSublessons = sublessonsForLanguage[sublessonVersionsIndex].allVersions.filter(
          (el) => {
            return el.version != sublesson.version;
          }
        );
        newSublessons.push(sublesson);
        sublessonsForLanguage[sublessonVersionsIndex] = await this.groupSublessonVersions({
          id: sublesson.id,
          language: sublesson.language as languages,
          sublessons: newSublessons as SublessonMetadata[],
        });
        this.setSublessonList(currentSublessonList);
      }
    }
  }

  @Action
  async deleteSublesson({
    owner,
    id,
    language,
    version,
  }: {
    owner: Owner;
    id: string;
    language: languages | string;
    version: string;
  }) {
    this.setLoading(true);

    const success = await this.client.deleteSublesson({
      owner,
      type: "sublesson",
      id,
      language: language,
      version,
    });

    if (success) {
      // Update full sublessons
      const newSublessons = [...this.sublessons];
      this.setSublessons(
        newSublessons.filter((t) => t.id != id && t.language != language && t.version != version)
      );

      // update grouped sublesson list
      const currentSublessonList = { ...this.sublessonList } || {};
      const sublessonsForLanguage = currentSublessonList[language] || [];
      const sublessonVersionsIndex = sublessonsForLanguage.findIndex((el) => {
        return el.id == id;
      });

      if (sublessonVersionsIndex == -1) return;
      else {
        sublessonsForLanguage[sublessonVersionsIndex].allVersions = sublessonsForLanguage[
          sublessonVersionsIndex
        ].allVersions.filter((e) => {
          return e.id != id && e.language != language && e.version != version;
        });
        delete sublessonsForLanguage[sublessonVersionsIndex].draft;
        if (sublessonsForLanguage[sublessonVersionsIndex].allVersions.length == 0) {
          sublessonsForLanguage.splice(sublessonVersionsIndex, 1);
        }
      }

      this.setSublessonList(currentSublessonList);
    }
    this.setLoading(false);
  }

  @Action
  async getSublesson(sublesson: TrainingElementReference) {
    const sublessonExists = this.sublessons.find(
      (t) =>
        t.id === sublesson?.id &&
        t.language === sublesson?.language &&
        t.version === sublesson?.version
    );

    if (sublessonExists) return sublessonExists;
    else {
      this.setLoading(true);
      const fetchedSublesson = await this.client.getSublesson(sublesson);
      if (fetchedSublesson) {
        this.setSublessons([...this.sublessons, fetchedSublesson]);
      }
      this.setLoading(false);
      return fetchedSublesson;
    }
  }

  @Action
  async fetchSublesson(sublessonId: TrainingElementReference) {
    this.setLoading(true);
    let sublessonExists = (await this.sublessons).find(
      (t) =>
        t.id === sublessonId.id &&
        t.language == sublessonId.language &&
        t.version == sublessonId.version
    );

    if (!sublessonExists) {
      const sublesson = await this.client.getSublesson(sublessonId);
      this.sublessons.push(sublesson!);
      this.setSublessons(this.sublessons);
    }

    sublessonExists = this.sublessons.find(
      (t) =>
        t.id === sublessonId.id &&
        t.language == sublessonId.language &&
        t.version == sublessonId.version
    );

    this.setLoading(false);
    return sublessonExists;
  }

  @Action
  async publishSublesson({
    reference,
    changelogMessage,
    editorEmail,
  }: {
    reference: TrainingElementReference;
    changelogMessage: string;
    editorEmail: string;
  }) {
    const { id, language, version } = reference;
    this.setLoading(true);
    const success = await this.client.publishSublesson(reference, changelogMessage, editorEmail);

    if (success) {
      // Update full sublessons
      const newSublessons = [...this.sublessons];
      const sublesson = newSublessons.find(
        (t) => t.id == id && t.language == language && t.version == version
      );
      if (!sublesson) {
        this.setLoading(false);
        return;
      }
      sublesson.status = "published";
      this.setSublessons(newSublessons);

      // update grouped sublesson list
      const currentSublessonList = { ...this.sublessonList } || {};
      const sublessonsForLanguage = currentSublessonList[language] || [];
      const sublessonVersionsIndex = sublessonsForLanguage.findIndex((el) => {
        return el.id == id;
      });

      if (sublessonVersionsIndex == -1) {
        this.setLoading(false);
        return;
      } else {
        delete sublessonsForLanguage[sublessonVersionsIndex].draft;
      }
      sublessonsForLanguage[sublessonVersionsIndex].publishedVersions[version] = sublesson;
      delete sublessonsForLanguage[sublessonVersionsIndex].draft;
      this.setSublessonList(currentSublessonList);
    }
    this.setLoading(false);
  }

  // Helpers
  @Action({})
  groupSublessons({ sublessons }: { sublessons: SublessonMetadata[] }) {
    const sublessonList: TrainingElementVersions[] = [];
    sublessons.forEach((sublesson) => {
      let sublessonIndex = sublessonList.findIndex((el) => el.id == sublesson.id);
      if (sublessonIndex == -1) {
        sublessonList.push({ id: sublesson.id, publishedVersions: {}, allVersions: [] });
        sublessonIndex = sublessonList.length - 1;
      }

      if (
        sublesson.status == "published" &&
        (!sublessonList[sublessonIndex].latestPublished ||
          semver.gt(
            sublesson.version + ".0",
            sublessonList[sublessonIndex].latestPublished!.version + ".0"
          ))
      ) {
        sublessonList[sublessonIndex].latestPublished = sublesson;
      }

      if (
        !sublessonList[sublessonIndex].latest ||
        semver.gt(sublesson.version + ".0", sublessonList[sublessonIndex].latest!.version + ".0")
      ) {
        sublessonList[sublessonIndex].latest = sublesson;
      }

      if (sublesson.status != "published") {
        sublessonList[sublessonIndex].draft = sublesson;
      } else if (sublesson.status == "published") {
        sublessonList[sublessonIndex].publishedVersions[sublesson.version] = sublesson;
      }

      sublessonList[sublessonIndex].allVersions.push(sublesson);
    });

    return sublessonList;
  }

  @Action
  getAllPublishedVersions(sublesson: TrainingElementReference) {
    const allVersions = this.sublessonList[sublesson.language].find((el) => {
      return el.id == sublesson.id;
    });
    return Object.keys(allVersions?.publishedVersions || {});
  }

  @Action({})
  groupSublessonVersions({
    id,
    language,
    sublessons,
  }: {
    id: string;
    language: languages;
    sublessons: SublessonMetadata[];
  }): TrainingElementVersions {
    const sublessonVersions: TrainingElementVersions = {
      id,
      allVersions: [],
      publishedVersions: {},
    };

    sublessons.forEach((sublesson) => {
      if (sublesson.language != language) return;

      if (
        sublesson.status == "published" &&
        (!sublessonVersions.latestPublished ||
          semver.gt(sublesson.version + ".0", sublessonVersions.latestPublished!.version + ".0"))
      ) {
        sublessonVersions.latestPublished = sublesson;
      }

      if (
        !sublessonVersions.latest ||
        semver.gt(sublesson.version + ".0", sublessonVersions.latest!.version + ".0")
      ) {
        sublessonVersions.latest = sublesson;
      }

      if (sublesson.status !== "published") {
        sublessonVersions.draft = sublesson;
      } else if (sublesson.status == "published") {
        sublessonVersions.publishedVersions[sublesson.version] = sublesson;
      }

      sublessonVersions.allVersions.push(sublesson);
    });

    return sublessonVersions;
  }
}

export default getModule(SublessonsModule_v2);
