
import Vue from "vue";
import { Component, Emit, Prop } from "vue-property-decorator";

@Component
export default class ExpandBtn extends Vue {
  @Prop({ default: false }) readonly open!: boolean;

  @Emit()
  onClick() {}
}
