
import { Component, Vue } from "vue-property-decorator";
import { SaveLearningPath } from "shared-alva/models";
import { languages } from "shared-alva/languages";
import draggable from "vuedraggable";
import Upload from "@/components/sublessons/blocks/Upload.vue";
import ElementPreview from "@/components/ElementPreview.vue";
import ElementDropZone from "@/components/ElementDropZone.vue";
import OsdiButton from "shared-alva/components/buttons/OsdiButton.vue";
import OsdiTooltipButton from "shared-alva/components/buttons/OsdiTooltipButton.vue";
import OsdiTextField from "shared-alva/components/inputs/OsdiTextField.vue";
import OsdiTextArea from "shared-alva/components/inputs/OsdiTextArea.vue";
import OsdiConfirmDialog from "shared-alva/components/dialogs/OsdiConfirmDialog.vue";
import LearningPathModule from "@/store/modules/learningPath-store";
import { v4 as uuid } from "uuid";
import MediaLibrary from "@/components/media/MediaLibrary.vue";
import TargetSettings from "@/components/TargetSettings.vue";
import CreateLayout from "@/components/Layouts/CreateLayout.vue";
import { ROUTES } from "@/router";

@Component({
  components: {
    OsdiTextArea,
    ElementDropZone,
    ElementPreview,
    draggable,
    OsdiTextField,
    OsdiButton,
    OsdiConfirmDialog,
    Upload,
    MediaLibrary,
    TargetSettings,
    OsdiTooltipButton,
    CreateLayout,
  },
})
export default class LearningPathCreate extends Vue {
  valid = false;
  titleRules = [(v: string) => v?.length <= 80 || "max 80"];
  learningPath: SaveLearningPath = {} as SaveLearningPath;
  showMediaLibrary: boolean = false;
  learningPathStore = LearningPathModule;

  async mounted() {
    this.learningPath = {
      id: uuid(),
      title: "",
      status: "draft",
      elements: [],
      targetGroups: [],
      assetTypes: [],
      owner: this.learningPathStore.owner,
      language: languages.en,
      version: "1.0",
      lastUpdated: 0,
    };
  }

  async handleOnSave() {
    await this.learningPathStore.saveLearningPath(this.learningPath);
    this.$router.push({
      name: ROUTES.SmartCollegeLearningPathDetail,
      params: {
        learningPathId: this.learningPath.id,
      },
    });
  }
}
