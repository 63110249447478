
import Vue from "vue";
import { Component, Emit, Prop } from "vue-property-decorator";
import OsdiTextField from "shared-alva/components/inputs/OsdiTextField.vue";
import MachineTypesModule from "@/store/modules/machine-types-store";
import { getModule } from "vuex-module-decorators";
import OsdiConfirmDialog from "shared-alva/components/dialogs/OsdiConfirmDialog.vue";

export interface ParamItemChange {
  text: string;
  itemIndex: number;
}

@Component({ components: { OsdiTextField, OsdiConfirmDialog } })
export default class MachineTypeItem extends Vue {
  @Prop({ required: true }) readonly machineIndex!: number;
  @Prop({ required: true }) readonly machineType!: string;
  machineTypeText = this.machineType;
  machineTypesStore = getModule(MachineTypesModule);
  $refs!: {
    confirmDeleteDialog: OsdiConfirmDialog;
  };

  onClickUp() {
    this.machineTypesStore.swapMachineTypeUp(this.machineIndex);
  }

  onClickDown() {
    this.machineTypesStore.swapMachineTypeDown(this.machineIndex);
  }

  onClickDelete() {
    this.$refs.confirmDeleteDialog.open({
      message: this.$t("smartcollege.machineType.ConfirmDeleteDialog") as string,
      confirmCallback: () => {
        this.machineTypesStore.deleteMachineTypeByIndex(this.machineIndex);
      },
    });
  }

  onChange() {
    this.machineTypesStore.machineTypeChange({
      itemIndex: this.machineIndex,
      text: this.machineTypeText,
    });
  }
}
