
import { Component, Vue, Watch } from "vue-property-decorator";
import Sublessons from "@/store/modules/sublessons-store_v2";
import Lessons from "@/store/modules/lessons-store_v2";
import Trainings from "@/store/modules/trainings-store_v2";
import Media from "@/store/modules/media-store_v2";

import { languages } from "shared-alva/languages";
import { Sublesson, Lesson, Training } from "shared-alva/models";
import Upload from "./MediaUpload.vue";

@Component({ components: { Upload } })
export default class ApiTestpage extends Vue {
  private output = "";
  private id = "TestId";
  private language = "de";
  private version = "1.0";
  private tenant = "fettecompacting";
  private siteId = "global";

  saveSublesson() {
    const sublesson: Sublesson = {
      owner: { tenant: this.tenant, siteId: this.siteId },
      title: "TestTitle",
      element: "TestElement",
      description: "TestDescription",
      durationMinutes: 5,
      targetGroups: [],
      assetTypes: [],
      slides: [],
      lastUpdated: 0,
      status: "draft",
      version: this.version,
      language: this.language,
      id: this.id,
    };
    this.output = JSON.stringify(Sublessons.saveSublesson(sublesson), null, 2);
  }

  createSublessonDraft() {
    const response = Sublessons.createSublessonDraft({
      owner: { tenant: this.tenant, siteId: this.siteId },
      id: this.id,
      srcLang: this.language as languages,
      srcVersion: this.version,
      newLang: languages.de,
      newVersion: "1.1",
    });
    this.output = JSON.stringify(response, null, 2);
  }

  async fetchSublessons() {
    const sublessons = await Sublessons.fetchSublessons({
      owner: { tenant: this.tenant, siteId: this.siteId },
      language: this.language as languages,
      isPublished: false,
    });
    this.output = JSON.stringify(sublessons, null, 2);
  }

  async fetchPublishedSublessons() {
    const sublessons = await Sublessons.fetchSublessons({
      owner: { tenant: this.tenant, siteId: this.siteId },
      language: this.language as languages,
      isPublished: true,
    });
    this.output = JSON.stringify(sublessons, null, 2);
  }

  async fetchSublesson() {
    const sublesson = await Sublessons.fetchSublesson({
      owner: { tenant: this.tenant, siteId: this.siteId },
      id: this.id,
      language: this.language as languages,
      version: this.version,
    });
    this.output = JSON.stringify(sublesson, null, 2);
  }

  deleteSublesson() {
    Sublessons.deleteSublesson({
      owner: { tenant: this.tenant, siteId: this.siteId },
      id: this.id,
      language: this.language as languages,
      version: this.version,
    });
  }

  async publishSublesson() {
    Sublessons.publishSublesson({
      reference: {
        owner: { tenant: this.tenant, siteId: this.siteId },
        id: this.id,
        language: this.language as languages,
        version: this.version,
      },
      editorEmail: "admin@fette-compacting.com",
      changelogMessage: "Initial version",
    });
  }

  async saveLesson() {
    const lesson: Lesson = {
      owner: { tenant: this.tenant, siteId: this.siteId },
      status: "draft",
      version: "1.0",
      language: "de",
      id: "TestId",
      element: "testelement",
      title: "TestTitle",
      description: "TestDescription",
      targetGroups: [],
      assetTypes: [],
      sublessons: [],
      lastUpdated: 0,
    };
    this.output = JSON.stringify(await Lessons.saveLesson(lesson), null, 2);
  }

  createLessonDraft() {
    Lessons.createLessonDraft({
      owner: { tenant: this.tenant, siteId: this.siteId },
      id: "eaf36def-4536-4f26-be43-a0e4fc3d6e8b",
      srcLang: languages.de,
      srcVersion: "1.0",
      newLang: languages.en,
      newVersion: "1.0",
    });
  }

  async fetchLessons() {
    const lessons = await Lessons.fetchLessons({
      owner: { tenant: this.tenant, siteId: this.siteId },
      language: this.language as languages,
      isPublished: false,
    });
    this.output = JSON.stringify(lessons, null, 2);
  }

  async fetchLesson() {
    const lesson = await Lessons.fetchLesson({
      type: "lesson",
      owner: { tenant: this.tenant, siteId: this.siteId },
      id: this.id,
      language: this.language,
      version: this.version,
    });
    this.output = JSON.stringify(lesson, null, 2);
  }

  deleteLesson() {
    Lessons.deleteLesson({
      id: "TestId",
      language: languages.de,
      version: "1.0",
    });
  }

  saveTraining() {
    const training: Training = {
      owner: { tenant: this.tenant, siteId: this.siteId },
      status: "draft",
      version: "1.0",
      language: "de",
      id: "TestId",
      title: "TestTitle",
      description: "TestDescription",
      elements: [],
      lastUpdated: 0,
      targetGroups: [],
      assetTypes: [],
      thumbnail: {
        fileExtension: "png",
        owner: { tenant: this.tenant, siteId: this.siteId },
        uuid: "123",
        mimeType: "mimetype",
      },
    };
    Trainings.saveTraining(training);
  }

  createTrainingDraft() {
    Trainings.createTrainingDraft({
      owner: { tenant: this.tenant, siteId: this.siteId },
      id: "025dc250-dd73-43d4-b4c6-19041828c53b",
      srcLang: languages.de,
      srcVersion: "1.0",
      newLang: languages.en,
      newVersion: "1.0",
    });
  }

  async fetchTrainings() {
    const trainings = await Trainings.fetchTrainings({
      owner: { tenant: this.tenant, siteId: this.siteId },
      language: this.language as languages,
      isPublished: false,
    });
    this.output = JSON.stringify(trainings, null, 2);
  }

  async fetchTraining() {
    const training = await Trainings.fetchTraining({
      owner: { tenant: this.tenant, siteId: this.siteId },
      id: this.id,
      language: this.language as languages,
      version: this.version,
    });
    this.output = JSON.stringify(training, null, 2);
  }

  // deleteTraining() {
  //   Trainings.deleteTraining({ id: "TestId", language: languages.de, version: "1.1" });
  // }

  // listMedia() {
  //   Media.fetchMedia({ owner: { tenant: this.tenant, siteId: this.siteId }, filter: {} });
  // }

  // getMediaMetadata() {
  //   Media.getMediaMetadata("d3b45ac2-b2f8-42b2-bce5-8f1bc5939048");
  // }

  // async deleteMedia() {
  //   const metaData = await Media.getMediaMetadata("d3b45ac2-b2f8-42b2-bce5-8f1bc5939048");
  //   if (metaData) await Media.deleteMedia(metaData);
  // }
}
