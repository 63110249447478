
import { Component, Prop, VModel, Watch } from "vue-property-decorator";
import Vue from "vue";
import Upload from "@/components/sublessons/blocks/Upload.vue";
import OsdiIconButton from "shared-alva/components/buttons/OsdiIconButton.vue";
import OsdiTooltipButton from "shared-alva/components/buttons/OsdiTooltipButton.vue";
import OsdiDeleteButton from "shared-alva/components/buttons/OsdiDeleteButton.vue";
import OsdiTextArea from "shared-alva/components/inputs/OsdiTextArea.vue";
import UIStateModule from "@/store/modules/uistate-store";
import BadgeElement from "@/components/sublessons/blocks/BadgeElement.vue";
import BadgeCarousel from "@/components/sublessons/blocks/BadgeCarousel.vue";
import MediaLibrary from "@/components/media/MediaLibrary.vue";

import { BadgeType, MediaEntity, Badge, MediaMetadata } from "shared-alva/models";

@Component({
  components: {
    OsdiTextArea,
    OsdiDeleteButton,
    OsdiIconButton,
    Upload,
    BadgeElement,
    BadgeCarousel,
    MediaLibrary,
    OsdiTooltipButton,
  },
})
export default class MediaBlock extends Vue {
  @VModel() slideMedias!: MediaEntity[];

  @Prop()
  quizQuestion?: boolean;

  @Prop()
  multiple!: boolean;

  @Prop()
  sublessonId!: string;

  @Prop()
  showBadgeCarousel?: boolean;

  @Prop()
  showUndoButton!: boolean;

  showMediaLibrary: boolean = false;
  resolvedMedias: MediaEntity[] = [];

  isUploading = false;
  isMounted = false;

  isUndoDisabled = true;

  lastMedia?: MediaEntity;
  deletedIndex = 0;
  currentSlide = 0;

  cachedMedias!: MediaEntity[];

  uiStore = UIStateModule;

  currentBadge: Badge = { text: BadgeType.EMPTY };

  get isLoading() {
    return this.isUploading || !this.isMounted;
  }

  async mounted() {
    await this.resolveMedia();
    this.checkBadgesOnMount();
    this.isMounted = true;
  }

  checkBadgesOnMount() {
    if (this.showBadgeCarousel) {
      let media = this.slideMedias[this.currentSlide];
      if (media?.badge != undefined && media?.badge != BadgeType.EMPTY) {
        this.currentBadge = { text: this.slideMedias[this.currentSlide].badge } as Badge;
      }
    }
  }

  setCurrentBadge(badge: Badge) {
    this.currentBadge = badge;
    this.slideMedias[this.currentSlide].badge = badge.text;
  }

  isImage(media: MediaEntity) {
    return media.mimeType.includes("image");
  }

  updateCurrentSlide(value: number) {
    this.currentSlide = value;
  }

  @Watch("uiStore.getSaveButtonIsPressed")
  listenForSave() {
    if (this.uiStore.getSaveButtonIsPressed) {
      this.isUndoDisabled = true;
      this.uiStore.applySaveButtonStatus(false);
    }
  }

  updateCachedValues(index?: number) {
    this.cachedMedias = [...this.slideMedias];
    if (index != undefined) this.deletedIndex = index;
  }

  @Watch("slideMedias")
  private async onSlideMediasChanged() {
    this.isMounted = false;
    await this.resolveMedia();
    this.isMounted = true;
  }

  async resolveMedia() {
    this.resolvedMedias = this.slideMedias;
  }

  openMediaLibrary() {
    this.showMediaLibrary = true;
  }

  onCloseMediaLibrary() {
    this.showMediaLibrary = false;
  }

  onNewMediaSelected(media: MediaMetadata) {
    this.slideMedias.push({
      owner: { tenant: "fettecompacting", siteId: "global" },
      uuid: media.uuid,
      mimeType: media.mimeType,
      caption: "",
      signedUrl: media.signedUrl,
      signedThumbnailUrl: media.signedThumbnailUrl,
      fileExtension: media.fileExtension || "",
    });
    this.showMediaLibrary = false;
    this.updateCurrentSlide(this.slideMedias.length - 1);
  }

  async onDeleteMediaAtIndex(index: number) {
    this.lastMedia = this.slideMedias[index];
    this.updateCachedValues(index);
    this.slideMedias.splice(index, 1);
    this.isUndoDisabled = false;
    await this.resolveMedia();
  }

  async onUndoMedia() {
    this.isUndoDisabled = true;
    this.slideMedias = [...this.cachedMedias];
    this.lastMedia = undefined;
    this.updateCurrentSlide(this.deletedIndex);
  }

  removeMediaButtonText(media: MediaEntity) {
    const isMediaAnImage = media.mimeType.startsWith("image/");
    return isMediaAnImage
      ? this.$t("smartcollege.slideeditor.image.remove").toString()
      : this.$t("smartcollege.slideeditor.video.remove").toString();
  }
}
