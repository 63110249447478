import { Module, getModule, VuexModule, Mutation, Action } from "vuex-module-decorators";
import store from "@/store/index";
import { FilterBarItems } from "@/models";

interface FilterBarItemsState {
  [key: string]: {
    filterItems: FilterBarItems[];
    searchInput: string;
  };
}

@Module({ dynamic: true, namespaced: true, store, name: "filters" })
class FiltersModule extends VuexModule {
  filters: FilterBarItemsState = {} as FilterBarItemsState;

  @Mutation
  SET_ALL_FILTERS({
    element,
    filters,
    searchInput,
  }: {
    element: string;
    filters: FilterBarItems[];
    searchInput: string;
  }) {
    const copy = { ...this.filters };
    copy[element] = { filterItems: filters, searchInput };
    this.filters = copy;
  }

  @Action
  setAllFilters({
    element,
    filters,
    searchInput,
  }: {
    element: string;
    filters: FilterBarItems[];
    searchInput: string;
  }) {
    this.SET_ALL_FILTERS({ element, filters, searchInput });
  }
}

export default FiltersModule;
