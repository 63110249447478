
import Auth from "@aws-amplify/auth";
import { Component, Vue } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import AuthModule, { AuthenticationState } from "@/store/modules/auth-store";
import logger from "@/logger";
import OsdiButton from "shared-alva/components/buttons/OsdiButton.vue";
import OsdiMailField from "shared-alva/components/inputs/OsdiMailField.vue";
import OsdiPasswordField from "shared-alva/components/inputs/OsdiPasswordField.vue";
import OsdiTextField from "shared-alva/components/inputs/OsdiTextField.vue";
import OsdiTooltipButton from "shared-alva/components/buttons/OsdiTooltipButton.vue";
const authStore = getModule(AuthModule);

@Component({
  components: { OsdiButton, OsdiTextField, OsdiMailField, OsdiPasswordField, OsdiTooltipButton },
})
export default class Login extends Vue {
  private username = "";
  private password = "";
  private valid = true;
  private error = "";

  login() {
    this.doLogin();
  }

  async doLogin() {
    try {
      const cognitoUser = await Auth.signIn(this.username.toLowerCase(), this.password);
      await authStore.requestUserPermissions();

      logger.info("sign in successful");

      /**
       * original logic:
       * @see https://github.com/aws-amplify/amplify-js/blob/master/packages/aws-amplify-vue/src/components/authenticator/SignIn.vue
       */
      if (cognitoUser.challengeName === "NEW_PASSWORD_REQUIRED") {
        logger.info("require new password");
        authStore.updateAuthn(AuthenticationState.requireNewPassword);
      } else {
        authStore.updateAuthn(AuthenticationState.signedIn);
      }

      authStore.login(cognitoUser);
      this.error = "";
    } catch (error) {
      authStore.login(null);
      logger.error(error);
      this.error = this.$t("auth.errors.loginFailed").toString();
    }
  }

  forgotPassword() {
    authStore.updateAuthn(AuthenticationState.forgotPassword);
  }
}
