
import Vue from "vue";
import { Component, Prop, Emit } from "vue-property-decorator";
import { Sublesson, Lesson, Training } from "shared-alva/models";
import { MiscAPI } from "shared-alva";
import awsconfig from "@/aws-config";
import moment from "moment";

@Component({
  components: {},
})
export default class ChangeLog extends Vue {
  private miscApi: MiscAPI = new MiscAPI(awsconfig.API_V2);

  @Prop() readonly changelogData!: Sublesson | Lesson | Training;
  @Prop() readonly dataType!: any;
  @Prop() readonly showChangelog!: boolean;
  @Prop() readonly selectedVersion!: string;

  private close: boolean = this.showChangelog;
  invertedData: any = [];
  private mainVersion: string = "";

  getFormatedTime(value: string): string {
    const timeConverted = moment(value).format("L");
    return timeConverted;
  }

  async mounted() {
    const { id, language, version } = this.changelogData;

    const data = await this.miscApi.getChangelog({
      owner: { tenant: "fettecompacting", siteId: "global" },
      id: id,
      language: language,
      version: version,
      type: this.dataType,
    });
    this.invertedData = data.reverse();
    this.mainVersion = this.selectedVersion[id as any];
  }

  @Emit()
  closeChangelog() {
    this.close = false;
  }
}
