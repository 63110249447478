
import { Choice, ElementStatusType, Question, QuestionType } from "shared-alva/models";
import OsdiButton from "shared-alva/components/buttons/OsdiButton.vue";
import { v4 as uuid } from "uuid";
import { Component, Watch, Vue, Prop, Emit } from "vue-property-decorator";
import OsdiTextField from "shared-alva/components/inputs/OsdiTextField.vue";
import OsdiSnackbar from "shared-alva/components/snackbars/OsdiSnackbar.vue";
import OsdiTextArea from "shared-alva/components/inputs/OsdiTextArea.vue";
import QuestionModule from "@/store/modules/questions-store";
import { getModule } from "vuex-module-decorators";
import { languages } from "shared-alva/languages";
import { emit, title } from "process";
import { text } from "stream/consumers";
import { ROUTES } from "@/router";
import { VContainer, VRow, VCol, VForm, VSelect } from "vuetify/lib";

@Component({
  components: {
    OsdiButton,
    OsdiTextField,
    OsdiSnackbar,
    OsdiTextArea,
  },
})
export default class GapTest extends Vue {
  questionStore = getModule(QuestionModule);

  public requiredRules = [(v: string) => !!v || "Required"];

  @Prop() question!: Question;
  @Prop() save!: boolean;
  @Prop() publish!: boolean;

  text: string = "";
  title: string = "";
  element: string = "";
  description: string = "";
  actionLabel: string = "";
  points: number = 0;
  currentQuestion = { ...this.question };
  languageItems = Object.values(languages);
  choice: Choice = {
    additionalInfo: "",
  } as Choice;

  $refs!: {
    snackbar: OsdiSnackbar;
    form: HTMLFormElement;
  };

  mounted() {
    if (this.question) {
      this.title = this.question.title;
      this.text = this.question.text;
      this.description = this.question.description;
      this.element = this.question.element;
      this.actionLabel = this.question.actionLabel;
      this.points = this.question.points;

      this.generateChoice();
      if (this.question.choices && this.question.choices.length > 0) {
        this.choice = this.question.choices[0];
      }
    }
    this.loaded();
  }

  @Emit()
  loaded() {}
  generateChoice() {
    const choice: Choice = {
      owner: { siteId: "global", tenant: "fettecompacting" },
      id: uuid(),
      language: "en",
      version: "1.0",
      status: "published",
      textValue: "",
      mediaValue: undefined,
      additionalInfo: "",
      correctPlace: 0,
    };
    this.choice = choice;
  }

  @Watch("questionStore.questions")
  onQuestionStoreChanged() {
    this.currentQuestion = { ...this.questionStore.getQuestions[this.question.id] };
  }

  @Watch("text")
  @Watch("title")
  @Watch("element")
  @Watch("descripion")
  @Watch("actionLabel")
  @Watch("points")
  changeText() {
    this.currentQuestion.text = this.text;
    this.currentQuestion.title = this.title;
    this.currentQuestion.element = this.element;
    this.currentQuestion.description = this.description;
    this.currentQuestion.actionLabel = this.actionLabel;
    this.currentQuestion.points = this.points;
    this.change();
  }

  @Emit()
  change() {}

  @Watch("save")
  async onSave() {
    if (this.$refs.form.validate()) {
      await this.onSubmit(this.currentQuestion.status);
      if (this.$route.path.includes("new")) {
        this.$router.push({
          name: ROUTES.SmartCollegeQuestionDetails,
          params: {
            questionId: this.currentQuestion.id,
          },
          query: {
            version: this.currentQuestion.version,
            language: this.currentQuestion.language,
          },
        });
      }
    } else {
      return false;
    }
  }

  @Watch("publish")
  async onPublish() {
    if (this.$refs.form.validate()) {
      await this.onSubmit("published");
    } else {
      return false;
    }
  }

  async onSubmit(status: string) {
    if (this.currentQuestion.id === "" || this.currentQuestion.id === undefined) {
      this.currentQuestion.id = uuid();
    }
    this.currentQuestion.type = QuestionType.GAP_TEST;
    this.currentQuestion.choices = [this.choice];
    this.currentQuestion.text = this.text;
    this.currentQuestion.title = this.title;
    this.currentQuestion.element = this.element;
    this.currentQuestion.description = "";
    this.currentQuestion.actionLabel = this.actionLabel;
    this.currentQuestion.points = 1;
    this.currentQuestion.status = status as ElementStatusType;
    this.currentQuestion.version = this.currentQuestion.version ?? "1.0";
    this.currentQuestion.references = [];
    this.currentQuestion.owner = {
      siteId: "global",
      tenant: "fettecompacting",
    };

    await this.questionStore.saveQuestion(this.currentQuestion);

    this.$refs.snackbar.open({
      message: this.$t("smartcollege.question.textfield.questionSaved").toString(),
      //message: this.$t("smartcollege.notification.changesSaved").toString(),
    });
  }
}
