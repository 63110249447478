
import { Component, Vue, Watch } from "vue-property-decorator";
import MediaStore from "@/store/modules/media-store_v2";
import { MediaMetadata, MediaReferences } from "shared-alva/models";
import DialogStore from "@/store/modules/dialog-store";
import OsdiIconButton from "shared-alva/components/buttons/OsdiIconButton.vue";
import OsdiTooltipButton from "shared-alva/components/buttons/OsdiTooltipButton.vue";
import { getModule } from "vuex-module-decorators";
import MediaPreviewDialog from "./MediaPreview.vue";
import MediaUploadStatus from "./MediaUploadStatus.vue";
import OsdiSnackbar from "shared-alva/components/snackbars/OsdiSnackbar.vue";
import ExtraInfoPanel from "./ExtraInfoPanel/ExtraInfoPanel.vue";

const dialogStore = getModule(DialogStore);
export interface MediaMetadataUIInfo extends MediaMetadata {
  isLoading?: boolean;
  togglePanel?: boolean;
  extraMediaInfo?: MediaReferences[];
  dataLoaded?: boolean;
}
@Component({
  components: {
    MediaPreviewDialog,
    MediaUploadStatus,
    OsdiIconButton,
    OsdiTooltipButton,
    OsdiSnackbar,
    ExtraInfoPanel,
  },
})
export default class MediaLibrary extends Vue {
  $refs!: { mediaTable: HTMLElement; filePicker: HTMLElement };
  searchTerm: string = "";
  sortBy: string = "";
  mimeTypes = ["Image", "Video"];
  selectedFilter: string = "";
  showDropzone = 0;
  droppedFiles = [];
  showUploadStatus = true;
  currentUploads: File[] = [];
  selectedItems: MediaMetadata[] = [];
  showMediaPreview = false;
  previewItem: MediaMetadata | undefined;
  filteredMedia: MediaMetadataUIInfo[] = [];
  changedId = "";
  originalName = "";
  itemToDelete: MediaMetadata[] = [];
  changeItem: MediaMetadata = {} as MediaMetadata;
  hasReferences = false;
  itemsToDelete: MediaMetadata[] = [];
  mediaCache = new Map<string, MediaMetadata>();
  newTitle: string = "";
  updateKey: number = 0;

  get media() {
    return MediaStore.media;
  }

  get mediaLoading() {
    return MediaStore.isLoading;
  }

  @Watch("selectedFilter")
  @Watch("media", { immediate: true })
  async updateFilteredMedia() {
    if (this.selectedFilter) {
      this.filteredMedia = [
        ...(
          await MediaStore.getMedia({
            owner: { tenant: "fettecompacting", siteId: "global" },
          })
        ).filter((m: MediaMetadata) => m.mimeType.includes(this.selectedFilter.toLowerCase())),
      ];
    } else
      this.filteredMedia = [
        ...(await MediaStore.getMedia({ owner: { tenant: "fettecompacting", siteId: "global" } })),
      ];

    this.filteredMedia = this.filteredMedia.map((v) => {
      return {
        ...v,
        thumbnail: this.setThumbnailBackground(v),
        extraMediaInfo: [],
        isLoading: false,
        togglePanel: false,
        dataLoaded: false,
      };
    });
  }

  filterMimetype(value: any, search: string | null, item: any): boolean {
    return item.mimeType.includes("image");
  }

  resetFilters() {
    this.sortBy = "";
    this.searchTerm = "";
    this.selectedFilter = "";
  }

  openPreview(item: MediaMetadata) {
    this.previewItem = item;
    this.showMediaPreview = true;
  }

  closePreview() {
    this.showMediaPreview = false;
    this.previewItem = undefined;
  }

  setThumbnailBackground(item: MediaMetadata): string {
    const isAnimation = item.mimeType.includes("webp") || item.mimeType.includes("gif");
    const url = isAnimation ? item.signedUrl : item.signedThumbnailUrl;

    return `background: url(${url});                \
            background-size: contain;               \
            background-position-x: center;          \
            background-position-y: center;          \
            background-repeat: no-repeat;`;
  }

  getDuration(media: MediaMetadata): string {
    if (!media.duration || media.duration == 0) return "-";
    else {
      const minutes = parseInt((media.duration / 60).toString());
      const seconds = parseInt((media.duration % 60).toString());
      if (minutes == 0) return `${seconds}sec`;
      else if (seconds == 0) return `${minutes}min`;
      else return `${minutes}min ${seconds}sec`;
    }
  }

  getMediaType(media: MediaMetadata): "Image" | "Video" | "Other" {
    if (media.mimeType.includes("image")) return "Image";
    else if (media.mimeType.includes("video")) return "Video";
    else return "Other";
  }

  getDate(media: MediaMetadata): string {
    return new Date(media.creationTimestamp!).toLocaleDateString("de-DE");
  }

  getShortTitle(title: string): string {
    if (!title) return "";
    if (title.length < 25) return title;
    else return title.substring(0, 22) + "...";
  }

  async handleOnClickExtraInfo(item: MediaMetadataUIInfo) {
    item.togglePanel = !item.togglePanel;
    if (item.togglePanel === false) return;
    if (item.extraMediaInfo?.length !== 0) return;
    if (item.dataLoaded) return;

    item.isLoading = true;
    const mediaReference = await MediaStore.getMediaReferences({
      owner: item.owner,
      id: item.uuid,
    });
    item.isLoading = false;
    item.dataLoaded = true;
    item.extraMediaInfo = [...mediaReference];
  }

  async startNameChange(item?: MediaMetadata) {
    if (item) {
      const items = this.filteredMedia.filter((m) => m.uuid !== item.uuid);
      const names = items.map((m) => m.title);

      this.changeItem = { ...item, title: this.newTitle };
      const name = names.find((n) => n === this.newTitle);
      if (name) {
        this.hasReferences = true;
      }
    } else {
      this.changedId = "";
      this.originalName = "";
      this.hasReferences = false;
      this.changeItem = {} as MediaMetadata;
    }
  }

  changeName(item?: MediaMetadata) {
    // implement code to change nam
    if (item) {
      this.originalName = item.title ? item.title : "";
      this.changedId = item.uuid;
    } else {
      this.originalName = "";
      this.changedId = "";
      this.hasReferences = false;
    }
  }

  async onSubmit(item: MediaMetadata) {
    delete item.signedUrl;
    delete item.signedThumbnailUrl;
    await MediaStore.changeMedia(item);
    this.newTitle = "";
    this.changedId = "";
    this.changeItem = {} as MediaMetadata;
    this.updateKey += 1;
  }

  resetName(item: MediaMetadata) {
    if (item.title) item.title = this.originalName;
  }

  onDragOver(e: Event) {
    e.preventDefault();
  }

  capitalizeFirstLetter(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  onDragEnter(e: Event) {
    e.preventDefault();
    this.showDropzone++;
  }
  onDragLeave(e: Event) {
    e.preventDefault();
    this.showDropzone--;
    if (this.showDropzone == 0) {
    }
  }

  onDrop(e: any) {
    e.stopPropagation();
    e.preventDefault();
    this.showDropzone = 0;
    e.dataTransfer.files.forEach((element: never) => this.droppedFiles.push(element));
    this.currentUploads = this.droppedFiles;
  }

  onUploadOverButton(file: File) {
    if (file) {
      this.currentUploads.push(file);
      (this.$refs.filePicker as any)!.value = undefined;
      (this.$refs.filePicker as any)!.reset();
    }
  }

  onUploadButton() {
    document.getElementById("filePicker")!.click();
  }

  async onDeleteIntention(media: MediaMetadata[]) {
    this.itemToDelete = media;
    for (let i = 0; i < media.length; i++) {
      const m = media[i];
      let metadata: MediaMetadata | undefined;

      if (this.mediaCache.has(m.uuid)) {
        metadata = this.mediaCache.get(m.uuid) as MediaMetadata;
      } else {
        metadata = await MediaStore.getMediaMetadata({
          owner: m.owner,
          mediaId: m.uuid,
        });
      }

      if (metadata && metadata.references && metadata.references.length > 0) {
        this.hasReferences = true;
        break;
      }
    }
  }

  resetDelete() {
    this.itemToDelete = [];
    this.hasReferences = false;
  }

  async onDelete(media: MediaMetadata[]) {
    for (let i = 0; i < media.length; i++) {
      const m = media[i];
      let metadata: MediaMetadata | undefined;

      if (this.mediaCache.has(m.uuid)) {
        metadata = this.mediaCache.get(m.uuid) as MediaMetadata;
      } else {
        metadata = await MediaStore.getMediaMetadata({
          owner: m.owner,
          mediaId: m.uuid,
        });
      }
      if (metadata && (!metadata.references || metadata!.references.length == 0)) {
        await MediaStore.deleteMedia(m);
      }
    }
    MediaStore.media;
    this.resetDelete();
  }

  get headers() {
    return [
      {
        text: "",
        sortable: false,
        value: "preview",
      },

      {
        text: "Title",
        value: "title",
      },
      {
        text: "",
        align: "center",
        value: "border2",
        sortable: false,
      },
      {
        text: "Media Type",
        align: "center",
        value: "mimeType",
      },
      {
        text: "",
        align: "center",
        value: "border1",
        sortable: false,
      },
      {
        text: "Duration",
        align: "center",
        value: "duration",
      },
      {
        text: "",
        align: "center",
        value: "border3",
        sortable: false,
      },
      {
        text: "Date",
        align: "center",
        value: "creationTimestamp",
      },
      {
        text: "",
        align: "center",
        value: "border4",
        sortable: false,
      },
      {
        sortable: false,
        value: "btn_info",
      },
    ];
  }
}
