
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import OsdiCloseButton from "shared-alva/components/buttons/OsdiCloseButton.vue";
import OsdiButton from "shared-alva/components/buttons/OsdiButton.vue";

@Component({
  components: { OsdiButton, OsdiCloseButton },
})
export default class ActionBar extends Vue {
  @Prop()
  sublessonTitle!: string;

  @Prop()
  hasUnsavedChanges!: boolean;

  @Emit()
  onSave() {}

  @Emit()
  onCloseClick() {}
}
