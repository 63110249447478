import { Module, getModule, VuexModule, Mutation, Action } from "vuex-module-decorators";
import store from "@/store/index";
import { Owner, LearningPath, TrainingMetadata, SaveLearningPath } from "shared-alva/models";
import { languages } from "shared-alva/languages";
import { LearningPathAPI } from "shared-alva";
import awsconfig from "@/aws-config";

export interface LearningPathUI extends Omit<LearningPath, "elements"> {
  elements: TrainingMetadata[];
}
@Module({ dynamic: true, namespaced: true, store, name: "learningPath_v2" })
class LearningPathModule extends VuexModule {
  private readonly lPathClient = new LearningPathAPI(awsconfig.API_V2);
  private readonly _owner: Owner = { tenant: "fettecompacting", siteId: "global" };
  private _loading = false;
  private _training: TrainingMetadata[] = [];
  private _learningPaths: LearningPath[] = [];
  private _machineTypes: string[] = [];
  private _learningPathUI: LearningPathUI = {} as LearningPathUI;

  @Mutation
  setLearningPaths(learningPaths: LearningPath[]) {
    this._learningPaths = learningPaths;
  }

  @Mutation
  setTrainings(training: TrainingMetadata[]) {
    this._training = training;
  }

  @Mutation
  setLoading(loading: boolean) {
    this._loading = loading;
  }

  @Mutation
  setMachineTypes(machineTypes: string[]) {
    this._machineTypes = machineTypes;
  }

  @Mutation
  setLearningPathUI(learningPathUI: LearningPathUI) {
    this._learningPathUI = learningPathUI;
  }

  get owner() {
    return this._owner;
  }

  get learningPaths() {
    return this._learningPaths;
  }

  get trainings() {
    return this._training;
  }

  get isLoading() {
    return this._loading;
  }

  get machineTypes() {
    return this._machineTypes;
  }

  get learningPathUI() {
    return this._learningPathUI;
  }

  @Action({ rawError: true })
  async fetchLearningPath(): Promise<boolean> {
    try {
      this.setLoading(true);
      const { learningPaths, machineTypes, trainings } = await this.lPathClient.queryLearningPath(
        this._owner,
        false,
        true,
        languages.all
      );
      this.context.commit("setLearningPaths", learningPaths);
      this.context.commit("setMachineTypes", machineTypes);
      this.context.commit("setTrainings", trainings);

      return true;
    } catch (error: any) {
      console.error(error);

      return false;
    } finally {
      this.setLoading(false);
    }
  }

  @Action
  async filterTrainingByUserLanguage(userLanguage: languages) {
    const englishTrainings = this.trainings.filter(
      (training) => training.language === languages.en
    );
    if (userLanguage === languages.en) return englishTrainings;

    const targetLanguageTrainings = this.trainings.filter(
      (training) => training.language === userLanguage
    );
    const trainingWithLanguageAdapted = englishTrainings.map((englishTraining) => {
      const trainingToReplace = targetLanguageTrainings.find(
        (targetLanguageTraining) => targetLanguageTraining.id === englishTraining.id
      );
      return trainingToReplace ? trainingToReplace : englishTraining;
    });

    return trainingWithLanguageAdapted;
  }

  @Action({ commit: "setLearningPathUI" })
  async mountLearningPathUIbyId(learningPathId: string): Promise<LearningPathUI> {
    const targetLPath = this.learningPaths.find(
      (lPath) => lPath.id === learningPathId
    ) as LearningPath;
    const lPathTrainings: TrainingMetadata[] = targetLPath.elements.map((element) => {
      const targetTraining = this.trainings.find(
        // info: the training id could be repeated: compare with id and language to be unique training
        (training) => training.id === element.id && element.language === training.language
      );

      return targetTraining;
    }) as TrainingMetadata[];
    const learningPathUi: LearningPathUI = { ...targetLPath, elements: lPathTrainings };

    return learningPathUi;
  }

  @Action({ rawError: true })
  async saveLearningPath(learningPath: SaveLearningPath): Promise<boolean> {
    try {
      this.setLoading(true);
      const success = await this.lPathClient.saveLearningPath(learningPath);
      if (!success) return false;
      await this.fetchLearningPath();

      return true;
    } catch (error) {
      console.error(error);

      return false;
    } finally {
      this.setLoading(false);
    }
  }

  @Action
  async deleteLearningPath(): Promise<Boolean> {
    try {
      this.setLoading(true);
      const success = await this.lPathClient.deleteLearningPath({
        owner: this._owner,
        id: this.learningPathUI.id,
        language: "en",
        version: "1.0",
      });
      if (!success) return false;

      await this.fetchLearningPath();
      this.setLearningPathUI({} as LearningPathUI);

      return true;
    } catch (error) {
      console.error(error);

      return false;
    } finally {
      this.setLoading(false);
    }
  }
}

export default getModule(LearningPathModule);
