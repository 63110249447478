
import { Component, Vue, Prop, Watch, Emit } from "vue-property-decorator";
import { FilterBarItems } from "@/models";
import OsdiTooltipButton from "shared-alva/components/buttons/OsdiTooltipButton.vue";
import filtersStore from "@/store/modules/filters-store";
import { getModule } from "vuex-module-decorators";

@Component({
  components: { OsdiTooltipButton },
})
export default class TopFiltersBar extends Vue {
  @Prop({ required: true }) filters!: FilterBarItems[];
  @Prop() itemWidth!: "";
  @Prop({ default: false }) needSpace!: boolean;

  getFilterName(filter: FilterBarItems, item: { value: string; index: number }) {
    if (!filter.itemLabels || filter.itemLabels.length < item.index) return item.value;
    else return filter.itemLabels[item.index];
  }

  get filtersWithIndex() {
    return this.filters.map((el: FilterBarItems) => {
      return {
        ...el,
        items: el.items.map((item, index) => {
          return { value: item, index: index };
        }),
      };
    });
  }

  @Emit()
  resetFilters() {
    this.filters.forEach((element) => {
      element.value = element.items[0];
    });
  }
}
