import { Lesson, Question, Quiz, Sublesson, Training } from "shared-alva/models";

import { TrainingElementVersions } from "@/models";

export const filterNewVersion = (
  filteredItems: TrainingElementVersions[],
  filteredNewVersion: any
) => {
  //swap the latestPublished with the new item that we want to display
  filteredItems = filteredItems.map((filter: any, index: any) => {
    if (filter.id == filteredNewVersion.id) {
      filteredItems[index].latestPublished = filteredNewVersion;
    }
    return filter;
  });
  return filteredItems;
};

export const filterTargetGroup = (
  selectedTargetGroup: string,
  filteredItems: TrainingElementVersions[]
) => {
  filteredItems = filteredItems.filter((el: TrainingElementVersions) => {
    return (el.latest as Training | Sublesson | Lesson)?.targetGroups
      .map((e) => e.toLowerCase())
      .includes(selectedTargetGroup.toLowerCase());
  });
  return filteredItems;
};

export const filterMachineType = (
  selectedMachineType: string,
  filteredItems: TrainingElementVersions[]
) => {
  filteredItems = filteredItems.filter((el: TrainingElementVersions) => {
    return (el.latest as Training | Sublesson | Lesson)?.assetTypes
      .map((e) => {
        return e.toLowerCase();
      })
      .includes(selectedMachineType.toLowerCase());
  });
  return filteredItems;
};

export const filterStatus = (selectedStatus: string, filteredItems: TrainingElementVersions[]) => {
  if (selectedStatus == "Published") {
    filteredItems = filteredItems.filter((el: TrainingElementVersions) => {
      return Object.keys(el.publishedVersions).length > 0;
    });
  } else {
    filteredItems = filteredItems
      .filter((el: TrainingElementVersions) => {
        return el.draft && el.draft.status == selectedStatus.toLowerCase();
      })
      .map((el: TrainingElementVersions) => {
        return { ...el, publishedVersions: {}, latestPublished: undefined };
      });
  }

  return filteredItems;
};

export const searchInputValues = (
  filteredItems: TrainingElementVersions[],
  searchInput = "",
  isTraining?: boolean
) => {
  if (isTraining) {
    const searchText = searchInput.trim().toLowerCase();
    filteredItems = filteredItems.filter(
      (value: TrainingElementVersions) =>
        value.latestPublished?.title.toLowerCase().includes(searchText) ||
        value.latest?.title.toLowerCase().includes(searchText) ||
        value.draft?.title.toLowerCase().includes(searchText)
    );
  } else {
    const searchText = searchInput.trim().toLowerCase();
    if (filteredItems && filteredItems.length > 0) {
      filteredItems = filteredItems.filter(
        (value: TrainingElementVersions) =>
          (value.latestPublished as Sublesson | Question | Quiz | undefined)?.element
            .toLowerCase()
            .includes(searchText) ||
          (value.latest as Sublesson | Quiz | Question).element
            .toLowerCase()
            .includes(searchText) ||
          (value.draft as Sublesson | Quiz | Question | undefined)?.element
            .toLowerCase()
            .includes(searchText) ||
          value.latestPublished?.title.toLowerCase().includes(searchText) ||
          value.latest?.title.toLowerCase().includes(searchText) ||
          value.draft?.title.toLowerCase().includes(searchText)
      );
    }
  }
  return filteredItems;
};
