
import { Component, Vue } from "vue-property-decorator";
import Auth from "@aws-amplify/auth";
import AuthModule, { AuthenticationState } from "@/store/modules/auth-store";
import { getModule } from "vuex-module-decorators";
import logger from "@/logger";
import OsdiButton from "shared-alva/components/buttons/OsdiButton.vue";
import OsdiPasswordField from "shared-alva/components/inputs/OsdiPasswordField.vue";
import OsdiTextField from "shared-alva/components/inputs/OsdiTextField.vue";
const authStore = getModule(AuthModule);
@Component({
  components: { OsdiButton, OsdiTextField, OsdiPasswordField },
})
export default class RequireNewPassword extends Vue {
  private valid = true;
  private password = "";
  private error = "";

  submitForm() {
    this.changePassword();
  }

  async changePassword() {
    try {
      const cognitoUser = await Auth.completeNewPassword(authStore.user, this.password, []);
      await Auth.verifiedContact(cognitoUser);
      logger.info("Password changed succesfully.");
      authStore.updateAuthn(AuthenticationState.signedIn);
      authStore.login(cognitoUser);
    } catch (error) {
      logger.error("Could not reset password.", error);
      this.error = this.$t("auth.errors.couldNotSetNewPassword").toString();
    }
  }
}
