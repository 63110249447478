
import { Component, Watch, Vue } from "vue-property-decorator";
import { FilterBarItems, TableMenuAction, TrainingElementVersions } from "@/models";
import { Lesson, MachineTypes, Owner } from "shared-alva/models";
import { getModule } from "vuex-module-decorators";
import OsdiTooltipButton from "shared-alva/components/buttons/OsdiTooltipButton.vue";
import OsdiTable, { OsdiTableRowAction } from "shared-alva/components/tables/OsdiTable.vue";
import DialogStore from "@/store/modules/dialog-store";
import TopFiltersBar from "@/components/TopFiltersBar.vue";
import OsdiConfirmDialog from "shared-alva/components/dialogs/OsdiConfirmDialog.vue";
import { hasPermission } from "@/authentication/authentication.service";
import LessonsModule from "@/store/modules/lessons-store_v2";
import { availableLanguages, languages } from "shared-alva/languages";
import { MachineTypesAPI } from "shared-alva";
import UIStateModule from "@/store/modules/uistate-store";
import awsconfig from "@/aws-config";
import Changelog from "@/components/Changelog.vue";
import { contentFilterItems, sortItems, refilterContent } from "@/service/contentHandlers";
import OsdiTableItem from "./TableItem/LessonTableItem.vue";
import OsdiDraftTableItem from "@/components/tableTools/TableItemDraft.vue";
import OsdiTableFooter from "@/components/tableTools/TableFooter.vue";

import { v4 as uuid } from "uuid";
import i18n from "@/i18n";
import { sort } from "semver";
import { DataTableHeader } from "vuetify";
import LanguageModule from "@/store/modules/language-store";
import { filterBarItems } from "@/utils/filterBarItems";
import { tableHeaders } from "@/utils/tableHeaders";
import { ROUTES } from "@/router";
import FiltersModule from "@/store/modules/filters-store";

const dialogStore = getModule(DialogStore);
@Component({
  components: {
    OsdiConfirmDialog,
    OsdiTable,
    TopFiltersBar,
    OsdiTooltipButton,
    Changelog,
    OsdiTableItem,
    OsdiDraftTableItem,
    OsdiTableFooter,
  },
})
export default class LessonOverview extends Vue {
  $refs!: {
    confirmDeleteDialog: OsdiConfirmDialog;
  };

  private elementPath = "lesson";

  private machineTypesAPI = new MachineTypesAPI(awsconfig.API_V2);
  actions: OsdiTableRowAction[] = [];
  private uiStore = UIStateModule;

  lessonStore = LessonsModule;
  filteredItems: TrainingElementVersions[] = [];
  expandedItems: TrainingElementVersions[] = [];

  private filteredNewVersion: any = "";
  selectedVersion: { [id: string]: string } = {};

  private machineTypes: string[] = [];
  private searchInput: string = "";

  showChangelog: boolean = false;
  changelogData?: Lesson;

  private needExtended: boolean = false;
  filterStore = getModule(FiltersModule);

  languageStore = getModule(LanguageModule);
  filterBarItems = this.getFilterBarItems;
  tableMenu: TableMenuAction[] = [
    {
      label: "createNewTranslation",
      icon: "mdi-translate",
      action: (lesson: Lesson) => this.newVersionDialog(lesson, true),
    },
    {
      label: "createNewVersion",
      icon: "mdi-source-branch-plus",
      action: (lesson: Lesson) => this.newVersionDialog(lesson, false),
    },
    {
      label: "showChangelog",
      icon: "mdi-history",
      action: (lesson: Lesson) => {
        this.openChangelog(lesson);
      },
    },
    {
      label: "duplicate",
      icon: "mdi-content-copy",
      action: (lesson: Lesson) => {
        this.duplicate(lesson);
      },
    },
  ];

  @Watch("languageStore.language")
  refreshFilters() {
    this.filterBarItems = this.getFilterBarItems;
  }

  get getFilterBarItems(): FilterBarItems[] {
    return [
      {
        label: i18n.t("smartcollege.overviewTable.headers.status").toString(),
        items: [
          i18n.t("smartcollege.filters.all").toString(),
          i18n.t("smartcollege.filters.draft").toString(),
          i18n.t("smartcollege.filters.review").toString(),
          i18n.t("smartcollege.filters.approved").toString(),
          i18n.t("smartcollege.filters.published").toString(),
        ],
        value: i18n.t("smartcollege.filters.all").toString(),
        onChange: () => {},
      },
      {
        label: i18n.t("smartcollege.overviewTable.headers.language").toString(),
        items: ["Default", ...availableLanguages],
        itemLabels: ["Default", ...availableLanguages].flatMap((el: string) => {
          return i18n.t(`smartcollege.language.${el}`).toString();
        }),
        value: i18n.locale,
        onChange: () => {},
      },
      {
        label: i18n.t("smartcollege.overviewTable.headers.learners").toString(),
        items: [
          i18n.t("smartcollege.filters.all").toString(),
          i18n.t("smartcollege.targetGroups.MECHANIC").toString(),
          i18n.t("smartcollege.targetGroups.ELECTRICIAN").toString(),
          i18n.t("smartcollege.targetGroups.OPERATOR").toString(),
        ],
        value: i18n.t("smartcollege.filters.all").toString(),
        onChange: () => {},
      },

      {
        label: i18n.t("smartcollege.overviewTable.headers.assetTypes").toString(),
        items: [i18n.t("smartcollege.filters.all").toString(), ...this.machineTypes],
        value: i18n.t("smartcollege.filters.all").toString(),
        onChange: () => {},
      },
    ];
  }

  get getStatusMap(): { [id: string]: string } {
    return {
      [i18n.t("smartcollege.filters.all").toString()]: "All",
      [i18n.t("smartcollege.filters.review").toString()]: "Review",
      [i18n.t("smartcollege.filters.approved").toString()]: "Approved",
      [i18n.t("smartcollege.filters.published").toString()]: "Published",
      [i18n.t("smartcollege.filters.draft").toString()]: "Draft",
    };
  }
  get getTypeMap(): { [id: string]: string } {
    return {
      [i18n.t("smartcollege.filters.all").toString()]: "All",
      [i18n.t("smartcollege.targetGroups.MECHANIC").toString()]: "MECHANIC",
      [i18n.t("smartcollege.targetGroups.ELECTRICIAN").toString()]: "ELECTRICIAN",
      [i18n.t("smartcollege.targetGroups.OPERATOR").toString()]: "OPERATOR",
    };
  }

  get tableHeaders(): DataTableHeader[] {
    return [
      {
        text: "",
        value: "id",
        width: "1%",
        sortable: false,
      },
      {
        text: i18n.t("smartcollege.overviewTable.headers.title").toString(),
        sortable: true,
        value: "title",
      },
      {
        text: i18n.t("smartcollege.overviewTable.headers.version").toString(),
        align: "start",
        sortable: false,
        value: "version",
        width: "2%",
      },
      {
        text: i18n.t("smartcollege.overviewTable.headers.status").toString(),
        value: "status",
        sortable: false,
      },
      {
        text: i18n.t("smartcollege.overviewTable.headers.language").toString(),
        value: "language",
        sortable: false,
      },
      {
        text: i18n.t("smartcollege.overviewTable.headers.targetGroups").toString(),
        value: "targetGroups",
        sortable: false,
      },
      {
        text: i18n.t("smartcollege.overviewTable.headers.assetTypes").toString(),
        value: "assetTypes",
        sortable: false,
      },
      {
        text: "",
        value: "context-menu",
        sortable: false,
        width: "1%",
      },
      {
        text: "",
        value: "data-table-expand",
        sortable: false,
      },
    ];
  }

  async created() {
    if (await hasPermission("deleteLesson")) {
      this.actions.push({
        tooltip: this.$t("smartcollege.lessoneditor.actions.deleteLesson").toString(),
        icon: "mdi-delete",
        callback: async (lesson: Lesson) => await this.deleteLesson(lesson),
      });
    }
  }

  @Watch("filterBarItems", { deep: true })
  @Watch("searchInput")
  changeSearchInput() {
    this.filterStore.setAllFilters({
      element: this.elementPath,
      filters: this.filterBarItems,
      searchInput: this.searchInput,
    });
  }

  async mounted() {
    const owner: Owner = {
      tenant: "fettecompacting",
      siteId: "global",
    };
    const { types }: MachineTypes = (await this.machineTypesAPI.getMachineTypes(
      owner
    )) as MachineTypes;

    this.machineTypes = types;

    this.filterBarItems[3].items = [
      i18n.t("smartcollege.filters.all").toString(),
      ...this.machineTypes,
    ];

    if (this.filterStore.filters[this.elementPath]) {
      this.filterBarItems = this.filterStore.filters[this.elementPath].filterItems;
      this.searchInput = this.filterStore.filters[this.elementPath].searchInput;
      this.uiStore.setSearchInput(this.searchInput);
    } else {
      this.filterStore.setAllFilters({
        element: this.elementPath,
        filters: this.filterBarItems,
        searchInput: this.searchInput,
      });
    }
  }

  newVersionDialog(lesson: Lesson, translate: boolean) {
    dialogStore.openDialog({
      componentName: "new-version-dialog",
      data: {
        baseElement: { ...lesson, type: "lesson" },
        translate,
      },
      customDialog: true,
    });
  }

  sort(items: any, index: string, isDescending: string) {
    const sortedItems = sortItems(items, index, isDescending);
    return sortedItems;
  }

  get selectedLanguage(): languages {
    if (this.filterBarItems[1].value == "Default") return this.$i18n.locale as languages;
    return this.filterBarItems[1].value as languages;
  }

  get selectedMachineType(): string {
    let filtersMap = {
      [i18n.t("smartcollege.filters.all").toString()]: "All",
    };

    const machinesMaps: { [id: string]: string } = {};

    this.filterBarItems[3].items.forEach((elem) => {
      machinesMaps[elem] = elem;
    });

    filtersMap = { ...machinesMaps, ...filtersMap };
    return filtersMap[this.filterBarItems[3].value];
  }

  get selectedTargetGroup(): string {
    return this.getTypeMap[this.filterBarItems[2].value];
  }
  get selectedStatus(): string {
    return this.getStatusMap[this.filterBarItems[0].value];
  }
  get showChangelogs(): boolean {
    return this.showChangelog;
  }

  getSelectedVersion(id: string): string {
    return this.selectedVersion[id] ?? "";
  }

  openChangelog(lesson: Lesson) {
    this.changelogData = lesson;
    this.showChangelog = true;
  }

  changeVersion(id: string, e: string): any {
    this.selectedVersion[id] = e;

    //finds the correct object that we want to display
    const itemFind = this.filteredItems.find((f) => f.id == id);
    const versionFind = itemFind?.publishedVersions[e];
    this.filteredNewVersion = versionFind;

    this.reloadFilteredItems();
  }

  closeChangelog(): void {
    this.showChangelog = !this.showChangelog;
  }

  @Watch("selectedMachineType")
  @Watch("selectedTargetGroup")
  @Watch("selectedStatus")
  @Watch("searchInput.length")
  @Watch("lessonStore.lessonList", { immediate: true })
  async reloadFilteredItems() {
    const lessons = await this.lessonStore.getAllLessonVersions(this.selectedLanguage);

    const filteredValues = contentFilterItems(
      lessons,
      this.filteredItems,
      this.expandedItems,
      this.selectedVersion,
      this.selectedTargetGroup,
      this.selectedStatus,
      this.selectedMachineType,
      this.filteredNewVersion,
      this.searchInput
    );
    this.filteredItems = filteredValues.filteredItems;
    this.expandedItems = filteredValues.expandedItems;
  }

  @Watch("selectedLanguage")
  async languageChanged() {
    //request sublessons with the new language and then refilter them with the searchInput value
    const content = await this.lessonStore.getAllLessonVersions(this.selectedLanguage);
    const refilteredContent = refilterContent(
      content,
      this.searchInput,
      this.selectedStatus,
      this.selectedVersion
    );
    this.filteredItems = refilteredContent.filteredItems;
    this.expandedItems = refilteredContent.expandedItems;
  }

  goToLesson(lessonId: string, version: string, language: string) {
    this.$router.push({
      name: ROUTES.SmartCollegeLessonDetail,
      params: {
        lessonId: lessonId,
      },
      query: {
        version: version,
        language: language,
      },
    });
  }

  createNewLesson() {
    // openCreateContentDialog("lesson");
    this.$router.push({
      name: ROUTES.SmartCollegesLessonNew,
    });
  }

  async duplicate(lesson: Lesson) {
    const completeLesson = await this.lessonStore.getLesson(lesson);
    const newLesson = {
      ...completeLesson,
      id: uuid(),
      version: "1.0",
      status: "draft",
    } as Lesson;
    await this.lessonStore.saveLesson(newLesson);
  }

  async deleteLesson(lesson: Lesson) {
    this.$refs.confirmDeleteDialog.open({
      confirmCallback: async () => {
        this.lessonStore.deleteLesson(lesson);
      },
      message: this.$t("smartcollege.list.deleteNotification.message", {
        name: lesson.title,
      }).toString(),
    });
  }

  @Watch("uiStore.getSearchInput")
  searchInputs() {
    return (this.searchInput = this.uiStore.getSearchInput);
  }

  genId() {
    return uuid();
  }
}
