import { Action, Module, Mutation, MutationAction, VuexModule } from "vuex-module-decorators";
import { MachineTypesAPI } from "shared-alva/api/machineTypes";
import { MachineTypes } from "shared-alva/models/index";
import { ParamItemChange } from "@/components/machineTypes/MachineTypeList/MachineTypeItem/MachineTypeItem.vue";

import store from "@/store/index";
import awsconfig from "@/aws-config";

@Module({ dynamic: true, namespaced: true, store, name: "machineTypes" })
class MachineTypesModule extends VuexModule {
  private machineTypes: string[] = [];
  private version: number = 0;
  private loading = false;
  private readonly machineTypeClient = new MachineTypesAPI(awsconfig.API_V2);
  private readonly owner = { tenant: "fettecompacting", siteId: "global" };

  get isLoading() {
    return this.loading;
  }

  get getMachineTypes() {
    return this.machineTypes;
  }

  get getVersion() {
    return this.version;
  }

  @Mutation
  SET_LOADING(loading: boolean) {
    this.loading = loading;
  }

  @Mutation
  SET_MACHINE_TYPES(machineTypes: string[]) {
    this.machineTypes = machineTypes;
  }

  @Mutation
  SET_VERSION(version: number) {
    this.version = version;
  }

  @Action({ rawError: true })
  async fetchMachineTypes() {
    this.SET_LOADING(true);
    const { types, version } = (await this.machineTypeClient.getMachineTypes(
      this.owner
    )) as MachineTypes;
    this.SET_LOADING(false);
    this.SET_VERSION(version);
    this.SET_MACHINE_TYPES(types);
  }

  @Action({ rawError: true })
  async saveMachineTypes(): Promise<boolean> {
    this.SET_LOADING(true);
    const newVersion = this.version + 1;
    const machineTypesToPersist: MachineTypes = {
      version: newVersion,
      types: this.machineTypes,
    };
    const responseSaveMachine = await this.machineTypeClient.setMachineTypes(
      this.owner,
      machineTypesToPersist
    );
    this.SET_LOADING(false);
    if (!responseSaveMachine) return false;
    this.SET_VERSION(newVersion);

    return true;
  }

  @Action({ rawError: true })
  deleteMachineTypeByIndex(indexToDelete: number) {
    const machineTypesDeleted = this.machineTypes.filter((_, index) => index !== indexToDelete);
    this.SET_MACHINE_TYPES(machineTypesDeleted);
  }

  @Action({ rawError: true })
  swapMachineType({ targetIndex, indexToSwap }: { targetIndex: number; indexToSwap: number }) {
    const copyMachineType = [...this.machineTypes];
    if (!copyMachineType[indexToSwap]) return null;
    const tempMachineType = copyMachineType[targetIndex];
    copyMachineType[targetIndex] = copyMachineType[indexToSwap];
    copyMachineType[indexToSwap] = tempMachineType;

    this.SET_MACHINE_TYPES(copyMachineType);
  }

  @Action({ rawError: true })
  swapMachineTypeUp(machineIndex: number) {
    this.swapMachineType({ targetIndex: machineIndex, indexToSwap: machineIndex - 1 });
  }

  @Action({ rawError: true })
  swapMachineTypeDown(machineIndex: number) {
    this.swapMachineType({ targetIndex: machineIndex, indexToSwap: machineIndex + 1 });
  }

  @Action({ rawError: true })
  addMachineType() {
    this.SET_MACHINE_TYPES([...this.machineTypes, ""]);
  }

  @Action({ rawError: true })
  machineTypeChange(itemChange: ParamItemChange) {
    this.machineTypes![itemChange.itemIndex] = itemChange.text;
  }
}

export default MachineTypesModule;
