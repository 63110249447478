export enum languages {
  de = "de",
  en = "en",
  zh = "zh",
}

const userLang = navigator.language;

function getLanguage() {
  if (userLang.includes("de")) {
    return languages.de;
  }

  if (userLang.includes("zh")) {
    return languages.zh;
  }

  return languages.en;
}

export const defaultLanguage: languages = getLanguage();
