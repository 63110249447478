
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { Slide } from "shared-alva/models";
import MediaBlock from "@/components/sublessons/blocks/MediaBlock.vue";
import OsdiTextField from "shared-alva/components/inputs/OsdiTextField.vue";
import HintBlock from "@/components/sublessons/blocks/HintBlock.vue";
import OsdiTextArea from "shared-alva/components/inputs/OsdiTextArea.vue";
//@ts-ignore
import { Bold, ListItem, BulletList, OrderedList, Code } from "tiptap-vuetify";
import OsdiMultilineTextField from "shared-alva/components/inputs/OsdiMultilineTextField.vue";
import WarningBlock from "../blocks/WarningBlock.vue";

@Component({
  components: {
    OsdiMultilineTextField,
    MediaBlock,
    OsdiTextField,
    OsdiTextArea,
    HintBlock,
    WarningBlock,
  },
})
export default class SlideText extends Vue {
  extensions = [Bold, ListItem, BulletList, OrderedList, Code];

  @Prop()
  slide!: Slide;

  @Prop()
  sublessonId!: string;
}
