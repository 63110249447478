import {
  MediaEntity,
  Sublesson,
  LessonMetadata,
  TrainingMetadata,
  SublessonMetadata,
  Quiz,
  Question,
} from "shared-alva/models";
import _ from "lodash";
import { TrainingElementVersions } from "./models";
import * as semver from "semver";
import { languages } from "shared-alva/languages";

export function changePositionInArray(array: any, fromIndex: number, toIndex: number) {
  const myElem = Object.assign({}, array[fromIndex]);
  array.splice(fromIndex, 1);
  array.splice(toIndex, 0, myElem);
}

export function downloadContent(data: string, name: string): Promise<void> {
  return new Promise((resolve) => {
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.style.display = "none";
    let blob;
    try {
      blob = new Blob([JSON.stringify(JSON.parse(data), null, 4)], { type: "octet/stream" });
    } catch {
      blob = new Blob([data], { type: "octet/stream" });
    }

    const url = window.URL.createObjectURL(blob);
    a.href = url;
    a.download = name;
    a.click();
    window.URL.revokeObjectURL(url);
    resolve();
  });
}

export function isValidThumbnail(element: MediaEntity | undefined) {
  return element && element.mimeType && element.uuid;
}

export function toSaveMedia(element: MediaEntity | undefined) {
  if (!element) return undefined;

  return _.omit(element, "signedUrl");
}

export function isValidThumbnail_v2(element: MediaEntity | undefined) {
  return element && element.mimeType && element.uuid;
}

export function toSaveMedia_v2(element: MediaEntity | undefined) {
  if (!element) return undefined;

  return _.omit(element, "signedUrl");
}

export function toSaveSublesson(element: Sublesson | undefined) {
  if (!element) return undefined;

  const newSlides = element.slides.map((slide) => {
    const newMedia: MediaEntity[] = [];
    const newSecondaryMedia: MediaEntity[] = [];

    slide.media.forEach((element) => {
      const saveMedia = toSaveMedia(element);
      if (saveMedia) newMedia.push(saveMedia);
    });

    slide.secondaryMedia.forEach((element) => {
      const saveMedia = toSaveMedia(element);
      if (saveMedia) newSecondaryMedia.push(saveMedia);
    });

    return {
      ...slide,
      media: newMedia,
      secondaryMedia: newSecondaryMedia,
    };
  });
  return { ...element, slides: newSlides };
}

export function groupElements<
  T extends TrainingMetadata | SublessonMetadata | LessonMetadata | Quiz | Question
>({ elements }: { elements: T[] }): TrainingElementVersions[] {
  const elementList: TrainingElementVersions[] = [];
  elements.forEach((element) => {
    let index = elementList.findIndex((el) => el.id == element.id);
    if (index == -1) {
      elementList.push({ id: element.id, publishedVersions: {}, allVersions: [] });
      index = elementList.length - 1;
    }

    if (
      element.status == "published" &&
      (!elementList[index].latestPublished ||
        semver.gt(element.version + ".0", elementList[index].latestPublished!.version + ".0"))
    ) {
      elementList[index].latestPublished = element;
    }

    if (
      !elementList[index].latest ||
      semver.gt(element.version + ".0", elementList[index].latest!.version + ".0")
    ) {
      elementList[index].latest = element;
    }

    if (element.status !== "published") {
      elementList[index].draft = element;
    } else {
      elementList[index].publishedVersions[element.version] = element;
    }

    elementList[index].allVersions.push(element);
  });

  return elementList;
}

export function groupElementVersions<
  T extends TrainingMetadata | SublessonMetadata | LessonMetadata | Quiz | Question
>({
  id,
  language,
  elements,
}: {
  id: string;
  language: languages;
  elements: T[];
}): TrainingElementVersions {
  const elementVersions: TrainingElementVersions = {
    id,
    allVersions: [],
    publishedVersions: {},
  };

  elements.forEach((element) => {
    if (element.language != language) return;

    if (
      element.status == "published" &&
      (!elementVersions.latestPublished ||
        semver.gt(element.version + ".0", elementVersions.latestPublished!.version + ".0"))
    ) {
      elementVersions.latestPublished = element;
    }

    if (
      !elementVersions.latest ||
      semver.gt(element.version + ".0", elementVersions.latest!.version + ".0")
    ) {
      elementVersions.latest = element;
    }

    if (element.status !== "published") {
      elementVersions.draft = element;
    } else if (element.status == "published") {
      elementVersions.publishedVersions[element.version] = element;
    }

    elementVersions.allVersions.push(element);
  });

  return elementVersions;
}

export function AddOne(str: string): string {
  return (parseInt(str) + 1).toString();
}
