
import { Component, Prop, Vue } from "vue-property-decorator";
import { Slide } from "shared-alva/models";
import SlideImage from "@/components/sublessons/slides/SlideImage.vue";
import SlideImageCompare from "@/components/sublessons/slides/SlideImageCompare.vue";
import SlideTextLeftImageRight from "@/components/sublessons/slides/SlideTextLeftImageRight.vue";
import SlideTextRightImageLeft from "@/components/sublessons/slides/SlideTextRightImageLeft.vue";
import SlideText from "@/components/sublessons/slides/SlideText.vue";
import OsdiButton from "shared-alva/components/buttons/OsdiButton.vue";

@Component({
  components: {
    SlideImage,
    SlideImageCompare,
    SlideTextLeftImageRight,
    SlideTextRightImageLeft,
    SlideText,
    OsdiButton,
  },
})
export default class SlideEditor extends Vue {
  @Prop()
  slide!: Slide;

  @Prop()
  sublessonId!: string;
}
