
import { Component, Vue } from "vue-property-decorator";
import { ElementStatusType, SaveLearningPath, TrainingElementReference } from "shared-alva/models";
import { PickerElement } from "@/models";
import { Training } from "shared-alva/models";
import { languages } from "shared-alva/languages";
import draggable from "vuedraggable";
import ElementPicker from "@/components/ElementPicker.vue";
import ElementPreview from "@/components/ElementPreview.vue";
import ElementDropZone from "@/components/ElementDropZone.vue";
import OsdiButton from "shared-alva/components/buttons/OsdiButton.vue";
import OsdiTextField from "shared-alva/components/inputs/OsdiTextField.vue";
import OsdiCloseButton from "shared-alva/components/buttons/OsdiCloseButton.vue";
import OsdiIconButton from "shared-alva/components/buttons/OsdiIconButton.vue";
import OsdiTooltipButton from "shared-alva/components/buttons/OsdiTooltipButton.vue";
import OsdiDeleteButton from "shared-alva/components/buttons/OsdiDeleteButton.vue";
import OsdiSnackbar from "shared-alva/components/snackbars/OsdiSnackbar.vue";
import OsdiConfirmDialog from "shared-alva/components/dialogs/OsdiConfirmDialog.vue";
import OsdiMenu from "shared-alva/components/menus/OsdiMenu.vue";
import PublishDialog from "../../PublishDialog.vue";
import MediaLibrary from "@/components/media/MediaLibrary.vue";
import TargetSettings from "@/components/TargetSettings.vue";
import learningPathStore from "@/store/modules/learningPath-store";
import LearningPathModule from "@/store/modules/learningPath-store";
import { LearningPathUI } from "@/store/modules/learningPath-store";
import LearningPathToolbar from "./Toolbar/LearningPathToolbar.vue";

@Component({
  components: {
    ElementDropZone,
    ElementPreview,
    ElementPicker,
    draggable,
    OsdiTextField,
    OsdiButton,
    OsdiConfirmDialog,
    OsdiCloseButton,
    OsdiSnackbar,
    OsdiIconButton,
    OsdiDeleteButton,
    OsdiMenu,
    PublishDialog,
    MediaLibrary,
    TargetSettings,
    OsdiTooltipButton,
    LearningPathToolbar,
  },
})
export default class LearningPathDetail extends Vue {
  tabs = null;
  drawer = false;
  snackbarMessage = "";
  learningPathUI: LearningPathUI = {} as LearningPathUI;
  pickedElements: PickerElement[] = [];
  pickerElements: PickerElement[] = [];
  isDropZoneHighlighted = false;
  learningPathStore = LearningPathModule;
  $refs!: {
    snackbar: OsdiSnackbar;
    confirmDeleteDialog: OsdiConfirmDialog;
  };

  async created() {
    const learningPathId = this.$route.params.learningPathId;
    await this.learningPathStore.mountLearningPathUIbyId(learningPathId);
    const trainingWithLanguageAdapted = await this.learningPathStore.filterTrainingByUserLanguage(
      this.$i18n.locale as languages
    );
    this.pickerElements = trainingWithLanguageAdapted?.map((training) => {
      return {
        owner: this.learningPathStore.owner,
        id: training.id,
        description: "",
        language: training.language,
        type: "training",
        version: training.version,
        title: training.title,
        element: training.title,
        parentLanguage: this.learningPathStore.learningPathUI.language,
        status: training.status!,
      } as PickerElement;
    });
    this.pickedElements = this.learningPathStore.learningPathUI.elements?.map((training) => {
      return {
        owner: this.learningPathStore.owner,
        id: training.id,
        language: training.language,
        version: training.version,
        type: "training",
        title: (training as Training).title,
        element: training.title,
        parentLanguage: training.language,
        status: training.status || "unknown",
      } as PickerElement;
    });
  }

  get getOverflow() {
    return this.drawer ? "initial" : "hidden";
  }

  highlightDropZone(highlight: boolean) {
    this.isDropZoneHighlighted = highlight;
  }

  snackbarAction(isToggleStatus: boolean) {
    this.$refs.snackbar.open();
    this.snackbarMessage = isToggleStatus
      ? (this.$t("smartcollege.learningPathdetails.create.snackbar.publishedConvert") as string)
      : (this.$t("smartcollege.learningPathdetails.create.snackbar.success") as string);
  }

  async handleOnSave(status: ElementStatusType = "draft", isToggleStatus = false) {
    const lPathTrainingsToSave: TrainingElementReference[] = this.pickedElements.map((training) => {
      return {
        id: training.id,
        language: training.language,
        owner: this.learningPathStore.owner,
        version: training.version,
      } as TrainingElementReference;
    });
    const lPathToSave: SaveLearningPath = {
      ...this.learningPathStore.learningPathUI,
      status: status,
      lastUpdated: 0,
      language: "en",
      owner: this.learningPathStore.owner,
      elements: lPathTrainingsToSave,
    };
    const hasSavedLPath = await this.learningPathStore.saveLearningPath(lPathToSave);
    if (!hasSavedLPath) {
      this.$refs.snackbar.open();
      this.snackbarMessage = this.$t(
        "smartcollege.learningPathdetails.create.snackbar.wrong"
      ) as string;
      return;
    }
    this.snackbarAction(isToggleStatus);
  }

  toggleDrawer() {
    this.drawer = !this.drawer;
  }

  async handleOnClickTogglePublish() {
    const isPublished = this.learningPathStore.learningPathUI.status === "published";
    const toggleStatus: ElementStatusType = isPublished ? "draft" : "published";
    await this.handleOnSave(toggleStatus, true);
    const learningPathId = this.$route.params.learningPathId;
    await this.learningPathStore.mountLearningPathUIbyId(learningPathId);
  }
}
