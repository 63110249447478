import { Module, getModule, VuexModule, Mutation, Action } from "vuex-module-decorators";
import store from "@/store/index";

import { Owner, Training, TrainingMetadata, TrainingElementReference } from "shared-alva/models";
import { languages } from "shared-alva/languages";
import { TrainingAPI } from "shared-alva";
import { GroupedTrainingElements, TrainingElementVersions } from "@/models";
import awsconfig from "@/aws-config";
import * as semver from "semver";

@Module({ dynamic: true, namespaced: true, store, name: "trainings_v2" })
class TrainingsModule_v2 extends VuexModule {
  private client = new TrainingAPI(awsconfig.API_V2);

  // State
  public trainings: Training[] = [];
  public trainingList: GroupedTrainingElements = {};
  public isLoading = false;

  // Mutations
  @Mutation
  setTrainings(trainings: Training[]) {
    this.trainings = trainings;
  }

  @Mutation
  setTrainingList(trainingList: GroupedTrainingElements) {
    this.trainingList = trainingList;
  }

  @Mutation
  setLoading(loading: boolean) {
    this.isLoading = loading;
  }

  // Actions
  @Action({ commit: "setTrainingList" })
  async fetchTrainings(params: { owner: Owner; language: languages; isPublished: boolean }) {
    this.setLoading(true);
    const trainings = await this.client.getTrainings(
      params.owner,
      params.language as languages,
      params.isPublished
    );
    const trainingList = { ...this.trainingList };
    trainingList[params.language] = await this.groupTrainings({ trainings });
    this.setLoading(false);
    return trainingList;
  }

  @Action
  async getAllTrainingVersions(language: languages): Promise<TrainingElementVersions[]> {
    if (!language) return [];
    if (!(language in this.trainingList)) {
      await this.fetchTrainings({
        owner: { tenant: "fettecompacting", siteId: "global" },
        language: language,
        isPublished: false,
      });
    }
    return this.trainingList[language];
  }

  @Action({ rawError: true })
  async createTrainingDraft({
    owner,
    id,
    srcLang,
    srcVersion,
    newLang,
    newVersion,
  }: {
    owner: Owner;
    id: string;
    srcLang: languages;
    srcVersion: string;
    newLang: languages;
    newVersion: string;
  }) {
    this.setLoading(true);
    const success = await this.client.createTrainingDraft(
      owner,
      id,
      srcLang,
      srcVersion,
      newLang,
      newVersion
    );

    if (success) {
      const srcTraining = this.trainingList[srcLang]
        .find((el: TrainingElementVersions) => {
          return el.id == id;
        })
        ?.allVersions.find((el) => {
          return el.version == srcVersion;
        });

      if (srcTraining) {
        const newTraining = {
          ...srcTraining,
          language: newLang,
          version: newVersion,
        };

        const currentTrainingList = { ...this.trainingList } || {};
        const trainingsForNewLang = currentTrainingList[newLang] || [];
        const trainingVersionsIndex = trainingsForNewLang.findIndex((el) => {
          return el.id == id;
        });

        if (trainingVersionsIndex == -1) {
          trainingsForNewLang.push(
            this.groupTrainingVersions({
              id,
              language: newLang,
              trainings: [newTraining] as TrainingMetadata[],
            })
          );
        } else {
          trainingsForNewLang[trainingVersionsIndex] = this.groupTrainingVersions({
            id,
            language: newLang,
            trainings: trainingsForNewLang[trainingVersionsIndex].allVersions as TrainingMetadata[],
          });
        }

        this.setTrainingList(currentTrainingList);
      }
    }
    this.setLoading(false);
    return success;
  }

  @Action
  async saveTraining(training: Training) {
    const success = await this.client.saveTraining(training);
    if (success) {
      // Update complete training
      const trainingIndex = this.trainings.findIndex((t) => {
        return (
          t.id == training.id && t.language == training.language && t.version == training.version
        );
      });
      const newTrainings = [...this.trainings];
      if (trainingIndex != -1) {
        newTrainings[trainingIndex] = training;
      } else newTrainings.push(training);
      this.setTrainings(newTrainings);

      // update grouped training list
      const currentTrainingList = { ...this.trainingList } || {};
      const trainingsForLanguage = currentTrainingList[training.language] || [];
      const trainingVersionsIndex = trainingsForLanguage.findIndex((el) => {
        return el.id == training.id;
      });

      if (trainingVersionsIndex == -1) {
        trainingsForLanguage.push({
          allVersions: [training],
          id: training.id,
          draft: training,
          latest: training,
          publishedVersions: {},
        });
        this.setTrainingList(currentTrainingList);
      } else {
        const newTrainings = trainingsForLanguage[trainingVersionsIndex].allVersions.filter(
          (el) => {
            return el.version != training.version;
          }
        );
        newTrainings.push(training);
        trainingsForLanguage[trainingVersionsIndex] = await this.groupTrainingVersions({
          id: training.id,
          language: training.language as languages,
          trainings: newTrainings as TrainingMetadata[],
        });
        this.setTrainingList(currentTrainingList);
      }
    }
  }

  @Action
  async deleteTraining({
    id,
    language,
    version,
  }: {
    id: string;
    language: languages | string;
    version: string;
  }) {
    this.setLoading(true);

    const success = await this.client.deleteTraining({
      owner: { tenant: "fettecompacting", siteId: "global" },
      type: "training",
      id,
      language: language,
      version,
    });

    if (success) {
      // Update full trainings
      const newTrainings = [...this.trainings];
      this.setTrainings(
        newTrainings.filter((t) => t.id != id && t.language != language && t.version != version)
      );

      // update grouped training list
      const currentTrainingList = { ...this.trainingList } || {};
      const trainingsForLanguage = currentTrainingList[language] || [];
      const trainingVersionsIndex = trainingsForLanguage.findIndex((el) => {
        return el.id == id;
      });

      if (trainingVersionsIndex == -1) return;
      else {
        trainingsForLanguage[trainingVersionsIndex].allVersions = trainingsForLanguage[
          trainingVersionsIndex
        ].allVersions.filter((e) => {
          return e.id != id && e.language != language && e.version != version;
        });
        delete trainingsForLanguage[trainingVersionsIndex].draft;
        if (trainingsForLanguage[trainingVersionsIndex].allVersions.length == 0) {
          trainingsForLanguage.splice(trainingVersionsIndex, 1);
        }
      }

      this.setTrainingList(currentTrainingList);
    }
    this.setLoading(false);
  }

  @Action
  async getTraining(training: TrainingElementReference) {
    const trainingExists = this.trainings.find(
      (t) =>
        t.id === training?.id &&
        t.language === training?.language &&
        t.version === training?.version
    );

    if (trainingExists) return trainingExists;
    else {
      this.setLoading(true);
      const fetchedTraining = await this.client.getTraining(training);
      if (fetchedTraining) {
        this.setTrainings([...this.trainings, fetchedTraining]);
      }
      this.setLoading(false);
      return fetchedTraining;
    }
  }

  @Action({ rawError: true })
  async fetchTraining(trainingId: TrainingElementReference) {
    this.setLoading(true);
    let trainingExists = (await this.trainings).find(
      (t) =>
        t.id === trainingId.id &&
        t.language == trainingId.language &&
        t.version == trainingId.version
    );

    if (!trainingExists) {
      const training = await this.client.getTraining(trainingId);
      this.trainings.push(training!);
      this.setTrainings(this.trainings);
    }

    trainingExists = this.trainings.find(
      (t) =>
        t.id === trainingId.id &&
        t.language == trainingId.language &&
        t.version == trainingId.version
    );

    this.setLoading(false);
    return trainingExists;
  }

  @Action
  getAvailableVersions(training: TrainingElementReference): string[] {
    const allVersions = this.trainingList[training.language].find((el) => {
      return el.id == training.id;
    });

    const draftVersions = allVersions?.draft?.version ? [allVersions.draft.version] : [];
    const publishedVersions = Object.keys(allVersions?.publishedVersions || {});
    return [...publishedVersions, ...draftVersions];
  }

  @Action
  async publishTraining({
    reference,
    changelogMessage,
    editorEmail,
  }: {
    reference: TrainingElementReference;
    changelogMessage: string;
    editorEmail: string;
  }) {
    const { id, language, version } = reference;
    this.setLoading(true);
    const success = await this.client.publishTraining(reference, changelogMessage, editorEmail);

    if (success) {
      // Update full trainings
      const newTrainings = [...this.trainings];
      const training = newTrainings.find(
        (t) => t.id == id && t.language == language && t.version == version
      );
      if (!training) {
        this.setLoading(false);
        return;
      }
      training.status = "published";
      this.setTrainings(newTrainings);

      // update grouped training list
      const currentTrainingList = { ...this.trainingList } || {};
      const trainingsForLanguage = currentTrainingList[language] || [];
      const trainingVersionsIndex = trainingsForLanguage.findIndex((el) => {
        return el.id == id;
      });

      if (trainingVersionsIndex == -1) {
        this.setLoading(false);
        return;
      } else {
        delete trainingsForLanguage[trainingVersionsIndex].draft;
      }
      trainingsForLanguage[trainingVersionsIndex].publishedVersions[version] = training;
      delete trainingsForLanguage[trainingVersionsIndex].draft;
      this.setTrainingList(currentTrainingList);
    }
    this.setLoading(false);
  }

  // Helpers
  @Action({})
  groupTrainings({ trainings }: { trainings: TrainingMetadata[] }) {
    const trainingList: TrainingElementVersions[] = [];
    trainings.forEach((training) => {
      let trainingIndex = trainingList.findIndex((el) => el.id == training.id);
      if (trainingIndex == -1) {
        trainingList.push({ id: training.id, publishedVersions: {}, allVersions: [] });
        trainingIndex = trainingList.length - 1;
      }

      if (
        training.status == "published" &&
        (!trainingList[trainingIndex].latestPublished ||
          semver.gt(
            training.version + ".0",
            trainingList[trainingIndex].latestPublished!.version + ".0"
          ))
      ) {
        trainingList[trainingIndex].latestPublished = training;
      }

      if (
        !trainingList[trainingIndex].latest ||
        semver.gt(training.version + ".0", trainingList[trainingIndex].latest!.version + ".0")
      ) {
        trainingList[trainingIndex].latest = training;
      }

      if (training.status != "published") {
        trainingList[trainingIndex].draft = training;
      } else if (training.status == "published") {
        trainingList[trainingIndex].publishedVersions[training.version] = training;
      }

      trainingList[trainingIndex].allVersions.push(training);
    });

    return trainingList;
  }

  @Action({})
  groupTrainingVersions({
    id,
    language,
    trainings,
  }: {
    id: string;
    language: languages;
    trainings: TrainingMetadata[];
  }): TrainingElementVersions {
    const trainingVersions: TrainingElementVersions = {
      id,
      allVersions: [],
      publishedVersions: {},
    };

    trainings.forEach((training) => {
      if (training.language != language) return;

      if (
        training.status == "published" &&
        (!trainingVersions.latestPublished ||
          semver.gt(training.version + ".0", trainingVersions.latestPublished!.version + ".0"))
      ) {
        trainingVersions.latestPublished = training;
      }

      if (
        !trainingVersions.latest ||
        semver.gt(training.version + ".0", trainingVersions.latest!.version + ".0")
      ) {
        trainingVersions.latest = training;
      }

      if (training.status !== "published") {
        trainingVersions.draft = training;
      } else if (training.status == "published") {
        trainingVersions.publishedVersions[training.version] = training;
      }

      trainingVersions.allVersions.push(training);
    });

    return trainingVersions;
  }

  @Action
  getAllPublishedVersions(training: TrainingElementReference) {
    const allVersions = this.trainingList[training.language].find((el) => {
      return el.id == training.id;
    });
    return Object.keys(allVersions?.publishedVersions || {});
  }

  @Action
  async resetTraining({
    id,
    language,
    owner,
    version,
  }: {
    id: string;
    language: languages;
    owner: Owner;
    version: string;
  }) {
    this.REMOVE_TRAINING(id);
    const elementReference: TrainingElementReference = {
      id,
      language,
      version,
      owner,
    };

    await this.fetchTraining(elementReference);
  }

  @Mutation
  REMOVE_TRAINING(id: string) {
    this.trainings = this.trainings.filter((training) => training.id != id);
  }
}

export default getModule(TrainingsModule_v2);
