
import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
import draggable from "vuedraggable";
import OsdiSearchField from "shared-alva/components/inputs/OsdiSearchField.vue";
import ElementPreview from "@/components/ElementPreview.vue";

import { languages } from "shared-alva/languages";
import { PickerElement } from "@/models";

@Component({ components: { ElementPreview, draggable, OsdiSearchField } })
export default class ElementPicker extends Vue {
  @Prop()
  private assignedElements!: PickerElement[];

  @Prop()
  private elements!: PickerElement[];

  @Prop()
  onDragStart!: () => void;

  @Prop()
  onDragEnd!: () => void;

  @Prop({ default: false })
  private allowLessons!: boolean;

  @Prop({ default: languages.en })
  private language!: languages;

  private allAssignableElements: PickerElement[] = [];
  searchTerm: string = "";

  get filteredElements(): PickerElement[] {
    const unassignedElements = this.filterForUnassignedElements(this.allAssignableElements);
    // TODO: search in backend
    const filteredElems = unassignedElements.filter((element) =>
      element.element.toLocaleLowerCase().includes(this.searchTerm.toLocaleLowerCase())
    );

    return filteredElems;
  }

  async mounted() {
    await this.loadData();
  }

  public async refreshDraggables() {
    await this.loadData();
  }

  @Watch("elements", { immediate: true })
  async loadData() {
    const sortByElement = (a: PickerElement, b: PickerElement) =>
      a.element.localeCompare(b.element);
    this.allAssignableElements = this.filterForUnassignedElements(this.elements).sort(
      sortByElement
    );
  }

  filterForUnassignedElements(elements: PickerElement[]) {
    return elements.filter(
      (elements) =>
        !this.assignedElements.map((assignedElement) => assignedElement.id).includes(elements.id)
    );
  }
}
