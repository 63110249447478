import { render, staticRenderFns } from "./MenuOptions.vue?vue&type=template&id=530f841d&scoped=true&"
import script from "./MenuOptions.vue?vue&type=script&lang=ts&"
export * from "./MenuOptions.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "530f841d",
  null
  
)

export default component.exports