import { Module, getModule, VuexModule, Mutation, Action } from "vuex-module-decorators";
import store from "@/store/index";

import { LessonAPI } from "shared-alva";
import { languages } from "shared-alva/languages";
import {
  Owner,
  Lesson,
  LessonMetadata,
  TrainingElementReference,
  Sublesson,
} from "shared-alva/models";
import { GroupedTrainingElements, TrainingElementVersions } from "@/models";
import awsconfig from "@/aws-config";
import * as semver from "semver";

@Module({ dynamic: true, namespaced: true, store, name: "lessons_v2" })
class LessonsModule_v2 extends VuexModule {
  private client = new LessonAPI(awsconfig.API_V2);

  // State
  public lessons: Lesson[] = [];
  public lessonList: GroupedTrainingElements = {};
  public isLoading = false;

  // Mutations
  @Mutation
  setLessons(lessons: Lesson[]) {
    this.lessons = lessons;
  }

  @Mutation
  setLessonList(lessonList: GroupedTrainingElements) {
    this.lessonList = lessonList;
  }

  @Mutation
  setLoading(loading: boolean) {
    this.isLoading = loading;
  }

  // Actions
  @Action({ commit: "setLessonList" })
  async fetchLessons(params: { owner: Owner; language: languages; isPublished: boolean }) {
    this.setLoading(true);
    const lessons = await this.client.getLessons(params.owner, params.language, params.isPublished);
    const lessonList = { ...this.lessonList };
    lessonList[params.language] = await this.groupLessons({ lessons });
    this.setLoading(false);
    return lessonList;
  }

  @Action
  getLessonById(id: string) {
    return this.lessons.find((t) => t.id === id);
  }

  @Action({})
  async getLesson(lesson: TrainingElementReference) {
    const lessonExists = this.lessons.find(
      (t) => t.id === lesson?.id && t.language === lesson?.language && t.version === lesson?.version
    );

    if (lessonExists) return lessonExists;
    else {
      this.setLoading(true);
      const fetchedLesson = await this.client.getLesson(lesson);
      if (fetchedLesson) {
        this.setLessons([...this.lessons, fetchedLesson]);
      }
      this.setLoading(false);
      return fetchedLesson;
    }
  }

  @Action
  async getAllLessonVersions(language: languages): Promise<TrainingElementVersions[]> {
    if (!language) return [];
    if (!(language in this.lessonList)) {
      await this.fetchLessons({
        owner: { tenant: "fettecompacting", siteId: "global" },
        language: language,
        isPublished: false,
      });
    }
    return this.lessonList[language];
  }

  @Action
  getAvailableVersions(lesson: TrainingElementReference): string[] {
    const allVersions = this.lessonList[lesson.language].find((el) => {
      return el.id == lesson.id;
    });

    const draftVersions = allVersions?.draft?.version ? [allVersions.draft.version] : [];
    const publishedVersions = Object.keys(allVersions?.publishedVersions || {});
    return [...publishedVersions, ...draftVersions];
  }

  @Action
  getAllPublishedVersions(lesson: TrainingElementReference) {
    const allVersions = this.lessonList[lesson.language].find((el) => {
      return el.id == lesson.id;
    });
    return Object.keys(allVersions?.publishedVersions || {});
  }

  @Action
  getAvailableLessonIds(language: languages): string[] {
    return this.lessonList[language].map((el) => {
      return el.id;
    });
  }

  @Action
  async createLessonDraft({
    owner,
    id,
    srcLang,
    srcVersion,
    newLang,
    newVersion,
  }: {
    owner: Owner;
    id: string;
    srcLang: languages;
    srcVersion: string;
    newLang: languages;
    newVersion: string;
  }) {
    this.setLoading(true);
    const success = await this.client.createLessonDraft(
      owner,
      id,
      srcLang,
      srcVersion,
      newLang,
      newVersion
    );

    if (success) {
      const srcLesson = this.lessonList[srcLang]
        .find((el: TrainingElementVersions) => {
          return el.id == id;
        })
        ?.allVersions.find((el) => {
          return el.version == srcVersion;
        });

      if (srcLesson) {
        const newLesson = {
          ...srcLesson,
          language: newLang,
          version: newVersion,
        } as LessonMetadata;

        const currentLessonList = { ...this.lessonList } || {};
        const lessonsForNewLang = currentLessonList[newLang] || [];
        const lessonVersionsIndex = lessonsForNewLang.findIndex((el) => {
          return el.id == id;
        });

        if (lessonVersionsIndex == -1) {
          lessonsForNewLang.push(
            this.groupLessonVersions({ id, language: newLang, lessons: [newLesson] })
          );
        } else {
          lessonsForNewLang[lessonVersionsIndex] = this.groupLessonVersions({
            id,
            language: newLang,
            lessons: lessonsForNewLang[lessonVersionsIndex].allVersions as LessonMetadata[],
          });
        }

        this.setLessonList(currentLessonList);
      }
    }
    this.setLoading(false);
    return success;
  }

  @Action({ rawError: true })
  async saveLesson(lesson: Lesson) {
    const success = await this.client.saveLesson(lesson);
    if (success) {
      // Update complete lesson
      const lessonIndex = this.lessons.findIndex((t) => {
        return t.id == lesson.id && t.language == lesson.language && t.version == lesson.version;
      });
      const newLessons = [...this.lessons];
      if (lessonIndex != -1) {
        newLessons[lessonIndex] = lesson;
      } else newLessons.push(lesson);
      this.setLessons(newLessons);

      // update grouped lesson list
      const currentLessonList = { ...this.lessonList } || {};
      const lessonsForLanguage = currentLessonList[lesson.language] || [];
      const lessonVersionsIndex = lessonsForLanguage.findIndex((el) => {
        return el.id == lesson.id;
      });

      if (lessonVersionsIndex == -1) {
        lessonsForLanguage.push({
          allVersions: [lesson],
          id: lesson.id,
          draft: lesson,
          latest: lesson,
          publishedVersions: {},
        });
        this.setLessonList(currentLessonList);
      } else {
        const newLessons = lessonsForLanguage[lessonVersionsIndex].allVersions.filter((el) => {
          return el.version != lesson.version;
        });
        newLessons.push(lesson);
        lessonsForLanguage[lessonVersionsIndex] = await this.groupLessonVersions({
          id: lesson.id,
          language: lesson.language as languages,
          lessons: newLessons as LessonMetadata[],
        });
        this.setLessonList(currentLessonList);
      }
    }
  }

  @Action
  async deleteLesson({
    id,
    language,
    version,
  }: {
    id: string;
    language: languages | string;
    version: string;
  }) {
    this.setLoading(true);

    const success = await this.client.deleteLesson({
      owner: { tenant: "fettecompacting", siteId: "global" },
      type: "lesson",
      id,
      language: language,
      version,
    });

    if (success) {
      // Update full lessons
      const newLessons = [...this.lessons];
      this.setLessons(
        newLessons.filter((t) => t.id != id && t.language != language && t.version != version)
      );

      // update grouped lesson list
      const currentLessonList = { ...this.lessonList } || {};
      const lessonsForLanguage = currentLessonList[language] || [];
      const lessonVersionsIndex = lessonsForLanguage.findIndex((el) => {
        return el.id == id;
      });

      if (lessonVersionsIndex == -1) return;
      else {
        lessonsForLanguage[lessonVersionsIndex].allVersions = lessonsForLanguage[
          lessonVersionsIndex
        ].allVersions.filter((e) => {
          return e.id != id && e.language != language && e.version != version;
        });
        delete lessonsForLanguage[lessonVersionsIndex].draft;
        if (lessonsForLanguage[lessonVersionsIndex].allVersions.length == 0) {
          lessonsForLanguage.splice(lessonVersionsIndex, 1);
        }
      }

      this.setLessonList(currentLessonList);
    }
    this.setLoading(false);
  }

  @Action
  async fetchLesson(lessonId: TrainingElementReference) {
    this.setLoading(true);
    let lessonExists = (await this.lessons).find(
      (t) =>
        t.id === lessonId.id && t.language == lessonId.language && t.version == lessonId.version
    );

    if (!lessonExists) {
      const lesson = await this.client.getLesson(lessonId);
      this.lessons.push(lesson!);
      this.setLessons(this.lessons);
    }

    lessonExists = this.lessons.find(
      (t) =>
        t.id === lessonId.id && t.language == lessonId.language && t.version == lessonId.version
    );

    this.setLoading(false);
    return lessonExists;
  }

  @Mutation
  UPDATE_LESSON(lesson: Lesson) {
    const index = this.lessons.findIndex((l) => l.id == lesson.id);
    const lessonClone = [...this.lessons];
    if (index != -1) {
      lessonClone[index] = lesson;
    }
    this.lessons = lessonClone;
  }

  @Action
  async publishLesson({
    reference,
    changelogMessage,
    editorEmail,
  }: {
    reference: TrainingElementReference;
    changelogMessage: string;
    editorEmail: string;
  }) {
    const { id, language, version } = reference;
    this.setLoading(true);
    const success = await this.client.publishLesson(reference, changelogMessage, editorEmail);

    if (success) {
      // Update full lessons
      const newLessons = [...this.lessons];
      const lesson = newLessons.find(
        (t) => t.id == id && t.language == language && t.version == version
      );
      if (!lesson) {
        this.setLoading(false);
        return;
      }
      lesson.status = "published";
      this.setLessons(newLessons);

      // update grouped lesson list
      const currentLessonList = { ...this.lessonList } || {};
      const lessonsForLanguage = currentLessonList[language] || [];
      const lessonVersionsIndex = lessonsForLanguage.findIndex((el) => {
        return el.id == id;
      });

      if (lessonVersionsIndex == -1) {
        this.setLoading(false);
        return;
      } else {
        delete lessonsForLanguage[lessonVersionsIndex].draft;
      }
      lessonsForLanguage[lessonVersionsIndex].publishedVersions[version] = lesson;
      delete lessonsForLanguage[lessonVersionsIndex].draft;
      this.setLessonList(currentLessonList);
    }
    this.setLoading(false);
  }

  // Helpers
  @Action({})
  groupLessons({ lessons }: { lessons: LessonMetadata[] }) {
    const lessonList: TrainingElementVersions[] = [];
    lessons.forEach((lesson) => {
      let lessonIndex = lessonList.findIndex((el) => el.id == lesson.id);
      if (lessonIndex == -1) {
        lessonList.push({ id: lesson.id, publishedVersions: {}, allVersions: [] });
        lessonIndex = lessonList.length - 1;
      }

      if (
        lesson.status == "published" &&
        (!lessonList[lessonIndex].latestPublished ||
          semver.gt(lesson.version + ".0", lessonList[lessonIndex].latestPublished!.version + ".0"))
      ) {
        lessonList[lessonIndex].latestPublished = lesson;
      }

      if (
        !lessonList[lessonIndex].latest ||
        semver.gt(lesson.version + ".0", lessonList[lessonIndex].latest!.version + ".0")
      ) {
        lessonList[lessonIndex].latest = lesson;
      }

      if (lesson.status !== "published") {
        lessonList[lessonIndex].draft = lesson;
      } else if (lesson.status == "published") {
        lessonList[lessonIndex].publishedVersions[lesson.version] = lesson;
      }

      lessonList[lessonIndex].allVersions.push(lesson);
    });

    return lessonList;
  }

  @Action({})
  groupLessonVersions({
    id,
    language,
    lessons,
  }: {
    id: string;
    language: languages;
    lessons: LessonMetadata[];
  }): TrainingElementVersions {
    const lessonVersions: TrainingElementVersions = {
      id,
      allVersions: [],
      publishedVersions: {},
    };

    lessons.forEach((lesson) => {
      if (lesson.language != language) return;

      if (
        lesson.status == "published" &&
        (!lessonVersions.latestPublished ||
          semver.gt(lesson.version + ".0", lessonVersions.latestPublished!.version + ".0"))
      ) {
        lessonVersions.latestPublished = lesson;
      }

      if (
        !lessonVersions.latest ||
        semver.gt(lesson.version + ".0", lessonVersions.latest!.version + ".0")
      ) {
        lessonVersions.latest = lesson;
      }

      if (lesson.status !== "published") {
        lessonVersions.draft = lesson;
      } else if (lesson.status == "published") {
        lessonVersions.publishedVersions[lesson.version] = lesson;
      }

      lessonVersions.allVersions.push(lesson);
    });

    return lessonVersions;
  }

  @Action
  async resetLesson({
    id,
    language,
    owner,
    version,
  }: {
    id: string;
    language: languages;
    owner: Owner;
    version: string;
  }) {
    this.REMOVE_LESSON(id);
    const elementReference: TrainingElementReference = {
      id,
      language,
      version,
      owner,
    };
    await this.fetchLesson(elementReference);
  }

  @Mutation
  REMOVE_LESSON(id: string) {
    this.lessons = this.lessons.filter((lesson) => lesson.id != id);
  }
}

export default getModule(LessonsModule_v2);
