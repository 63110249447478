
import { Component, Watch, Vue } from "vue-property-decorator";
import { FilterBarItems, TableMenuAction, TrainingElementVersions } from "@/models";
import { Sublesson, Quiz } from "shared-alva/models";

import { availableLanguages, languages } from "shared-alva/languages";
import { getModule } from "vuex-module-decorators";
import OsdiTable, { OsdiTableRowAction } from "shared-alva/components/tables/OsdiTable.vue";
import DialogStore from "@/store/modules/dialog-store";
import TopFiltersBar from "@/components/TopFiltersBar.vue";
import OsdiConfirmDialog from "shared-alva/components/dialogs/OsdiConfirmDialog.vue";
import UIStateModule from "@/store/modules/uistate-store";
import QuizModule from "@/store/modules/quiz-store";
import awsconfig from "@/aws-config";
import Changelog from "@/components/Changelog.vue";
import OsdiButton from "osdi-design-system/molecules/button/button.vue";
import { refilterContent, sortItems } from "@/service/contentHandlers";
import QuizDetails from "./QuizDetails.vue";
import QuizVersion from "./QuizVersion.vue";
import i18n from "@/i18n";
import quizStore from "@/store/modules/quiz-store";
import { quizHeaders } from "@/utils/tableHeaders";
import { DataTableHeader } from "vuetify";
import LanguageModule from "@/store/modules/language-store";
import { ROUTES } from "@/router";
import FiltersModule from "@/store/modules/filters-store";
import OsdiTableFooter from "../tableTools/TableFooter.vue";

const dialogStore = getModule(DialogStore);

@Component({
  components: {
    OsdiConfirmDialog,
    OsdiTable,
    TopFiltersBar,
    Changelog,
    OsdiButton,
    QuizDetails,
    QuizVersion,
    OsdiTableFooter,
  },
})
export default class QuizOverview extends Vue {
  $refs!: {
    confirmDeleteDialog: OsdiConfirmDialog;
  };

  private actions: OsdiTableRowAction[] = [];
  private uiStore = UIStateModule;
  private elementPath = "quiz";
  public quizStore = getModule(QuizModule);
  public filteredItems: TrainingElementVersions[] = [];
  public expandedItems: TrainingElementVersions[] = [];
  public selectedQuiz: Quiz | null = null;
  public selectedVersion: { [id: string]: string } = {};
  public translate = false;
  loading = false;
  languageStore = getModule(LanguageModule);
  filterStore = getModule(FiltersModule);
  filterBarItems = this.getFilterBarItems;

  searchInput: string = "";
  private changelogData?: Sublesson;

  @Watch("languageStore.language")
  refreshFilters() {
    this.filterBarItems = this.getFilterBarItems;
  }

  get getFilterBarItems(): FilterBarItems[] {
    return [
      {
        label: i18n.t("smartcollege.overviewTable.headers.status").toString(),
        items: [
          i18n.t("smartcollege.filters.all").toString(),
          i18n.t("smartcollege.filters.draft").toString(),
          i18n.t("smartcollege.filters.review").toString(),
          i18n.t("smartcollege.filters.approved").toString(),
          i18n.t("smartcollege.filters.published").toString(),
        ],
        value: i18n.t("smartcollege.filters.all").toString(),
        onChange: () => {},
      },
      {
        label: i18n.t("smartcollege.overviewTable.headers.language").toString(),
        items: ["Default", ...availableLanguages],
        itemLabels: ["Default", ...availableLanguages].flatMap((el: string) => {
          return i18n.t(`smartcollege.language.${el}`).toString();
        }),
        value: i18n.locale,
        onChange: () => {},
      },
    ];
  }
  get getStatusMap(): { [id: string]: string } {
    return {
      [i18n.t("smartcollege.filters.all").toString()]: "All",
      [i18n.t("smartcollege.filters.review").toString()]: "Review",
      [i18n.t("smartcollege.filters.approved").toString()]: "Approved",
      [i18n.t("smartcollege.filters.published").toString()]: "Published",
      [i18n.t("smartcollege.filters.draft").toString()]: "Draft",
    };
  }

  get quizHeaders(): DataTableHeader[] {
    return [
      {
        text: i18n.t("smartcollege.overviewTable.headers.title").toString(),
        sortable: true,
        value: "title",
      },
      {
        text: i18n.t("smartcollege.overviewTable.headers.version").toString(),
        align: "start",
        sortable: false,
        value: "version",
        width: "1%",
      },
      {
        text: i18n.t("smartcollege.overviewTable.headers.status").toString(),
        value: "status",
        sortable: false,
      },
      {
        text: i18n.t("smartcollege.overviewTable.headers.language").toString(),
        value: "language",
        sortable: false,
      },
      {
        text: "",
        value: "delete-icon",
        sortable: false,
        width: "1%",
      },
    ];
  }

  get tableMenu(): TableMenuAction[] {
    return [
      {
        label: "createNewTranslation",
        icon: "mdi-translate",
        action: (item: Quiz) => this.newVersionDialog(item, true),
      },
      {
        label: "createNewVersion",
        icon: "mdi-source-branch-plus",
        action: (item: Quiz) => this.newVersionDialog(item, false),
      },
    ];
  }

  get getSelectedQuiz() {
    return this.selectedQuiz;
  }

  loadExpandedItems() {
    const expandedItems: TrainingElementVersions[] = [];
    if (this.filteredItems && this.filteredItems.length > 0) {
      this.filteredItems.forEach((element: TrainingElementVersions) => {
        this.selectedVersion[element.id] = element.latestPublished
          ? element.latestPublished.version
          : element.latest?.version || "";

        if (element.latestPublished && element.draft) {
          expandedItems.push(element);
        }
      });
    }

    this.expandedItems = expandedItems;
  }

  @Watch("filterBarItems", { deep: true })
  @Watch("searchInput")
  changeSearchInput() {
    this.filterStore.setAllFilters({
      element: this.elementPath,
      filters: this.filterBarItems,
      searchInput: this.searchInput,
    });
  }

  async mounted() {
    await this.quizStore.fetchQuizzes(this.selectedLanguage as languages);
    this.filteredItems = this.quizStore.quizList[this.selectedLanguage];

    if (this.filterStore.filters[this.elementPath]) {
      this.filterBarItems = this.filterStore.filters[this.elementPath].filterItems;
      this.searchInput = this.filterStore.filters[this.elementPath].searchInput;
      this.uiStore.setSearchInput(this.searchInput);
    } else {
      this.filterStore.setAllFilters({
        element: this.elementPath,
        filters: this.filterBarItems,
        searchInput: this.searchInput,
      });
    }

    this.loadExpandedItems();
  }

  newVersionDialog(item: Quiz, translate: boolean) {
    dialogStore.openDialog({
      componentName: "new-version-dialog",
      data: {
        baseElement: { ...item, type: "quizzes" },
        translate: translate,
      },
      customDialog: true,
    });
  }

  sort(items: any, index: string, isDescending: string) {
    const sortedItems = sortItems(items, index, isDescending);
    return sortedItems;
  }

  get selectedLanguage(): languages {
    if (this.filterBarItems[1].value == "Default") return this.$i18n.locale as languages;
    const selectedLang = this.filterBarItems[1].value as languages;
    return selectedLang;
  }

  get selectedStatus(): string {
    return this.getStatusMap[this.filterBarItems[0].value];
  }

  getSelectedVersion(id: string): string {
    return this.selectedVersion[id] ?? "";
  }

  changeVersion(id: string, e: string): any {
    this.selectedVersion[id] = e;
  }

  startQuiz(quiz: Quiz) {
    this.selectedQuiz = quiz;
  }

  async deleteQuiz(id: string, language: string, version: string) {
    await this.quizStore.removeQuiz({
      id,
      language,
      version,
    });

    this.reload();
  }

  @Watch("selectedStatus")
  reloadFilteredItems() {
    const quizzes = this.quizStore.quizList[this.selectedLanguage];
    const filteredContent = refilterContent(
      quizzes,
      this.searchInput || "",
      this.selectedStatus,
      this.selectedVersion
    );
    this.filteredItems = filteredContent.filteredItems;
    this.expandedItems = filteredContent.expandedItems;
  }

  @Watch("searchInput.length")
  @Watch("quizStore.getQuizzes", { immediate: true })
  @Watch("quizStore.quizList")
  reload() {
    this.reloadFilteredItems();
    this.loadExpandedItems();
  }

  @Watch("selectedLanguage")
  async languageChanged() {
    //request content with the new language and then refilter them with the searchInput value
    await this.quizStore.fetchQuizzes(this.selectedLanguage);
    const quizzes = this.quizStore.quizList[this.selectedLanguage];

    const filteredContent = refilterContent(
      quizzes,
      this.searchInput,
      this.selectedStatus,
      this.selectedVersion
    );

    this.filteredItems = filteredContent.filteredItems;
    this.loadExpandedItems();
  }

  createQuiz() {
    this.$router.push({
      name: ROUTES.SmartCollegesQuizNew,
    });
  }

  goToQuiz(quizId: string, version: string, language: string) {
    this.$router.push({
      name: ROUTES.SmartCollegesQuizDetails,
      params: {
        quizId: quizId,
      },
      query: {
        version: version,
        language: language,
      },
    });
  }

  @Watch("uiStore.getSearchInput")
  searchInputs() {
    return (this.searchInput = this.uiStore.getSearchInput);
  }
}
