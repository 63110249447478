
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { Slide } from "shared-alva/models";
import MediaBlock from "@/components/sublessons/blocks/MediaBlock.vue";
import OsdiTextField from "shared-alva/components/inputs/OsdiTextField.vue";
import draggable from "vuedraggable";

@Component({
  components: {
    OsdiTextField,
    MediaBlock,
    draggable,
  },
})
export default class SlideImageCompare extends Vue {
  @Prop()
  slide!: Slide;

  @Prop()
  sublessonId!: string;
}
