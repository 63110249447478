
import Vue from "vue";
import { Component, Emit, Prop } from "vue-property-decorator";
import OsdiButton from "shared-alva/components/buttons/OsdiButton.vue";
import OsdiIconButton from "shared-alva/components/buttons/OsdiIconButton.vue";
import { v4 as generateUUID } from "uuid";
import { MediaMetadata } from "shared-alva/models";
import { MediaAPI } from "shared-alva";
import awsconfig from "@/aws-config";

import AuthModule from "../store/modules/auth-store";
import { getModule } from "vuex-module-decorators";
const authStore = getModule(AuthModule);

@Component({ components: { OsdiButton, OsdiIconButton } })
export default class Upload extends Vue {
  private mediaApi = new MediaAPI({
    graphQl: awsconfig.API_V2,
    bucket: awsconfig.Storage.AWSS3.bucket,
  });

  @Prop({ default: false, type: Boolean })
  link!: boolean;

  @Prop({ default: true, type: Boolean })
  isUndoDisabled!: boolean;

  dropFile: File = new File([], "");
  metaData: MediaMetadata = {
    uuid: generateUUID(),
    owner: { tenant: "", siteId: "" },
    mimeType: "",
  };

  onInputChanged(file: File[]) {
    if (typeof file !== "undefined") {
      this.$emit("on-input-changed", file);
    }
  }

  async upload() {
    if (typeof this.dropFile !== "undefined" && this.dropFile.name != "") {
      const uuid = generateUUID();
      const file = this.dropFile;
      const mediaMetadata: MediaMetadata = {
        ...this.metaData,
        owner: { tenant: authStore.tenant, siteId: "global" },
        uuid: uuid,
        creationTimestamp: Date.now(),
      };

      // Get image height and width
      var img = new Image();
      img.onload = async function (this: any) {
        var sizes = {
          width: this.width!,
          height: this.height!,
        };
        URL.revokeObjectURL(this.src);
        mediaMetadata.width = sizes.width;
        mediaMetadata.height = sizes.height;
        await this.mediaApi.uploadMediaFile(file, mediaMetadata);
      };
      var objectURL = URL.createObjectURL(this.dropFile);
      img.src = objectURL;
    }
  }

  @Emit()
  applyUndo() {}

  focusInput() {
    (this.$refs.input as any).$el.querySelector("input").click();
  }

  @Emit()
  clearInput(event: MouseEvent) {}
}
