import { QuestionTypes } from "@/models";

import multipleChoice from "shared-alva/assets/quizz-templates/multiple-choice.svg";
import multipleChoiceHover from "shared-alva/assets/quizz-templates/multiple-choice-hover.svg";
import gap from "shared-alva/assets/quizz-templates/gap.svg";
import gapHover from "shared-alva/assets/quizz-templates/gap-hover.svg";
import AorB from "shared-alva/assets/quizz-templates/A-or-B.svg";
import AorBHover from "shared-alva/assets/quizz-templates/A-or-B-hover.svg";
import Sorting from "shared-alva/assets/quizz-templates/sorting.svg";
import SortingHover from "shared-alva/assets/quizz-templates/sorting-hover.svg";

export const questionTypes: QuestionTypes[] = [
  {
    name: "Multiple Choice",
    icon: multipleChoice,
    questionType: "MULTIPLE_CHOICE",
    iconHover: multipleChoiceHover,
  },
  {
    name: "Gap",
    icon: gap,
    questionType: "GAP_TEST",
    iconHover: gapHover,
  },
  {
    name: "A or B",
    icon: AorB,
    questionType: "CHOOSE_ONE",
    iconHover: AorBHover,
  },
  {
    name: "Sorting",
    icon: Sorting,
    questionType: "SORTING",
    iconHover: SortingHover,
  },
];
