
import { Component, Vue, Prop } from "vue-property-decorator";
import Login from "@/authentication/components/Login.vue";

import { getModule } from "vuex-module-decorators";
import AuthModule, { AuthenticationState } from "@/store/modules/auth-store";
import AuthenticatorDisplayMap from "../AuthenticatorDisplayMap";
import RequireNewPassword from "@/authentication/components/RequireNewPassword.vue";
import ForgotPassword from "@/authentication/components/ForgotPassword.vue";

import store from "@/store/index";
import router, { ROUTES } from "@/router";
const authStore = getModule(AuthModule);

@Component({
  components: { Login, RequireNewPassword, ForgotPassword },
})
export default class Authenticator extends Vue {
  @Prop()
  to!: string;

  private displayMap = AuthenticatorDisplayMap;
  private storeSubscription: any = undefined;

  mounted() {
    this.storeSubscription = store.subscribe((mutation) => {
      if (mutation.type === "updateAuthn" && mutation.payload === "signedIn") {
        if (this.storeSubscription) {
          // unregister from subscription
          this.storeSubscription();
        }
        router.push({ name: this.to || ROUTES.SmartCollegeDashboard });
      }
    });
  }

  destroyed() {
    if (this.storeSubscription) {
      // unregister from subscription
      this.storeSubscription();
    }
  }

  get authnState(): AuthenticationState {
    return authStore.authnState;
  }
}
