
import { Question, QuestionType } from "shared-alva/models";
import OsdiSnackbar from "shared-alva/components/snackbars/OsdiSnackbar.vue";
import { v4 as uuid } from "uuid";
import { Component, Vue, Prop } from "vue-property-decorator";
import ChooseOne from "./choices/ChooseOne.vue";
import GapTest from "./choices/GapTest.vue";
import MultipleChoice from "./choices/MultipleChoice.vue";
import PublishDialog from "../PublishDialog.vue";
import Sorting from "./choices/Sorting.vue";
import OsdiButton from "osdi-design-system/molecules/button/button.vue";

@Component({
  components: {
    GapTest,
    MultipleChoice,
    ChooseOne,
    PublishDialog,
    OsdiButton,
    Sorting,
  },
})
export default class QuestionCreate extends Vue {
  @Prop() isOpen!: boolean;
  questionType: QuestionType = QuestionType.MULTIPLE_CHOICE;
  question: Question = {} as Question;
  public save: boolean = false;
  showPublish = false;
  canBePublished = false;
  private open = this.isOpen;
  snackMessage = "";

  $refs!: {
    snackbar: OsdiSnackbar;
  };

  created() {
    this.questionType = this.$route.query.type as QuestionType;
  }

  private options: object = [
    { value: "MULTIPLE_CHOICE", label: "Multiple Choice" },
    { value: "CHOOSE_ONE", label: "Choose One" },
    { value: "GAP_TEST", label: "Gap Test" },
    { value: "SORTING", label: "Sorting" },
  ];

  createQuestion(question: Question) {
    this.question = { ...question };
    if (this.question.id === "" || this.question.id === undefined) {
      this.question.id = uuid();
    }
  }

  publish() {
    this.showPublish = true;
  }

  onPublishCanceled() {
    this.showPublish = false;
  }
  onPublished() {
    this.showPublish = false;
    this.onExit();
  }

  saveItem() {
    this.save = !this.save;
    this.canBePublished = true;
    this.$refs.snackbar.open();
    this.snackMessage = "Question saved";
  }

  onExit() {
    this.$router.push({
      path: `/questions`,
    });
  }
}
