import { Module, getModule, VuexModule, Mutation, Action } from "vuex-module-decorators";
import awsconfig from "@/aws-config";
import store from "@/store/index";
import { QuizAPI } from "shared-alva/api/quiz";
import { Owner, Quiz, QuizFilter, SaveQuiz, TrainingElementReference } from "shared-alva/models";
import { languages } from "shared-alva/languages";
import { GroupedTrainingElements, TrainingElementVersions } from "@/models";
import * as semver from "semver";
import { groupElements, groupElementVersions } from "@/utils";
import _ from "lodash";
interface T {
  language: languages;
  id: string;
}
@Module({ dynamic: true, namespaced: true, store, name: "quizzes" })
class QuizModule extends VuexModule {
  private client = new QuizAPI(awsconfig.API_V2);
  private quizzes: { [quizId: string]: Quiz } = {};
  isLoading = false;
  private allQuizzes = new Map<string, { [quizId: string]: Quiz }>();

  //state
  public quizList: GroupedTrainingElements = {};

  get getQuizzes() {
    return this.quizzes;
  }

  get getAllQuizzes() {
    return this.allQuizzes;
  }

  @Mutation
  SET_QUIZ_LIST(quizList: GroupedTrainingElements) {
    this.quizList = quizList;
  }

  @Action({ rawError: true })
  async fetchQuizzes(language: languages) {
    this.SET_LOADING(true);
    const owner = { tenant: "fettecompacting", siteId: "global" };
    const quizzes = await this.client.getQuizzes(language, owner);

    const quizList = { ...this.quizList };
    quizList[language] = await this.groupElements({ quizzes });
    this.SET_QUIZZES({ quizzes });
    this.SET_QUIZ_LIST(quizList);
    this.SET_LOADING(false);
  }

  @Action({})
  async fetchQuiz(quiz: TrainingElementReference) {
    this.SET_LOADING(true);
    const quizStore = await this.client.getQuiz({ trainingElementReferenceInput: quiz });
    this.SET_LOADING(false);
    return quizStore;
  }

  @Action({})
  async getQuiz(quiz: TrainingElementReference) {
    const quizzes = this.quizList[quiz.language];
    if (!quizzes) {
      return await this.fetchQuiz(quiz);
    }

    const filteredQuiz = quizzes.find(({ id }) => id === quiz.id);
    if (!filteredQuiz) {
      return await this.fetchQuiz(quiz);
    }

    const targetVersion = filteredQuiz.allVersions.find(({ version }) => version === quiz.version);
    if (targetVersion) {
      return targetVersion;
    }
  }

  @Action({})
  groupElements({ quizzes }: { quizzes: Quiz[] }) {
    return groupElements<Quiz>({ elements: quizzes });
  }

  get getLoadingStatus() {
    return this.isLoading;
  }

  @Mutation
  SET_QUIZ_MAP() {}

  @Action({ rawError: true })
  async removeQuiz({
    id,
    language,
    version,
  }: {
    id: string;
    language: languages | string;
    version: string;
  }) {
    const quiz = this.quizzes[id];
    const quizFilter: QuizFilter = {
      trainingElementReferenceInput: {
        id: quiz.id,
        owner: quiz.owner,
        language: language,
        version: version,
      },
    };

    const success = await this.client.deleteQuiz(quizFilter);

    if (success) {
      // const currentQuizList = { ...this.quizList };
      // const quizzesForLanguage = currentQuizList[language] || [];
      // const quizzesVersionsIndex = quizzesForLanguage.findIndex((el) => {
      //   return el.id == id;
      // });

      // if (quizzesVersionsIndex == -1) return;
      // else {
      //   quizzesForLanguage[quizzesVersionsIndex].allVersions = quizzesForLanguage[
      //     quizzesVersionsIndex
      //   ].allVersions.filter((e) => {
      //     return e.id != id && e.language != language && e.version != version;
      //   });

      //   //delete quizzesForLanguage[quizzesVersionsIndex].draft;
      //   const { draft, ...rest } = quizzesForLanguage[quizzesVersionsIndex];
      //   quizzesForLanguage[quizzesVersionsIndex] = rest;
      //   if (quizzesForLanguage[quizzesVersionsIndex].allVersions.length == 0) {
      //     quizzesForLanguage.splice(quizzesVersionsIndex, 1);
      //   }
      // }

      // currentQuizList[language] = [...quizzesForLanguage];
      // this.SET_QUIZ_LIST(currentQuizList);
      this.fetchQuizzes(language as languages);
    }
  }

  @Mutation
  SET_LOADING(loading: boolean) {
    this.isLoading = loading;
  }

  @Mutation
  SET_QUIZZES({ quizzes, language }: { quizzes: Quiz[]; language?: string }) {
    const quizzCopy = { ...this.quizzes };

    if (quizzes.length > 0) {
      quizzes.forEach((quiz) => {
        quizzCopy[quiz.id] = quiz;
        this.quizzes = quizzCopy;
      });
    } else {
      if (language) {
        const allCopy = new Map(this.allQuizzes);
        allCopy.set(language, { ...this.quizzes });
        this.allQuizzes = allCopy;
      }
      this.quizzes = {};
    }
  }

  @Mutation
  RESET_QUIZZES_LANGUAGE(quizzes: Quiz[]) {
    const quizzCopy = {} as { [quizId: string]: Quiz };
    this.quizzes = quizzCopy;
    if (quizzes.length > 0) {
      quizzes.forEach((quiz) => {
        quizzCopy[quiz.id] = quiz;
        this.quizzes = quizzCopy;
      });
    } else {
      this.quizzes = {};
    }
  }

  @Mutation
  REMOVE_QUIZ(quizId: string) {
    const quizzCopy = { ...this.quizzes };
    delete quizzCopy[quizId];
    this.quizzes = quizzCopy;
  }

  @Mutation
  ADD_QUIZ(quiz: Quiz) {
    const quizzCopy = { ...this.quizzes };
    quizzCopy[quiz.id] = quiz;
    this.quizzes = quizzCopy;
  }

  @Action({ rawError: true })
  async saveQuiz(quiz: Quiz) {
    const saveQuiz: SaveQuiz = {
      owner: quiz.owner,
      id: quiz.id,
      language: quiz.language,
      version: quiz.version,
      status: quiz.status!,
      title: quiz.title,
      element: quiz.element || "",
      description: quiz.description,
      quizLength: quiz.questions.length,
      questions: quiz.questions.map((question) => {
        return {
          id: question.id,
          owner: question.owner,
          language: question.language,
          version: question.version,
        } as TrainingElementReference;
      }),
    };

    let success = false;
    if (quiz.status !== "published") {
      success = await this.client.saveQuiz(saveQuiz);
    } else {
      // TODO - implement modal for publishingπ
      success = await this.client.publishQuiz(
        {
          id: quiz.id,
          owner: quiz.owner,
          version: quiz.version,
          language: quiz.language,
        } as TrainingElementReference,
        "",
        ""
      );
    }

    if (success && this.quizList[quiz.language]) {
      this.ADD_QUIZ(quiz);
      // update group quiz list
      const currentQuizList = { ...this.quizList };
      const quizzesForLanguage = currentQuizList[quiz.language];
      const quizVersionsIndex = quizzesForLanguage.findIndex((el) => el.id === quiz.id);

      if (quizVersionsIndex == -1) {
        quizzesForLanguage.push({
          allVersions: [quiz],
          id: quiz.id,
          latest: quiz,
          draft: quiz,
          publishedVersions: {},
        });
        this.SET_QUIZ_LIST(currentQuizList);
      } else {
        const newQuizzes = quizzesForLanguage[quizVersionsIndex].allVersions.filter((el) => {
          return el.version != quiz.version;
        });
        newQuizzes.push(quiz);
        quizzesForLanguage[quizVersionsIndex] = await groupElementVersions<Quiz>({
          id: quiz.id,
          language: quiz.language as languages,
          elements: newQuizzes as Quiz[],
        });
        this.SET_QUIZ_LIST(currentQuizList);
      }
    }
  }

  @Action
  async getAllQuizzesVersions(language: languages) {
    if (!language) return [];
    if (!(language in this.quizList)) {
      await this.fetchQuizzes(language as languages);
    }
    return this.quizList[language];
  }

  @Action
  getAllPublishedVersions(quiz: TrainingElementReference) {
    const allVersions = this.quizList[quiz.language].find((el) => {
      return el.id == quiz.id;
    });
    return Object.keys(allVersions?.publishedVersions || {});
  }

  @Action
  async publishQuiz({
    reference,
    changelogMessage,
    editorEmail,
  }: {
    reference: TrainingElementReference;
    changelogMessage: string;
    editorEmail: string;
  }) {
    const { id, language, version } = reference;

    const success = await this.client.publishQuiz(reference, changelogMessage, editorEmail);

    if (success && this.quizList[language]) {
      // Update full quizzes

      const quiz = this.quizzes[reference.id];

      quiz.status = "published";
      this.ADD_QUIZ(quiz);

      // update grouped sublesson list
      const currentQuizList = { ...this.quizList } || {};
      const quizForLanguage = currentQuizList[language] || [];
      const quizVersionsIndex = quizForLanguage.findIndex((el) => {
        return el.id == id;
      });

      if (quizVersionsIndex == -1) {
        return;
      } else {
        delete quizForLanguage[quizVersionsIndex].draft;
      }
      quizForLanguage[quizVersionsIndex].publishedVersions[version] = quiz;
      delete quizForLanguage[quizVersionsIndex].draft;
      this.SET_QUIZ_LIST(currentQuizList);
    }
  }

  @Action
  async deleteQuiz({
    owner,
    id,
    language,
    version,
  }: {
    owner: Owner;
    id: string;
    language: languages | string;
    version: string;
  }) {
    this.SET_LOADING(true);

    const success = await this.client.deleteQuiz({
      trainingElementReferenceInput: { owner, type: "quiz", id, language: language, version },
    });

    if (success) {
      const currentQuizList = { ...this.quizList } || {};
      const quizzesForLanguage = currentQuizList[language] || [];
      const sublessonVersionsIndex = quizzesForLanguage.findIndex((el) => {
        return el.id == id;
      });

      if (sublessonVersionsIndex == -1) return;

      quizzesForLanguage[sublessonVersionsIndex].allVersions = quizzesForLanguage[
        sublessonVersionsIndex
      ].allVersions.filter((e) => {
        return e.id != id && e.language != language && e.version != version;
      });
      delete quizzesForLanguage[sublessonVersionsIndex].draft;
      if (quizzesForLanguage[sublessonVersionsIndex].allVersions.length == 0) {
        quizzesForLanguage.splice(sublessonVersionsIndex, 1);
      }

      this.SET_QUIZ_LIST(currentQuizList);
    }

    this.SET_LOADING(false);
  }
}

export default QuizModule;
