var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[(_vm.showChangelog)?_c('changelog',{attrs:{"showChangelog":_vm.showChangelog,"changelogData":_vm.changelogData,"selectedVersion":_vm.selectedVersion,"dataType":"training"},on:{"close-changelog":_vm.closeChangelog}}):_vm._e(),_c('top-filters-bar',{attrs:{"filters":_vm.filterBarItems}}),(_vm.trainingStore.isLoading)?_c('v-progress-linear',{attrs:{"indeterminate":"","width":"100%"}}):_vm._e(),_c('osdi-table',{staticClass:"table",attrs:{"elevation":"0","headers":_vm.tableHeaders,"items":_vm.filteredItems,"actions":_vm.actions,"loading":_vm.trainingStore.isLoading,"custom-sort":_vm.sort,"expanded":_vm.expandedItems,"calculate-widths":"","show-expand":""},scopedSlots:_vm._u([{key:"item",fn:function({ item, isExpanded, expand }){return [_c('osdi-table-item',{key:_vm.genId(),attrs:{"item":item,"isExpanded":isExpanded,"tableMenu":_vm.tableMenu},on:{"on-click-row":function($event){item.latestPublished
            ? _vm.goToTraining(
                item.latestPublished.id,
                item.latestPublished.version,
                item.latestPublished.language
              )
            : _vm.goToTraining(item.latest.id, item.latest.version, item.latest.language)},"on-click-expand-item":function($event){return expand(!isExpanded)}},scopedSlots:_vm._u([{key:"td-version",fn:function(){return [_c('div',{staticClass:"table-data__version-select"},[(
                item.latestPublished &&
                item.allVersions.length > 1 &&
                Object.keys(item.publishedVersions).length > 1
              )?_c('v-select',{staticClass:"status-select",attrs:{"v-model":_vm.selectedVersion,"value":_vm.getSelectedVersion(item.latest.id),"items":Object.keys(item.publishedVersions),"item-text":"text","hide-details":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();},"change":function($event){return _vm.changeVersion(item.latest.id, $event)}}}):_c('span',[_vm._v(" "+_vm._s(item.latestPublished ? item.latestPublished.version : item.latest.version)+" ")])],1)]},proxy:true}],null,true)})]}},{key:"expanded-item",fn:function({ item }){return [_c('osdi-draft-table-item',{key:_vm.genId(),attrs:{"leftIcon":"mdi-folder","isTrainingTable":true,"item":item,"tableMenu":_vm.tableMenu},on:{"on-click-row":function($event){return _vm.goToTraining(item.latest.id, item.latest.version, item.latest.language)}}})]}}])}),_c('osdi-table-footer',{attrs:{"createBtnLabel":_vm.$t('smartcollege.addTrainingButton.title')},on:{"on-create":_vm.createNewTraining}}),_c('osdi-confirm-dialog',{ref:"confirmDeleteDialog",attrs:{"title":_vm.$t('smartcollege.trainingOverview.deleteNotification.title'),"confirm-text":_vm.$t('smartcollege.delete'),"cancel-text":_vm.$t('smartcollege.abort')}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }