import Authentication from "@/authentication/Authentication.vue";
import Container from "@/components/Container.vue";
import LessonDetail from "@/components/lessons/LessonDetail.vue";
import LessonOverview from "@/components/lessons/LessonOverview/LessonOverview.vue";
import LessonCreate from "@/components/lessons/LessonCreate.vue";
import Dashboard from "@/components/dashboard/Dashboard.vue";
import SublessonDetail from "@/components/sublessons/SublessonDetail.vue";
import SublessonOverview from "@/components/sublessons/SublessonOverview/SublessonOverview.vue";
import SublessonCreate from "@/components/sublessons/SublessonCreate.vue";
import TrainingDetail from "@/components/trainings/TrainingDetail.vue";
import TrainingOverview from "@/components/trainings/TrainingOverview/TrainingOverview.vue";
import TrainingCreate from "@/components/trainings/TrainingCreate.vue";
import QuestionOverview from "@/components/questions/QuestionOverview.vue";
import ApiTestpage from "@/components/ApiTestpage.vue";
import MediaLibrary from "@/components/media/MediaLibrary.vue";
import QuizOverview from "@/components/quizzes/QuizOverview.vue";
import QuizDetails from "@/components/quizzes/QuizDetails.vue";
import QuestionDetails from "@/components/questions/QuestionDetails.vue";
import QuestionCreate from "@/components/questions/QuestionCreate.vue";
import MachineTypes from "@/components/machineTypes/MachineTypes.vue";
import LearningPathOverview from "@/components/learningPaths/LearningPathOverview/LearningPathOverview.vue";
import LearningPathDetail from "@/components/learningPaths/LearningPathDetail/LearningPathDetail.vue";
import LearningPathCreate from "@/components/learningPaths/LearningPathCreate/LearningPathCreate.vue";

import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import { getModule } from "vuex-module-decorators";
import AuthModule from "./store/modules/auth-store";

const authStore = getModule(AuthModule);

Vue.use(VueRouter);

export const enum ROUTES {
  SmartCollegeDashboard = "SmartCollegeDashboard",
  SmartCollegeSublessons = "SmartCollegeSublessons",
  SmartCollegeSublessonDetail = "SmartCollegeSublessonDetail",
  SmartCollegesSublessonNew = "SmartCollegesSublessonNew",
  SmartCollegeLearningPaths = "SmartCollegeLearningPaths",
  SmartCollegeLearningPathDetail = "SmartCollegeLearningPathDetail",
  SmartCollegesLearningPathNew = "SmartCollegesLearningPathNew",
  SmartCollegeTrainings = "SmartCollegeTrainings",
  SmartCollegeTrainingDetail = "SmartCollegeTrainingDetail",
  SmartCollegesTrainingNew = "SmartCollegesTrainingNew",
  SmartCollegeLessons = "SmartCollegeLessons",
  SmartCollegeLessonDetail = "SmartCollegeLessonDetail",
  SmartCollegesLessonNew = "SmartCollegesLessonNew",
  SmartCollegeQuestions = "SmartCollegeQuestions",
  SmartCollegesQuizzes = "SmartCollegesQuizzes",
  SmartCollegesQuizDetails = "SmartCollegesQuizDetails",
  SmartCollegesQuizNew = "SmartCollegesQuizNew",
  ApiTestpage = "ApiTestpage",
  MediaLibrary = "MediaLibrary",
  SmartCollegeQuestionNew = "SmartCollegeQuestionNew",
  SmartCollegeQuestionDetails = "SmartCollegeQuestionDetails",
  SmartCollegeMachineTypes = "SmartCollegeMachineTypes",
}

interface RouteMeta {
  requiresAuth?: boolean;
}

const routes: RouteConfig[] = [
  {
    path: "/",
    component: Container,
    meta: { requireAuth: true },
    children: [
      {
        name: ROUTES.SmartCollegeDashboard,
        path: "/",
        component: SublessonOverview,
        meta: { requireAuth: true },
      },
      {
        name: ROUTES.SmartCollegeSublessons,
        path: "/sublessons",
        component: SublessonOverview,
        meta: { requireAuth: true },
      },
      {
        name: ROUTES.SmartCollegeLessons,
        path: "/lessons",
        component: LessonOverview,
        meta: { requireAuth: true },
      },
      {
        name: ROUTES.SmartCollegeSublessonDetail,
        path: "sublessons/:sublessonId",
        component: SublessonDetail,
        meta: { requireAuth: true },
      },
      {
        name: ROUTES.SmartCollegesSublessonNew,
        path: "sublessons/new",
        component: SublessonCreate,
        meta: { requireAuth: true },
      },
      {
        name: ROUTES.SmartCollegeLessonDetail,
        path: "lessons/:lessonId",
        component: LessonDetail,
        meta: { requireAuth: true },
      },
      {
        path: "/lessons/new",
        name: ROUTES.SmartCollegesLessonNew,
        component: LessonCreate,
        meta: { requireAuh: true },
      },
      {
        name: ROUTES.SmartCollegeLearningPaths,
        path: "/learningPaths",
        component: LearningPathOverview,
        meta: { requireAuth: true },
      },
      {
        path: "/learningPaths/:learningPathId",
        name: ROUTES.SmartCollegeLearningPathDetail,
        component: LearningPathDetail,
        meta: { requireAuth: true },
      },
      {
        path: "/learningPaths/new",
        name: ROUTES.SmartCollegesLearningPathNew,
        component: LearningPathCreate,
        meta: { requireAuth: true },
      },
      {
        name: ROUTES.SmartCollegeTrainings,
        path: "/trainings",
        component: TrainingOverview,
        meta: { requireAuth: true },
      },
      {
        path: "/trainings/:trainingId",
        name: ROUTES.SmartCollegeTrainingDetail,
        component: TrainingDetail,
        meta: { requireAuth: true },
      },
      {
        path: "/trainings/new",
        name: ROUTES.SmartCollegesTrainingNew,
        component: TrainingCreate,
        meta: { requireAuth: true },
      },
      {
        path: "api_test",
        name: ROUTES.ApiTestpage,
        component: ApiTestpage,
        meta: { requireAuth: true },
      },
      {
        path: "medialibrary",
        name: ROUTES.MediaLibrary,
        component: MediaLibrary,
        meta: { requireAuth: true },
      },
      {
        path: "/quizzes",
        name: ROUTES.SmartCollegesQuizzes,
        component: QuizOverview,
        meta: { requireAuth: true },
      },
      {
        path: "/quizzes/:quizId",
        name: ROUTES.SmartCollegesQuizDetails,
        component: QuizDetails,
        meta: { requireAuth: true },
      },
      {
        path: "/quizzes/new",
        name: ROUTES.SmartCollegesQuizNew,
        component: QuizDetails,
        meta: { requireAuth: true },
      },
      {
        path: "/questions",
        name: ROUTES.SmartCollegeQuestions,
        component: QuestionOverview,
        meta: { requireAuth: true },
      },
      {
        path: "/questions/new",
        name: ROUTES.SmartCollegeQuestionNew,
        component: QuestionCreate,
        meta: { requireAuth: true },
      },
      {
        path: "/questions/:questionId",
        name: ROUTES.SmartCollegeQuestionDetails,
        component: QuestionDetails,
        meta: { requireAuth: true },
      },
      {
        path: "/machine-types",
        name: ROUTES.SmartCollegeMachineTypes,
        component: MachineTypes,
        meta: { requireAuth: true },
      },
    ],
  },
  {
    path: "/login/:to",
    name: "LoginTo",
    component: Authentication,
  },
  {
    path: "/login",
    name: "Login",
    component: Authentication,
  },
];
const router = new VueRouter({
  routes,
});

router.beforeEach(async (to, _, next) => {
  if (to.meta?.requireAuth) {
    await authStore.reloadUser();
    if (authStore.isLoggedIn) {
      return next();
    } else {
      const toAfterLogin = to.name;
      return next({ name: "Login", params: { to: toAfterLogin || ROUTES.SmartCollegeDashboard } });
    }
  } else {
    return next();
  }
});

export default router;
