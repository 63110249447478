import i18n from "../i18n";
import { getModule } from "vuex-module-decorators";
import { v4 as uuid } from "uuid";
import {
  LessonMetadata,
  SublessonMetadata,
  TrainingMetadata,
  TemplateType,
  Sublesson,
  Lesson,
  Training,
} from "shared-alva/models";
import { languages } from "shared-alva/languages";
import { TrainingElementVersions } from "@/models";

import LessonsModule from "@/store/modules/lessons-store_v2";
import SublessonsModule from "@/store/modules/sublessons-store_v2";
import TrainingsModule from "@/store/modules/trainings-store_v2";
import DialogStore from "@/store/modules/dialog-store";

const dialogStore = getModule(DialogStore);

import {
  filterNewVersion,
  filterTargetGroup,
  filterMachineType,
  filterStatus,
  searchInputValues,
} from "@/utils/filterHelpers";

//=============== OPEN MODALS/DIALOGS LOGIC ===============//
export const openContentModal = (
  item: TrainingElementVersions,
  selectedVersion: { [id: string]: string },
  type: string,
  isDraft: boolean
) => {
  if (!isDraft && item.latestPublished) {
    selectedVersion[item.id] = item.latestPublished.version;
    openDialog(
      item.publishedVersions[selectedVersion[item.latestPublished.id]] as
        | LessonMetadata
        | SublessonMetadata
        | TrainingMetadata,
      type as string
    );
  } else {
    return openDialog(item.draft as LessonMetadata | SublessonMetadata | TrainingMetadata, type);
  }
};

const openDialog = async (
  content: LessonMetadata | SublessonMetadata | TrainingMetadata,
  type: string
) => {
  if (type === "lesson") {
    const fullLesson = await LessonsModule.getLesson(content);
    openDialogStore("lesson-detail", fullLesson);
  }

  if (type === "sublesson") {
    const fullSublesson = await SublessonsModule.getSublesson(content);
    openDialogStore("sublesson-detail", fullSublesson);
  }
  if (type === "training") {
    const fullTraining = await TrainingsModule.getTraining(content);
    openDialogStore("training-detail", fullTraining);
  }
};

const openDialogStore = (
  name: string,
  data: LessonMetadata | SublessonMetadata | TrainingMetadata | undefined
) => {
  dialogStore.openDialog({
    componentName: name,
    data: data,
    customDialog: true,
  });
};

//====================== SORT ITEMS ON THE TABLE ===============//
export const sortItems = (
  items: any,
  index: string,
  isDescending: string,
  isTraining?: boolean
) => {
  const x = items.sort((a: TrainingElementVersions, b: TrainingElementVersions): number => {
    if (index == "title") {
      if (isTraining) {
        const titleA: string = (a?.latest as any).title!.toLowerCase() ?? "";
        const titleB: string = (b?.latest as any).title!.toLowerCase() ?? "";
        return titleA > titleB ? 1 : -1;
      } else {
        const titleA: string = (a?.latest as any).element!.toLowerCase() ?? "";
        const titleB: string = (b?.latest as any).element!.toLowerCase() ?? "";
        return titleA > titleB ? 1 : -1;
      }
    }
    return 0;
  });

  if (isDescending != "false") return items.reverse();
  else return items;
};

//====================== CREATE NEW Lesson/Sublesson/Training DIALOG ===============//
export const openCreateContentDialog = async (type: string) => {
  const sublesson: Sublesson = {
    id: uuid(),
    title: "",
    element: "",
    status: "draft",
    description: "",
    durationMinutes: 5,
    targetGroups: [],
    assetTypes: [],
    slides: [
      {
        id: uuid(),
        templateType: TemplateType.SlideTextRightImageLeft,
        title: "",
        bodyText: "",
        media: [],
        secondaryMedia: [],
        hints: [],
        warnings: [],
      },
    ],
    owner: { tenant: "fettecompacting", siteId: "global" },
    language: languages.de,
    version: "1.0",
    lastUpdated: 0,
  };

  const lesson: Lesson = {
    id: uuid(),
    title: "",
    element: "",
    status: "draft",
    description: "",
    sublessons: [],
    owner: { tenant: "fettecompacting", siteId: "global" },
    language: languages.de,
    version: "1.0",
    lastUpdated: 0,
    targetGroups: [],
    assetTypes: [],
  };

  const training: Training = {
    id: uuid(),
    title: "",
    status: "draft",
    description: "",
    elements: [],
    targetGroups: [],
    assetTypes: [],
    owner: { tenant: "fettecompacting", siteId: "global" },
    language: languages.de,
    version: "1.0",
    lastUpdated: 0,
  };

  await dialogStore.openDialog({
    componentName: `${type}-create`,
    data: type === "sublesson" ? sublesson : type === "lesson" ? lesson : training,
  });
};

//====================== FILTER ITEMS for Lesson/Sublesson/Training  ===============//
export const contentFilterItems = (
  content: any,
  filteredItems: TrainingElementVersions[],
  expandedItems: TrainingElementVersions[],
  selectedVersion: { [id: string]: string },
  selectedTargetGroup: string,
  selectedStatus: string,
  selectedMachineType: string,
  filteredNewVersion: any,
  searchInput: string,
  isTraining?: boolean
) => {
  if (!searchInput.length) {
    filteredItems = content;
  } else {
    filteredItems = searchInputValues(filteredItems, searchInput, isTraining);
  }

  if (filteredNewVersion) {
    filteredItems = filterNewVersion(filteredItems, filteredNewVersion);
  }

  if (selectedTargetGroup != "All") {
    filteredItems = filterTargetGroup(selectedTargetGroup, filteredItems);
  }

  if (selectedMachineType != "All") {
    filteredItems = filterMachineType(selectedMachineType, filteredItems);
  }
  if (selectedStatus !== "All") {
    filteredItems = filterStatus(selectedStatus, filteredItems);
  }

  expandedItems = [];
  filteredItems.forEach((element: TrainingElementVersions) => {
    selectedVersion[element.id] = element.latestPublished
      ? element.latestPublished.version
      : element.latest?.version || "";

    if (element.latestPublished && element.draft) {
      expandedItems.push(element);
    }
  });
  return { expandedItems, filteredItems };
};

export const contentFilterItemsOther = (
  content: any,
  filteredItems: TrainingElementVersions[],
  expandedItems: TrainingElementVersions[],
  selectedVersion: { [id: string]: string },
  selectedStatus: string,
  searchInput: string,
  isTraining?: boolean
) => {
  if (!searchInput.length) {
    filteredItems = content;
  } else {
    filteredItems = searchInputValues(filteredItems, searchInput, isTraining);
  }

  if (selectedStatus !== "All") {
    filteredItems = filterStatus(selectedStatus, filteredItems);
  }

  expandedItems = [];
  filteredItems.forEach((element: TrainingElementVersions) => {
    selectedVersion[element.id] = element.latestPublished
      ? element.latestPublished.version
      : element.latest?.version || "";

    if (element.latestPublished && element.draft) {
      expandedItems.push(element);
    }
  });
  return { expandedItems, filteredItems };
};

export const refilterContent = (
  content: any,
  searchInput: string,
  selectedStatus: string,
  selectedVersion: { [id: string]: string },
  isTraining?: boolean
) => {
  //request sublessons with the new language and then refilter them with the searchInput value
  let filteredItems = searchInputValues(content, searchInput, isTraining);
  if (selectedStatus !== "All") {
    filteredItems = filterStatus(selectedStatus, filteredItems);
  }
  const expandedItems: TrainingElementVersions[] = [];
  if (filteredItems && filteredItems.length > 0) {
    filteredItems.forEach((element: TrainingElementVersions) => {
      selectedVersion[element.id] = element.latestPublished
        ? element.latestPublished.version
        : element.latest?.version || "";

      if (element.latestPublished && element.draft) {
        expandedItems.push(element);
      }
    });
  }
  return { expandedItems, filteredItems };
};
