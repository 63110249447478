
import { Component, Watch, Vue, Prop, Emit } from "vue-property-decorator";
import TopFiltersBar from "@/components/TopFiltersBar.vue";
import OsdiConfirmDialog from "shared-alva/components/dialogs/OsdiConfirmDialog.vue";

import { Question, Quiz } from "shared-alva/models";
import { getModule } from "vuex-module-decorators";
import QuizModule from "@/store/modules/quiz-store";
import { availableLanguages } from "shared-alva/languages";
import { ROUTES } from "@/router";

@Component({
  components: {
    TopFiltersBar,
  },
})
export default class QuizVersion extends Vue {
  @Prop() element!: Quiz | Question;
  @Prop() translate!: boolean;
  public currentElement: Quiz | Question = {} as Quiz | Question;
  baseVersion = "";
  radioVersionType: string = "";
  newLanguage = "";

  get newVersion() {
    if (this.radioVersionType == "major") return this.majorPreview;
    else if (this.radioVersionType == "minor") return this.minorPreview;
    else return "";
  }

  mounted() {
    this.currentElement = this.element;
    this.baseVersion = this.currentElement.version;
  }

  get majorPreview() {
    const [major, minor] = this.baseVersion.split(".");
    return `${parseInt(major) + 1}.0`;
  }

  get minorPreview() {
    const [major, minor] = this.baseVersion.split(".");
    return `${major}.${parseInt(minor) + 1}`;
  }

  get allLanguages() {
    return availableLanguages;
  }

  @Emit()
  startItem() {
    this.currentElement.status = "draft";
    if (this.translate) {
      return {
        ...this.currentElement,
        language: this.newLanguage,
      };
    } else {
      return {
        ...this.currentElement,
        version: this.newVersion,
      };
    }
  }

  @Emit()
  onExit() {
    return false;
  }
}
