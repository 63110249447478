
import OsdiTextField from "shared-alva/components/inputs/OsdiTextField.vue";
import OsdiTextArea from "shared-alva/components/inputs/OsdiTextArea.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Sublesson } from "shared-alva/models";

@Component({
  components: { OsdiTextArea, OsdiTextField },
})
export default class SublessonMetadataEditor extends Vue {
  @Prop() sublesson!: Sublesson;

  titleRules = [
    (v: any) => !!v || this.$t("smartcollege.fieldRequired"),
    (v: string) => v.length <= 80 || "max 80",
  ];
  elementRules = [
    (v: any) => !!v || this.$t("smartcollege.fieldRequired"),
    (v: string) => v.length <= 80 || "max 80",
  ];
  descriptionRules = [(v: string) => v.length <= 200 || "max 200"];
  durationMinutesRules = [
    (v: string) => !!v || this.$t("smartcollege.fieldRequired"),
    (v: number) => v > 0 || "min value > 0",
  ];
}
