
import { v4 as uuid } from "uuid";
import { Component, VModel } from "vue-property-decorator";
import Vue from "vue";

import OsdiTextField from "shared-alva/components/inputs/OsdiTextField.vue";
import OsdiButton from "shared-alva/components/buttons/OsdiButton.vue";
import OsdiCloseButton from "shared-alva/components/buttons/OsdiCloseButton.vue";
import OsdiTextArea from "shared-alva/components/inputs/OsdiTextArea.vue";
import OsdiHints from "shared-alva/components/menus/OsdiHints.vue";
import OsdiDeleteButton from "shared-alva/components/buttons/OsdiDeleteButton.vue";
import OsdiIconButton from "shared-alva/components/buttons/OsdiIconButton.vue";

import { Warning, WarningIconType, WarningRiskLevel } from "shared-alva/models";
import uistateStore from "@/store/modules/uistate-store";

@Component({
  components: {
    OsdiDeleteButton,
    OsdiCloseButton,
    OsdiIconButton,
    OsdiTextArea,
    OsdiButton,
    OsdiTextField,
    OsdiHints,
  },
})
export default class WarningBlock extends Vue {
  @VModel() warnings!: Warning[];

  isIconsChooseOpen = false;
  isWarningDeleting = false;
  isEditing = false;
  uiStateStore = uistateStore;
  clonedWarning?: Warning;
  toDeleteIndex = 0;

  get mapWarningList() {
    return Object.values(WarningIconType).map((iconType) => {
      return {
        url: this.renderIcon(iconType),
        value: iconType,
      };
    });
  }

  currentWarning: Warning = {
    uuid: uuid(),
    iconType: "" as WarningIconType,
    riskLevel: "" as WarningRiskLevel,
    source: "",
    description: "",
  };
  riskTypes: WarningRiskLevel[] = [
    WarningRiskLevel.Danger,
    WarningRiskLevel.Warning,
    WarningRiskLevel.Caution,
    WarningRiskLevel.Important,
  ];

  renderIcon(iconType: WarningIconType) {
    return require(`shared-alva/assets/warning-icons/${iconType}.svg`) ?? "";
  }

  enableEditing(uuid: string) {
    this.isEditing = true;
    this.clonedWarning = this.warnings.find((w) => w.uuid === uuid)!;
    this.currentWarning = { ...this.clonedWarning };
    this.uiStateStore.setWarningsDialogState(true);
  }

  saveWarning() {
    if (this.isEditing) {
      this.warnings = this.warnings.map((warning) => {
        if (warning.uuid === this.currentWarning.uuid) {
          return this.currentWarning;
        }
        return warning;
      });
      this.isEditing = false;
    } else {
      this.enableWarning();
    }
    this.uiStateStore.setWarningsDialogState(false);
    this.clearWarning();
  }

  closeWarning() {
    if (this.isEditing) {
      this.warnings = this.warnings.map((warning) => {
        if (warning.uuid === this.clonedWarning?.uuid) {
          return this.clonedWarning;
        }
        return warning;
      });
      this.isEditing = false;
    }
    this.clearWarning();
    this.uiStateStore.setWarningsDialogState(false);
  }

  enableWarning() {
    const newWarning = {
      uuid: this.currentWarning.uuid,
      iconType: this.currentWarning.iconType,
      riskLevel: this.currentWarning.riskLevel,
      source: this.currentWarning.source,
      description: this.currentWarning.description,
    };

    if (!this.warnings) this.warnings = [newWarning];
    else this.warnings.push(newWarning);

    this.clearWarning();
  }

  changeWarningIcon(warning: WarningIconType) {
    this.currentWarning.iconType = warning;
    this.isIconsChooseOpen = false;
  }

  openWarningDelete(i: number) {
    this.toDeleteIndex = i;
    this.isWarningDeleting = true;
  }
  closeWarningDelete() {
    this.isWarningDeleting = false;
  }

  openChooseIcon() {
    this.isIconsChooseOpen = true;
  }
  closeChooseIcon() {
    this.isIconsChooseOpen = false;
  }

  clearWarning() {
    this.currentWarning = {
      uuid: uuid(),
      iconType: "" as WarningIconType,
      riskLevel: "" as WarningRiskLevel,
      source: "",
      description: "",
    };
  }

  removeWarning() {
    this.warnings.splice(this.toDeleteIndex, 1);
    this.closeWarningDelete();
  }
}
