import Vue from "vue";
import { Auth } from "@aws-amplify/auth";
import { Storage } from "@aws-amplify/storage";
import awsconfig from "./aws-config";
import i18n from "./i18n";
import router from "@/router";
import App from "@/App.vue";
import store from "@/store/index";

Auth.configure(awsconfig.Auth);
Storage.configure(awsconfig.Storage);

Vue.config.productionTip = false;

import vuetify from "./plugins/vuetify";

new Vue({
  store,
  router,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
