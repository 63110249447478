
import OsdiTooltipButton from "shared-alva/components/buttons/OsdiTooltipButton.vue";
import OsdiConfirmDialog from "shared-alva/components/dialogs/OsdiConfirmDialog.vue";
import OsdiTextField from "shared-alva/components/inputs/OsdiTextField.vue";
import OsdiTextArea from "shared-alva/components/inputs/OsdiTextArea.vue";
import OsdiButton from "shared-alva/components/buttons/OsdiButton.vue";
import { Component, Emit, Vue, Watch } from "vue-property-decorator";
import TrainingsModule from "@/store/modules/trainings-store_v2";
import { MediaEntity, MediaMetadata } from "shared-alva/models";
import ElementDropZone from "@/components/ElementDropZone.vue";
import MediaLibrary from "@/components/media/MediaLibrary.vue";
import ActionBar from "@/components/trainings/ActionBar.vue";
import ElementPreview from "@/components/ElementPreview.vue";
import TargetSettings from "@/components/TargetSettings.vue";
import CreateLayout from "@/components/Layouts/CreateLayout.vue";
import Upload from "../sublessons/blocks/Upload.vue";
import { languages } from "shared-alva/languages";
import { Training } from "shared-alva/models";
import draggable from "vuedraggable";
import { v4 as uuid } from "uuid";
import { ROUTES } from "@/router";

@Component({
  components: {
    OsdiTextArea,
    ElementDropZone,
    ElementPreview,
    ActionBar,
    draggable,
    OsdiTextField,
    OsdiButton,
    OsdiConfirmDialog,
    Upload,
    MediaLibrary,
    TargetSettings,
    OsdiTooltipButton,
    CreateLayout,
  },
})
export default class TrainingCreate extends Vue {
  titleRules = [(v: string) => (v && v.length <= 80) || "max 80"];
  $refs!: { confirmDialog: OsdiConfirmDialog };
  training: Training = {} as Training;
  showMediaLibrary: boolean = false;
  private unsavedChanges = false;
  resolvedMedia?: MediaEntity;
  languages = languages;
  isMounted = false;
  isSaving = false;
  valid = false;

  @Watch("training", { deep: true })
  onTrainingChanged() {
    this.unsavedChanges = true;
  }

  async mounted() {
    this.training = {
      id: uuid(),
      title: "",
      status: "draft",
      description: "",
      elements: [],
      targetGroups: [],
      assetTypes: [],
      owner: { tenant: "fettecompacting", siteId: "global" },
      language: languages.de,
      version: "1.0",
      lastUpdated: 0,
    } as Training;

    await this.resolveMedia();
    this.isMounted = true;
  }

  get isLoading() {
    return TrainingsModule.isLoading || !this.isMounted;
  }

  get hasThumbnail() {
    return this.training.thumbnail !== undefined && this.training.thumbnail !== null;
  }

  async resolveMedia() {
    if (!this.hasThumbnail) {
      return;
    }

    this.resolvedMedia = this.training.thumbnail;
  }

  async save() {
    this.isSaving = true;

    await TrainingsModule.saveTraining(this.training);
    this.isSaving = false;

    this.$refs.confirmDialog.close();

    this.$router.push({
      name: ROUTES.SmartCollegeTrainingDetail,
      params: {
        trainingId: this.training.id,
      },
      query: {
        version: this.training.version,
        language: this.training.language,
      },
    });
  }

  /**
   * @see app.vue
   **/
  public onCloseDialog() {
    if (this.unsavedChanges) {
      this.$refs.confirmDialog.open();
      return;
    }
    this.close();
  }

  @Emit()
  public close() {
    this.$router.push({ name: ROUTES.SmartCollegeTrainings });
  }

  async saveAndClose() {
    await this.save();
    await this.close();
  }

  openMediaLibrary() {
    this.showMediaLibrary = true;
  }
  onCloseMediaLibrary() {
    this.showMediaLibrary = false;
  }

  async onNewMediaSelected(media: MediaMetadata) {
    this.$set(this.training, "thumbnail", {
      owner: { tenant: "fettecompacting", siteId: "global" },
      uuid: media.uuid,
      mimeType: media.mimeType,
      signedUrl: media.signedUrl,
      signedThumbnailUrl: media.signedThumbnailUrl,
      fileExtension: media.fileExtension || "",
    });

    this.showMediaLibrary = false;
    await this.resolveMedia();
  }

  get filteredLanguages() {
    const filtered = [];
    for (const lang in languages) {
      if (lang != "all") filtered.push(lang);
    }
    return filtered;
  }
}
