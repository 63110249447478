
import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { Slide, TemplateType } from "shared-alva/models";
import OsdiDeleteButton from "shared-alva/components/buttons/OsdiDeleteButton.vue";
import OsdiButton from "shared-alva/components/buttons/OsdiButton.vue";
import OsdiTooltipButton from "shared-alva/components/buttons/OsdiTooltipButton.vue";
import draggable from "vuedraggable";
import templateIcons from "@/../assets/template-icons";
import OsdiIconButton from "shared-alva/components/buttons/OsdiIconButton.vue";
import OsdiMenu from "shared-alva/components/menus/OsdiMenu.vue";

@Component({
  components: {
    OsdiMenu,
    OsdiIconButton,
    draggable,
    OsdiButton,
    OsdiDeleteButton,
    OsdiTooltipButton,
  },
})
export default class SlidesMenu extends Vue {
  get availableTemplates(): { name: TemplateType; icon: any }[] {
    return Object.keys(templateIcons).map((key) => {
      return {
        name: key as TemplateType,
        icon: templateIcons[key as TemplateType],
      };
    });
  }

  @Prop()
  allSlides!: Slide[];

  @Prop()
  slide!: Slide;

  @Emit()
  onOpenSlide(id: string) {
    return id;
  }

  @Emit()
  onAppendSlide(templateType: TemplateType) {
    return templateType;
  }

  @Emit()
  onChangeTemplateType(templateType: TemplateType) {
    return templateType;
  }

  @Emit()
  onDeleteSlide() {
    return "delete";
  }

  @Emit()
  onSlideOrderChanged(evt: any): { from: number; to: number } {
    return { from: evt.oldIndex, to: evt.newIndex };
  }

  isActive(slide: Slide): boolean {
    return slide.id === this.slide.id;
  }
}
