import { render, staticRenderFns } from "./SublessonCreate.vue?vue&type=template&id=236dad11&scoped=true&"
import script from "./SublessonCreate.vue?vue&type=script&lang=ts&"
export * from "./SublessonCreate.vue?vue&type=script&lang=ts&"
import style0 from "./SublessonCreate.vue?vue&type=style&index=0&id=236dad11&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "236dad11",
  null
  
)

export default component.exports