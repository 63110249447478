
import { Component, VModel, Emit, Vue, Watch } from "vue-property-decorator";
import ActionBar from "@/components/sublessons/ActionBar.vue";
import TargetSettings from "@/components/TargetSettings.vue";
import SublessonMetadataEditor from "@/components/sublessons/SublessonMetadataEditor.vue";
import SlidesMenu from "@/components/sublessons/SlidesMenu.vue";
import SlideEditor from "@/components/sublessons/SlideEditor.vue";
import OsdiButton from "shared-alva/components/buttons/OsdiButton.vue";
import OsdiSnackbar from "shared-alva/components/snackbars/OsdiSnackbar.vue";
import { v4 as uuid } from "uuid";
import OsdiCloseButton from "shared-alva/components/buttons/OsdiCloseButton.vue";
import OsdiIconButton from "shared-alva/components/buttons/OsdiIconButton.vue";
import OsdiMenu from "shared-alva/components/menus/OsdiMenu.vue";
import SublessonOverview from "@/components/sublessons/SublessonOverview/SublessonOverview.vue";
import lodash from "lodash";
import arrayMove from "array-move";
import OsdiConfirmDialog from "shared-alva/components/dialogs/OsdiConfirmDialog.vue";
import { hasPermission, getAllStatus } from "@/authentication/authentication.service";
import { Sublesson, Slide, TemplateType } from "shared-alva/models";
import { languages } from "shared-alva/languages";
import SublessonsModule from "@/store/modules/sublessons-store_v2";
import UIStateModule from "@/store/modules/uistate-store";
import PublishDialog from "../PublishDialog.vue";
import { TableMenu } from "@/models";
import { exportTranslationFile, importTranslationFile } from "@/translation.service";
import { downloadContent } from "@/utils";
import { ROUTES } from "@/router";
import { valid } from "semver";
import { deleteSublesson } from "shared-alva/api/graphql/mutations";
import {
  VProgressLinear,
  VCard,
  VCardText,
  VContainer,
  VRow,
  VCol,
  VToolbar,
  VTooltip,
  VBtn,
  VIcon,
  VSpacer,
  VMenu,
  VList,
  VListItem,
  VListItemTitle,
  VForm,
  VNavigationDrawer,
  VTabs,
  VTab,
  VTabsItems,
  VTabItem,
} from "vuetify/lib";

@Component({
  components: {
    OsdiConfirmDialog,
    SublessonOverview,
    OsdiIconButton,
    TargetSettings,
    ActionBar,
    SlideEditor,
    SlidesMenu,
    SublessonMetadataEditor,
    OsdiButton,
    OsdiSnackbar,
    OsdiCloseButton,
    OsdiMenu,
    PublishDialog,
  },
})
export default class SublessonDetail extends Vue {
  public sublesson: Sublesson = {} as Sublesson;
  // @VModel()
  // sublesson!: Sublesson;

  $refs!: {
    snackbar: OsdiSnackbar;
    confirmDialog: OsdiConfirmDialog;
    confirmDeleteDialog: OsdiConfirmDialog;
    translationFileUpload: HTMLInputElement;
  };

  updated = 0;
  currentSlide?: Slide | null = null;
  slideId = "";
  unsavedChanges = false;
  private clonedSublesson?: Sublesson;
  valid = false;
  moreActions: TableMenu[] = [
    { label: "Export translation file", icon: "mdi-export", action: this.exportTranslationFile },
    { label: "Import translation file", icon: "mdi-import", action: this.openFilePicker },
  ];

  drawer = false;
  showPublish = false;
  tabs = null;
  showSaveButton = false;
  private permissions: string[] = [];
  private languages: languages[] = Object.values(languages);
  private selectedLanguage: languages = this.$i18n.locale as languages;
  private status: { text: string; value: string; disabled: boolean }[] = [
    {
      text: "Draft",
      value: "draft",
      disabled: false,
    },
    {
      text: "Published",
      value: "published",
      disabled: false,
    },
  ];

  get allSlides() {
    return this.sublesson.slides ?? [];
  }

  get getOverflow() {
    return this.drawer ? "initial" : "hidden";
  }

  async created() {
    const language = (this.$route.query.language as string) || "";
    const version = (this.$route.query.version as string) || "";
    const sublesson = await SublessonsModule.fetchSublesson({
      owner: { siteId: "global", tenant: "fettecompacting" },
      id: this.$route.params.sublessonId || "",
      language: language,
      version: version,
    });
    this.sublesson = (sublesson as Sublesson) || {};

    this.clonedSublesson = lodash.cloneDeep(this.sublesson);
    this.currentSlide = this.sublesson.slides[0];
    this.showSaveButton =
      (await hasPermission("updateSublesson")) && this.sublesson.status !== "published";
    this.permissions = await getAllStatus("Sublesson");
  }

  @Watch("sublesson", { deep: true })
  onSublessonChanged() {
    this.unsavedChanges = !lodash.isEqual(this.sublesson, this.clonedSublesson);
  }

  handleValueChange(value: languages) {
    this.selectedLanguage = value;
  }

  @Watch("permissions")
  async handleStatus(permissions: string[]) {
    this.status = this.status.map((item) => {
      item.text = this.$t(`smartcollege.sublessonTable.status.${item.value}`).toString();
      item.disabled = permissions.indexOf(item.value) === -1;
      return item;
    });
  }

  @Emit()
  public close() {
    this.$router.push({ name: ROUTES.SmartCollegeSublessons });
  }

  async saveAndClose() {
    await this.save();
    await this.close();
  }

  async save() {
    await SublessonsModule.saveSublesson(this.sublesson);
    this.unsavedChanges = false;

    UIStateModule.applySaveButtonStatus(true);
    this.clonedSublesson = lodash.cloneDeep(this.sublesson);

    this.$refs.snackbar.open({
      message: this.$t("smartcollege.notification.changesSaved").toString(),
    });
  }

  get isLoading() {
    return SublessonsModule.isLoading;
  }

  /**
   * @see app.vue
   **/
  public onCloseDialog() {
    if (this.unsavedChanges) {
      this.$refs.confirmDialog.open();
      return;
    }

    this.close();
    this.$router.push({
      path: `/sublessons`,
    });
  }

  openSlide(id: string) {
    this.currentSlide = this.sublesson.slides.find((slide) => slide.id === id);
    this.slideId = this.currentSlide!.id;
  }

  appendSlide(templateType: TemplateType) {
    const newSlide = {
      id: uuid(),
      templateType,
      title: "",
      bodyText: "",
      media: [],
      secondaryMedia: [],
      hints: [],
      warnings: [],
    };
    this.sublesson.slides.push(newSlide);
    this.currentSlide = newSlide;
  }

  changeTemplateType(templateType: TemplateType) {
    if (!this.currentSlide) return;
    this.currentSlide.templateType = templateType;
  }

  deleteSlide() {
    const tempSlide = this.currentSlide!;
    const index = this.sublesson.slides.indexOf(this.currentSlide!);

    this.$refs.snackbar.close();
    this.$refs.snackbar.open({
      message: this.$t("smartcollege.slideeditor.deleteNotification.message", {
        name: this.currentSlide?.title,
      }).toString(),
      actionText: this.$t("smartcollege.slideeditor.deleteNotification.action").toString(),
      action: () => {
        this.sublesson.slides.push(tempSlide);
        this.$refs.snackbar.close();
      },
      duration: 2000,
    });

    this.sublesson.slides.splice(index, 1);

    if (this.allSlides.length === 0) {
      this.appendSlide(TemplateType.SlideTextRightImageLeft);
    }

    this.currentSlide = this.sublesson.slides[index == 0 ? 0 : index - 1];
  }

  changeSlideOrder(evt: { from: number; to: number }) {
    if (evt.from === evt.to) return;
    this.sublesson.slides = arrayMove(this.sublesson.slides, evt.from, evt.to);
  }

  async deleteSublesson() {
    this.$refs.confirmDeleteDialog.open({
      confirmCallback: async () => {
        await SublessonsModule.deleteSublesson(this.sublesson);
        this.close();
        this.$router.go(-1);
      },
      message: this.$t("smartcollege.list.deleteNotification.message", {
        name: this.sublesson.title,
      }).toString(),
    });
  }

  toggleDrawer() {
    this.drawer = !this.drawer;
  }

  get canBePublished() {
    return !this.unsavedChanges && this.sublesson.status !== "published";
  }

  publish() {
    this.showPublish = true;
  }

  onPublishCanceled() {
    this.showPublish = false;
  }

  async onPublished() {
    this.showPublish = false;
    this.close();
  }

  exportTranslationFile() {
    const translationFile = exportTranslationFile(this.sublesson, "XLSX");
  }

  async importTranslationFile(event: any) {
    let fileData = event.target.files[0];
    const sl = (await importTranslationFile(this.sublesson, fileData, "XLSX")) as Sublesson;

    for (const [key, value] of Object.entries(sl)) {
      this.$set(this.sublesson, key, lodash.cloneDeep(value));
    }

    this.updated = this.updated + 1;
  }

  openFilePicker() {
    this.$refs.translationFileUpload!.click();
  }
}
