
import { PickerElement } from "@/models";
import QuestionModule from "@/store/modules/questions-store";
import { languages } from "shared-alva/languages";
import { ElementStatusType, Question, Quiz } from "shared-alva/models";
import OsdiTextField from "shared-alva/components/inputs/OsdiTextField.vue";
import OsdiTextArea from "shared-alva/components/inputs/OsdiTextArea.vue";
import OsdiButton from "osdi-design-system/molecules/button/button.vue";
import { Component, Vue, Prop, Watch, Emit } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import ElementDropZone from "../ElementDropZone.vue";
import ElementPicker from "../ElementPicker.vue";
import { v4 as uuid } from "uuid";
import QuizModule from "@/store/modules/quiz-store";
import OsdiSnackbar from "shared-alva/components/snackbars/OsdiSnackbar.vue";
import PublishDialog from "../PublishDialog.vue";
import OsdiConfirmDialog from "shared-alva/components/dialogs/OsdiConfirmDialog.vue";
import { ROUTES } from "@/router";
import _ from "lodash";

@Component({
  components: {
    OsdiTextField,
    OsdiTextArea,
    ElementDropZone,
    ElementPicker,
    OsdiButton,
    OsdiSnackbar,
    PublishDialog,
    OsdiConfirmDialog,
  },
})
export default class QuizDetails extends Vue {
  @Prop() quiz!: Quiz;
  @Prop() language!: string;
  public currentQuiz: Quiz = {} as Quiz;
  public pickedElements: PickerElement[] = [];
  public pickerElements: PickerElement[] = [];
  public question: Question = {} as Question;
  public tabs = null;
  public drawer = false;
  public loaded = false;
  isDropZoneHighlighted = false;
  snackMessage = "";
  public questionStore = getModule(QuestionModule);
  public quizStore = getModule(QuizModule);
  public languageItems = Object.values(languages);
  public requiredRules = [(v: string) => !!v || "Required"];
  showPublish = false;
  canBeSaved = false;
  canBePublished = false;
  isLanguageChange = false;
  lastPickedElements: PickerElement[] = [];

  $refs!: {
    snackbar: OsdiSnackbar;
    form: HTMLFormElement;
    confirmDialog: OsdiConfirmDialog;
  };

  languageChange(result: boolean) {
    this.isLanguageChange = result;
  }

  public onCloseDialog() {
    if (this.canBeSaved) {
      this.$refs.confirmDialog.open();
      return;
    }
    this.close();
    this.$router.push({ name: ROUTES.SmartCollegesQuizzes });
  }

  @Emit()
  public async close() {
    this.$router.push({ name: ROUTES.SmartCollegesQuizzes });
  }

  async saveAndClose() {
    await this.save();
    await this.close();
  }

  async deleteQuiz() {
    await this.quizStore.deleteQuiz({
      owner: this.currentQuiz.owner,
      id: this.currentQuiz.id,
      language: this.currentQuiz.language,
      version: this.currentQuiz.version,
    });
    await this.close();
  }

  get getOverflow() {
    return this.drawer ? "initial" : "hidden";
  }

  get CanBePublished() {
    let can = true;

    this.pickedElements.forEach((elem) => {
      if (elem.status !== "published") {
        can = false;
      }
    });

    return can && this.canBePublished && this.isLanguageChange;
  }

  highlightDropZone(highlight: boolean) {
    this.isDropZoneHighlighted = highlight;
  }
  public toggleDrawer() {
    this.drawer = !this.drawer;
  }

  async mounted() {
    const language = (this.$route.query.language as string) || "";
    const version = (this.$route.query.version as string) || "";
    const quiz = await this.quizStore.getQuiz({
      owner: { siteId: "global", tenant: "fettecompacting" },
      id: this.$route.params.quizId || "",
      language: language,
      version: version,
    });
    if (quiz) {
      this.currentQuiz = (quiz as Quiz) || {};
    } else {
      this.currentQuiz = {
        title: "",
        description: "",
        element: "",
        id: this.$route.params.quizId || "",
        questions: [],
        quizLength: 0,
        status: "draft",
        language: this.$route.query.language || "en",
        type: "quiz",
        owner: { siteId: "global", tenant: "fettecompacting" },
        version: this.$route.query.version || "1.0",
        lastUpdated: 0,
      } as Quiz;
    }

    await this.questionStore.fetchQuestions(this.currentQuiz.language as languages);
    this.loadPickerElement();
    const newLanguage = this.$route.query.newLanguage || "";
    const newVersion = this.$route.query.newVersion || "";
    if (newVersion.length) {
      this.currentQuiz.version = newVersion as string;
    }
    if (newLanguage.length) {
      this.currentQuiz.language = newLanguage as string;
    }
    if (newVersion.length || newLanguage.length) {
      this.currentQuiz.status = "draft";
    }
    this.loaded = true;
  }

  loadPickerElement() {
    this.pickedElements = this.currentQuiz.questions.map((elem) => {
      return {
        title: elem.title,
        element: elem.element,
        parentLanguage: this.currentQuiz.language,
        status: elem.status,
        id: elem.id,
        owner: elem.owner,
        language: elem.language,
        version: elem.version,
      } as PickerElement;
    });

    this.pickerElements = (
      this.questionStore.questionList[this.currentQuiz.language].map((q) => q.latest) as Question[]
    )
      .filter((q) => q !== undefined)
      .map((elem) => {
        return {
          title: elem.title,
          element: elem.element,
          parentLanguage: this.currentQuiz.language,
          status: elem.status,
          id: elem.id,
          owner: elem.owner,
          language: elem.language,
          version: elem.version,
        } as PickerElement;
      });
  }

  @Watch("currentQuiz.language")
  async changeLanguage() {
    await this.questionStore.fetchQuestions(this.currentQuiz.language as languages);
    this.loadPickerElement();
  }

  async save() {
    if (this.$refs.form.validate()) {
      if (this.currentQuiz.status == "published") return false;
      if (await this.saveQuiz(this.currentQuiz.status || "draft")) {
        this.canBeSaved = false;
      }
    } else {
      return false;
    }
  }

  async saveQuiz(status: string): Promise<boolean> {
    if (this.currentQuiz.id === "" || this.currentQuiz.id === undefined) {
      this.currentQuiz.id = uuid();
    }

    const quizAux = { ...this.currentQuiz };
    quizAux.questions = this.pickedElements.map((elem) => {
      const allVersions = this.questionStore.questionList[this.currentQuiz.language].find(
        (q) => q.id === elem.id
      )?.allVersions;
      return allVersions?.find((v) => v.version === elem.version) as Question;
    });
    this.currentQuiz = quizAux;
    this.currentQuiz.status = status as ElementStatusType;
    if (status !== "published") {
      try {
        await this.quizStore.saveQuiz(this.currentQuiz);
        this.canBePublished = true;
        this.$refs.snackbar.open();
        this.snackMessage = "Quiz saved successfully";
        return true;
      } catch (err) {
        this.$refs.snackbar.open();
        this.snackMessage = "Error saving quiz";
        return false;
      }
    } else {
      try {
        this.$refs.snackbar.open();
        this.snackMessage = "Quiz published successfully";
        return true;
      } catch (err) {
        this.$refs.snackbar.open();
        this.snackMessage = "Error publishing quiz";
        return false;
      }
    }
  }

  @Watch("currentQuiz", { deep: true })
  @Watch("pickedElements", { deep: true })
  validatePickedElements(current: any[]) {
    let canPublish = false;
    let canSave = false;
    if (
      this.currentQuiz.title.length > 0 &&
      this.currentQuiz.element.length > 0 &&
      this.currentQuiz.description.length > 0
    ) {
      if (this.pickedElements.length === 0) {
        this.canBePublished = false;
        if (this.loaded) this.canBeSaved = true;
        return;
      }
      this.pickedElements.forEach((elem) => {
        if (elem.language === this.currentQuiz.language) {
          if (elem.status !== "published") {
            canPublish = false;
          } else {
            canPublish = true;
          }
        } else {
          canSave = false;
        }
      });

      this.canBeSaved = canSave;
      this.canBePublished = canPublish;
    }

    if (this.loaded && JSON.stringify(current) != JSON.stringify(this.lastPickedElements)) {
      this.canBeSaved = true;
    }
    this.lastPickedElements = _.cloneDeep(current);
  }

  get CanBeSaved() {
    return this.canBeSaved;
  }

  publish() {
    this.showPublish = true;
  }

  onPublishCanceled() {
    this.showPublish = false;
  }
  onPublished() {
    this.showPublish = false;
    this.onCloseDialog();
  }
}
