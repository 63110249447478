
import { v4 as uuid } from "uuid";
import { Component, VModel } from "vue-property-decorator";
import Vue from "vue";
import { Hint, HintType } from "shared-alva/models";
import OsdiTextField from "shared-alva/components/inputs/OsdiTextField.vue";
import OsdiButton from "shared-alva/components/buttons/OsdiButton.vue";
import OsdiTooltipButton from "shared-alva/components/buttons/OsdiTooltipButton.vue";
import OsdiCloseButton from "shared-alva/components/buttons/OsdiCloseButton.vue";
import OsdiTextArea from "shared-alva/components/inputs/OsdiTextArea.vue";
import OsdiHints from "shared-alva/components/menus/OsdiHints.vue";
import OsdiDeleteButton from "shared-alva/components/buttons/OsdiDeleteButton.vue";
import OsdiIconButton from "shared-alva/components/buttons/OsdiIconButton.vue";

//@ts-ignore
import { Bold } from "tiptap-vuetify";
import uistateStore from "@/store/modules/uistate-store";

@Component({
  components: {
    OsdiDeleteButton,
    OsdiCloseButton,
    OsdiIconButton,
    OsdiTextArea,
    OsdiButton,
    OsdiTextField,
    OsdiHints,
    OsdiTooltipButton,
  },
})
export default class HintBlock extends Vue {
  extensions = [Bold];

  isWarningDeleting = false;
  isWarningOpen = false;

  @VModel()
  hints!: Hint[];

  enableHint(type: String) {
    this.hints.push({
      uuid: uuid(),
      type: type as HintType,
      text: "",
    });
  }

  openWarningMenu() {
    uistateStore.setWarningsDialogState(true);
  }

  removeHint(hint: Hint) {
    const index = this.hints.indexOf(hint);
    this.hints.splice(index, 1);
  }

  getIconByType(type: HintType): string {
    if (type === HintType.Info) {
      return "mdi-information";
    }
    return "mdi-paperclip";
  }

  getIconColorByType(type: HintType): string {
    if (type === HintType.Info) {
      return "#4DB6E4";
    }
    return "#008B95";
  }
}
