
import { TableMenuAction } from "@/models";
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { TableItemDefault } from "./TableItem.interface";

@Component
export default class OsdiMenuOptions extends Vue {
  @Prop({ required: true }) readonly item!: TableItemDefault;
  @Prop({ required: true }) readonly tableMenu!: TableMenuAction[];
}
