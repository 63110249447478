
import { Component, Vue, Prop } from "vue-property-decorator";
import Authenticator from "@/authentication/components/Authenticator.vue";

@Component({
  components: { Authenticator },
})
export default class Home extends Vue {
  @Prop()
  to!: string;
}
