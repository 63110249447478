
import Vue from "vue";
import OsdiButton from "shared-alva/components/buttons/OsdiButton.vue";
import { Component, Prop } from "vue-property-decorator";
import { TrainingElement, getElementType, TrainingElementReference } from "shared-alva/models";
import trainingStore from "@/store/modules/trainings-store_v2";
import lessonStore from "@/store/modules/lessons-store_v2";
import sublessonStore from "@/store/modules/sublessons-store_v2";
import QuestionModule from "@/store/modules/questions-store";
import QuizModule from "@/store/modules/quiz-store";
import learningPathStore from "@/store/modules/learningPath-store";
import OsdiTooltipButton from "shared-alva/components/buttons/OsdiTooltipButton.vue";
import { getModule } from "vuex-module-decorators";
import AuthStore from "@/store/modules/auth-store";

@Component({
  components: {
    OsdiButton,
    OsdiTooltipButton,
  },
})
export default class PublishDialog extends Vue {
  @Prop()
  element!: TrainingElement;

  private authStore = getModule(AuthStore);
  loading = false;
  changeLogMessage: string = "";
  editorEmail: string = "";
  private quizStore = getModule(QuizModule);
  private questionStore = getModule(QuestionModule);

  mounted() {
    this.editorEmail = this.getEmail;
  }

  cancel() {
    this.$emit("publishCanceled");
  }

  get getEmail(): string {
    return (this.authStore.user! as any).attributes!.email ?? "";
  }

  get elementReference(): TrainingElementReference {
    return {
      type: getElementType(this.element),
      owner: this.element.owner,
      id: this.element.id,
      language: this.element.language,
      version: this.element.version,
    };
  }

  async publish() {
    this.loading = true;

    if (getElementType(this.element) == "training") {
      await trainingStore.publishTraining({
        reference: this.elementReference,
        changelogMessage: this.changeLogMessage,
        editorEmail: this.editorEmail,
      });
    } else if (getElementType(this.element) == "lesson") {
      await lessonStore.publishLesson({
        reference: this.elementReference,
        changelogMessage: this.changeLogMessage,
        editorEmail: this.editorEmail,
      });
    } else if (getElementType(this.element) == "sublesson") {
      await sublessonStore.publishSublesson({
        reference: this.elementReference,
        changelogMessage: this.changeLogMessage,
        editorEmail: this.editorEmail,
      });
    } else if (getElementType(this.element) == "quiz") {
      await this.quizStore.publishQuiz({
        reference: this.elementReference,
        changelogMessage: this.changeLogMessage,
        editorEmail: this.editorEmail,
      });
    } else {
      await this.questionStore.publishQuestion({
        reference: this.elementReference,
        changelogMessage: this.changeLogMessage,
        editorEmail: this.editorEmail,
      });
    }
    this.loading = false;
    this.$emit("published");
  }
}
