import { Module, VuexModule, Mutation, Action } from "vuex-module-decorators";
import store from "@/store/index";

@Module({ dynamic: true, namespaced: true, store, name: "language" })
class LanguageModule extends VuexModule {
  language = "";

  @Mutation
  SET_LANG(language: string) {
    this.language = language;
  }

  @Action({ rawError: true })
  setLanguage(language: string) {
    this.SET_LANG(language);
  }
}

export default LanguageModule;
