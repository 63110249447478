
import Vue from "vue";
import { Component, Emit, Prop } from "vue-property-decorator";

import { TableMenuAction } from "@/models";
import { TableItemDraft } from "./TableItem.interface";
import OsdiMenuOption from "./MenuOptions.vue";

@Component({ components: { OsdiMenuOption } })
export default class OsdiTableItem extends Vue {
  @Prop({ required: true }) readonly item!: TableItemDraft;
  @Prop({ required: true }) readonly leftIcon!: string;
  @Prop({ required: true }) readonly tableMenu!: TableMenuAction[];
  @Prop({ default: false }) readonly isTrainingTable!: boolean;
  readonly titleLabel = `${this.item.draft.element}: ${this.item.draft.title}`;
  readonly titleTrainingLabel = this.item.draft.title;
  readonly statusLabel = this.item.draft.status;
  readonly languageLabel = this.item.draft.language;
  readonly targetGroupList = this.item.draft.targetGroups;
  readonly assetTypesList = this.item.draft.assetTypes;

  @Emit()
  onClickRow() {}

  extractFirstLetter(text: string) {
    return text && text[0];
  }
}
