
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { BadgeType, Badge } from "shared-alva/models";

@Component
export default class BadgeElement extends Vue {
  @Prop()
  badge!: Badge;

  getBackgroundColor() {
    return this.badge.color
      ? this.badge.color
      : this.badge.text === BadgeType.OK
      ? "#F0F2F3"
      : "#2DCC2D";
  }

  getTextColor() {
    return !this.badge.text.length ? "#818a8f" : "#ffffff";
  }
}
