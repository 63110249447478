
import { Component, Watch, Vue } from "vue-property-decorator";
import { FilterBarItems, TableMenuAction, TrainingElementVersions } from "@/models";
import { MachineTypes, Owner, Sublesson } from "shared-alva/models";
import { MachineTypesAPI } from "shared-alva";
import { availableLanguages, languages } from "shared-alva/languages";
import { getModule } from "vuex-module-decorators";
import OsdiTable, { OsdiTableRowAction } from "shared-alva/components/tables/OsdiTable.vue";
import DialogStore from "@/store/modules/dialog-store";
import TopFiltersBar from "@/components/TopFiltersBar.vue";
import OsdiConfirmDialog from "shared-alva/components/dialogs/OsdiConfirmDialog.vue";
import { hasPermission } from "@/authentication/authentication.service";
import SublessonsModule from "@/store/modules/sublessons-store_v2";
import UIStateModule from "@/store/modules/uistate-store";
import awsconfig from "@/aws-config";
import Changelog from "@/components/Changelog.vue";
import OsdiButton from "osdi-design-system/molecules/button/button.vue";
import { v4 as uuid } from "uuid";
import { ROUTES } from "@/router";
import OsdiTableItem from "./TableItem/SublessonTableItem.vue";
import OsdiDraftTableItem from "@/components/tableTools/TableItemDraft.vue";
import OsdiTableFooter from "@/components/tableTools/TableFooter.vue";

import { contentFilterItems, sortItems, refilterContent } from "@/service/contentHandlers";
import { tableHeaders } from "@/utils/tableHeaders";

import i18n from "@/i18n";
import { sort } from "semver";
import { DataTableHeader } from "vuetify";
import LanguageModule from "@/store/modules/language-store";
import { filterBarItems } from "@/utils/filterBarItems";
import FiltersModule from "@/store/modules/filters-store";
import { VProgressLinear, VSelect, VChip, VMenu, VBtn, VIcon, VToolbar } from "vuetify/lib";

const dialogStore = getModule(DialogStore);

@Component({
  components: {
    OsdiConfirmDialog,
    OsdiTable,
    TopFiltersBar,
    Changelog,
    OsdiButton,
    OsdiTableItem,
    OsdiDraftTableItem,
    OsdiTableFooter,
  },
})
export default class SublessonOverview extends Vue {
  $refs!: {
    confirmDeleteDialog: OsdiConfirmDialog;
  };

  private machineTypesAPI = new MachineTypesAPI(awsconfig.API_V2);
  actions: OsdiTableRowAction[] = [];
  private uiStore = UIStateModule;

  sublessonStore = SublessonsModule;
  filteredItems: TrainingElementVersions[] = [];
  expandedItems: TrainingElementVersions[] = [];

  private filteredNewVersion: any = "";
  selectedVersion: { [id: string]: string } = {};

  private machineTypes: string[] = [];
  private searchInput: string = "";

  showChangelog: boolean = false;
  changelogData?: Sublesson;
  private elementPath = "sublesson";
  languageStore = getModule(LanguageModule);
  filterBarItems = this.getFilterBarItems;
  filterStore = getModule(FiltersModule);

  tableMenu: TableMenuAction[] = [
    {
      label: "createNewTranslation",
      icon: "mdi-translate",
      action: (sublesson: Sublesson) => this.newVersionDialog(sublesson, true),
    },
    {
      label: "createNewVersion",
      icon: "mdi-source-branch-plus",
      action: (sublesson: Sublesson) => this.newVersionDialog(sublesson, false),
    },
    {
      label: "showChangelog",
      icon: "mdi-history",
      action: (sublesson: Sublesson) => {
        this.openChangelog(sublesson);
      },
    },
    {
      label: "duplicate",
      icon: "mdi-content-copy",
      action: (sublesson: Sublesson) => {
        this.duplicate(sublesson);
      },
    },
  ];

  @Watch("languageStore.language")
  refreshFilters() {
    this.filterBarItems = this.getFilterBarItems;
  }

  get getFilterBarItems(): FilterBarItems[] {
    return [
      {
        label: i18n.t("smartcollege.overviewTable.headers.status").toString(),
        items: [
          i18n.t("smartcollege.filters.all").toString(),
          i18n.t("smartcollege.filters.draft").toString(),
          i18n.t("smartcollege.filters.review").toString(),
          i18n.t("smartcollege.filters.approved").toString(),
          i18n.t("smartcollege.filters.published").toString(),
        ],
        value: i18n.t("smartcollege.filters.all").toString(),
        onChange: () => {},
      },
      {
        label: i18n.t("smartcollege.overviewTable.headers.language").toString(),
        items: ["Default", ...availableLanguages],
        itemLabels: ["Default", ...availableLanguages].flatMap((el: string) => {
          return i18n.t(`smartcollege.language.${el}`).toString();
        }),
        value: i18n.locale,
        onChange: () => {},
      },
      {
        label: i18n.t("smartcollege.overviewTable.headers.targetGroups").toString(),
        items: [
          i18n.t("smartcollege.filters.all").toString(),
          i18n.t("smartcollege.targetGroups.MECHANIC").toString(),
          i18n.t("smartcollege.targetGroups.ELECTRICIAN").toString(),
          i18n.t("smartcollege.targetGroups.OPERATOR").toString(),
        ],
        value: i18n.t("smartcollege.filters.all").toString(),
        onChange: () => {},
      },

      {
        label: i18n.t("smartcollege.overviewTable.headers.assetTypes").toString(),
        items: [i18n.t("smartcollege.filters.all").toString(), ...this.machineTypes],
        value: i18n.t("smartcollege.filters.all").toString(),
        onChange: () => {},
      },
    ];
  }

  get getStatusMap(): { [id: string]: string } {
    return {
      [i18n.t("smartcollege.filters.all").toString()]: "All",
      [i18n.t("smartcollege.filters.review").toString()]: "Review",
      [i18n.t("smartcollege.filters.approved").toString()]: "Approved",
      [i18n.t("smartcollege.filters.published").toString()]: "Published",
      [i18n.t("smartcollege.filters.draft").toString()]: "Draft",
    };
  }

  get getTypeMap(): { [id: string]: string } {
    return {
      [i18n.t("smartcollege.filters.all").toString()]: "All",
      [i18n.t("smartcollege.targetGroups.MECHANIC").toString()]: "MECHANIC",
      [i18n.t("smartcollege.targetGroups.ELECTRICIAN").toString()]: "ELECTRICIAN",
      [i18n.t("smartcollege.targetGroups.OPERATOR").toString()]: "OPERATOR",
    };
  }

  get tableHeaders(): DataTableHeader[] {
    return [
      {
        text: "",
        value: "id",
        width: "1%",
        sortable: false,
      },
      {
        text: i18n.t("smartcollege.overviewTable.headers.title").toString(),
        sortable: true,
        value: "title",
      },
      {
        text: i18n.t("smartcollege.overviewTable.headers.version").toString(),
        align: "start",
        sortable: false,
        value: "version",
        width: "2%",
      },
      {
        text: i18n.t("smartcollege.overviewTable.headers.status").toString(),
        value: "status",
        sortable: false,
      },
      {
        text: i18n.t("smartcollege.overviewTable.headers.language").toString(),
        value: "language",
        sortable: false,
      },
      {
        text: i18n.t("smartcollege.overviewTable.headers.learners").toString(),
        value: "targetGroups",
        sortable: false,
      },
      {
        text: i18n.t("smartcollege.overviewTable.headers.assetTypes").toString(),
        value: "assetTypes",
        sortable: false,
      },
      {
        text: "",
        value: "context-menu",
        width: "1%",
        sortable: false,
      },
      {
        text: "",
        value: "data-table-expand",
        sortable: false,
      },
    ];
  }

  async created() {
    if (await hasPermission("deleteSublesson")) {
      this.actions.push({
        tooltip: this.$t("smartcollege.sublessoneditor.actions.deleteSublesson").toString(),
        icon: "mdi-delete",
        callback: async (sublesson: Sublesson) => await this.deleteSublesson(sublesson),
      });
    }
  }

  @Watch("filterBarItems", { deep: true })
  @Watch("searchInput")
  changeSearchInput() {
    this.filterStore.setAllFilters({
      element: this.elementPath,
      filters: this.filterBarItems,
      searchInput: this.searchInput,
    });
  }

  async mounted() {
    const owner: Owner = {
      tenant: "fettecompacting",
      siteId: "global",
    };
    const { types }: MachineTypes = (await this.machineTypesAPI.getMachineTypes(
      owner
    )) as MachineTypes;

    this.machineTypes = types;
    this.filterBarItems[3].items = [
      i18n.t("smartcollege.filters.all").toString(),
      ...this.machineTypes,
    ];

    if (this.filterStore.filters[this.elementPath]) {
      this.filterBarItems = this.filterStore.filters[this.elementPath].filterItems;
      this.searchInput = this.filterStore.filters[this.elementPath].searchInput;
      this.uiStore.setSearchInput(this.searchInput);
    } else {
      this.filterStore.setAllFilters({
        element: this.elementPath,
        filters: this.filterBarItems,
        searchInput: this.searchInput,
      });
    }
  }

  newVersionDialog(sublesson: Sublesson, translate: boolean) {
    dialogStore.openDialog({
      componentName: "new-version-dialog",
      data: {
        baseElement: { ...sublesson, type: "sublesson" },
        translate: translate,
      },
      customDialog: true,
    });
  }

  sort(items: any, index: string, isDescending: string) {
    const sortedItems = sortItems(items, index, isDescending);
    return sortedItems;
  }

  get selectedLanguage(): languages {
    if (this.filterBarItems[1].value == "Default") return this.$i18n.locale as languages;

    return this.filterBarItems[1].value as languages;
  }

  get selectedMachineType(): string {
    let filtersMap = {
      [i18n.t("smartcollege.filters.all").toString()]: "All",
    };

    const machinesMaps: { [id: string]: string } = {};

    this.filterBarItems[3].items.forEach((elem) => {
      machinesMaps[elem] = elem;
    });

    filtersMap = { ...machinesMaps, ...filtersMap };
    return filtersMap[this.filterBarItems[3].value];
  }

  get selectedTargetGroup(): string {
    return this.getTypeMap[this.filterBarItems[2].value];
  }
  get selectedStatus(): string {
    return this.getStatusMap[this.filterBarItems[0].value];
  }

  getSelectedVersion(id: string): string {
    return this.selectedVersion[id] ?? "";
  }

  openChangelog(sublesson: Sublesson) {
    this.changelogData = sublesson;
    this.showChangelog = true;
  }

  changeVersion(id: string, e: string): any {
    this.selectedVersion[id] = e;

    //finds the correct object that we want to display
    const itemFind = this.filteredItems.find((f) => f.id == id);
    const versionFind = itemFind?.publishedVersions[e];
    this.filteredNewVersion = versionFind;

    this.reloadFilteredItems();
  }

  closeChangelog(): void {
    this.showChangelog = !this.showChangelog;
  }

  @Watch("selectedMachineType")
  @Watch("selectedTargetGroup")
  @Watch("selectedStatus")
  @Watch("searchInput")
  @Watch("sublessonStore.sublessonList", { immediate: true })
  async reloadFilteredItems() {
    const sublessons = await this.sublessonStore.getAllSublessonVersions(this.selectedLanguage);

    const filteredValues = contentFilterItems(
      sublessons,
      this.filteredItems,
      this.expandedItems,
      this.selectedVersion,
      this.selectedTargetGroup,
      this.selectedStatus,
      this.selectedMachineType,
      this.filteredNewVersion,
      this.searchInput
    );
    this.filteredItems = filteredValues.filteredItems;
    this.expandedItems = filteredValues.expandedItems;
  }

  @Watch("selectedLanguage")
  async languageChanged() {
    //request content with the new language and then refilter them with the searchInput value
    const content = await this.sublessonStore.getAllSublessonVersions(this.selectedLanguage);
    const refilteredContent = refilterContent(
      content,
      this.searchInput,
      this.selectedStatus,
      this.selectedVersion
    );
    this.filteredItems = refilteredContent.filteredItems;
    this.expandedItems = refilteredContent.expandedItems;
  }

  goToSublesson(lessonId: string, version: string, language: string) {
    this.$router.push({
      name: ROUTES.SmartCollegeSublessonDetail,
      params: {
        sublessonId: lessonId,
      },
      query: {
        version: version,
        language: language,
      },
    });
  }

  createNewSublesson() {
    // openCreateContentDialog("sublesson");
    this.$router.push({
      name: ROUTES.SmartCollegesSublessonNew,
    });
  }

  async duplicate(sublesson: Sublesson) {
    const completeSublesson = await this.sublessonStore.getSublesson(sublesson);
    const newSublesson = {
      ...completeSublesson,
      id: uuid(),
      version: "1.0",
      status: "draft",
    } as Sublesson;
    await SublessonsModule.saveSublesson(newSublesson);
  }

  async deleteSublesson(sublesson: Sublesson) {
    this.$refs.confirmDeleteDialog.open({
      confirmCallback: async () => {
        this.sublessonStore.deleteSublesson(sublesson);
      },
      message: this.$t("smartcollege.list.deleteNotification.message", {
        name: sublesson.title,
      }).toString(),
    });
  }

  @Watch("uiStore.getSearchInput")
  searchInputs() {
    return (this.searchInput = this.uiStore.getSearchInput);
  }

  genId() {
    return uuid();
  }
}
