
import Vue from "vue";
import { getModule } from "vuex-module-decorators";
import { Component, Watch } from "vue-property-decorator";
import { v4 as uuid } from "uuid";
import MachineTypeItem from "./MachineTypeItem/MachineTypeItem.vue";
import OsdiConfirmDialog from "shared-alva/components/dialogs/OsdiConfirmDialog.vue";
import OsdiSnackbar from "osdi-design-system/molecules/snackbar/Snackbar.vue";
import MachineTypesModule from "@/store/modules/machine-types-store";

@Component({ components: { MachineTypeItem, OsdiConfirmDialog, OsdiSnackbar } })
export default class MachineTypeList extends Vue {
  machineTypesStore = getModule(MachineTypesModule);
  machineTypes: string[] = [];
  $refs!: {
    itemsRef: HTMLDivElement;
  };

  async mounted() {
    await this.machineTypesStore.fetchMachineTypes();
  }

  genId() {
    return uuid();
  }

  @Watch("machineTypesStore.getMachineTypes")
  watchMachineTypesStore() {
    this.machineTypes = this.machineTypesStore.getMachineTypes;
  }
}
