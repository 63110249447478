
import { Component, Watch, Vue } from "vue-property-decorator";
import { TrainingElementVersions, QuestionTypes, FilterBarItems, TableMenuAction } from "@/models";
import { Sublesson, Question } from "shared-alva/models";
import { availableLanguages, languages } from "shared-alva/languages";
import { getModule } from "vuex-module-decorators";
import OsdiTable, { OsdiTableRowAction } from "shared-alva/components/tables/OsdiTable.vue";
import DialogStore from "@/store/modules/dialog-store";
import TopFiltersBar from "@/components/TopFiltersBar.vue";
import OsdiConfirmDialog from "shared-alva/components/dialogs/OsdiConfirmDialog.vue";
import QuestionModule from "@/store/modules/questions-store";
import UIStateModule from "@/store/modules/uistate-store";
import awsconfig from "@/aws-config";
import Changelog from "@/components/Changelog.vue";
import OsdiTooltipButton from "shared-alva/components/buttons/OsdiTooltipButton.vue";
import { questionTypes } from "@/utils/questionTypes";
import { refilterContent, sortItems } from "@/service/contentHandlers";
import QuestionDetails from "./QuestionDetails.vue";
import QuestionCreate from "./QuestionCreate.vue";
import QuizVersion from "../quizzes/QuizVersion.vue";
import i18n from "@/i18n";
import questionsStore from "@/store/modules/questions-store";
import { DataTableHeader } from "vuetify";
import LanguageModule from "@/store/modules/language-store";
import { questionHeaders } from "@/utils/tableHeaders";
import { ROUTES } from "@/router";
import { filterBarItems } from "@/utils/filterBarItems";
import { sort } from "semver";
import {
  VProgressLinear,
  VSelect,
  VMenu,
  VBtn,
  VIcon,
  VList,
  VListItem,
  VListItemTitle,
  VNavigationDrawer,
  VDivider,
  VHover,
  VCard,
  VImg,
  VToolbar,
} from "vuetify/lib";
import OsdiButton from "osdi-design-system/molecules/button/button.vue";
import FiltersModule from "@/store/modules/filters-store";
import { deleteQuestion } from "shared-alva/api/graphql/mutations";
import OsdiTableFooter from "../tableTools/TableFooter.vue";

const dialogStore = getModule(DialogStore);

@Component({
  components: {
    OsdiConfirmDialog,
    OsdiTable,
    TopFiltersBar,
    OsdiButton,
    Changelog,
    OsdiTooltipButton,
    QuestionDetails,
    QuestionCreate,
    QuizVersion,
    OsdiTableFooter,
  },
})
export default class QuestionOverview extends Vue {
  $refs!: {
    confirmDeleteDialog: OsdiConfirmDialog;
  };

  actions: OsdiTableRowAction[] = [];
  uiStore = UIStateModule;
  private elementPath = "question";
  questionTypeToOpen = "";
  questionsStore = getModule(QuestionModule);
  filteredItems: TrainingElementVersions[] = [];
  expandedItems: TrainingElementVersions[] = [];
  selectedQuestion: Question | null = null;
  drawer = false;
  questionTypes: QuestionTypes[] = questionTypes;
  selectedVersion: { [id: string]: string } = {};
  translate = false;
  filterStore = getModule(FiltersModule);

  searchInput: string = "";

  showChangelog: boolean = false;
  changelogData?: Sublesson;

  languageStore = getModule(LanguageModule);
  filterBarItems = this.getFilterBarItems;

  @Watch("languageStore.language")
  refreshFilters() {
    this.filterBarItems = this.getFilterBarItems;
  }

  get getFilterBarItems(): FilterBarItems[] {
    return [
      {
        label: i18n.t("smartcollege.overviewTable.headers.status").toString(),
        items: [
          i18n.t("smartcollege.filters.all").toString(),
          i18n.t("smartcollege.filters.draft").toString(),
          i18n.t("smartcollege.filters.review").toString(),
          i18n.t("smartcollege.filters.approved").toString(),
          i18n.t("smartcollege.filters.published").toString(),
        ],
        value: i18n.t("smartcollege.filters.all").toString(),
        onChange: () => {},
      },
      {
        label: i18n.t("smartcollege.overviewTable.headers.language").toString(),
        items: ["Default", ...availableLanguages],
        itemLabels: ["Default", ...availableLanguages].flatMap((el: string) => {
          return i18n.t(`smartcollege.language.${el}`).toString();
        }),
        value: i18n.locale,
        onChange: () => {},
      },
    ];
  }

  get getStatusMap(): { [id: string]: string } {
    return {
      [i18n.t("smartcollege.filters.all").toString()]: "All",
      [i18n.t("smartcollege.filters.review").toString()]: "Review",
      [i18n.t("smartcollege.filters.approved").toString()]: "Approved",
      [i18n.t("smartcollege.filters.published").toString()]: "Published",
      [i18n.t("smartcollege.filters.draft").toString()]: "Draft",
    };
  }

  get questionHeaders(): DataTableHeader[] {
    return [
      {
        text: i18n.t("smartcollege.overviewTable.headers.title").toString(),
        sortable: true,
        value: "title",
      },
      {
        text: i18n.t("smartcollege.overviewTable.headers.version").toString(),
        align: "start",
        sortable: false,
        value: "version",
        width: "1%",
      },
      {
        text: i18n.t("smartcollege.overviewTable.headers.status").toString(),
        value: "status",
        sortable: false,
      },
      {
        text: i18n.t("smartcollege.overviewTable.headers.language").toString(),
        value: "language",
        sortable: false,
      },
      {
        text: "",
        value: "delete-icon",
        sortable: false,
        width: "1%",
      },
    ];
  }

  toggleDrawer() {
    this.drawer = !this.drawer;
  }

  sort(items: any, index: string, isDescending: string) {
    const sortedItems = sortItems(items, index, isDescending);
    return sortedItems;
  }

  tableMenu: TableMenuAction[] = [
    {
      label: "createNewTranslation",
      icon: "mdi-translate",
      action: (item: Question) => this.newVersionDialog(item, true),
    },
    {
      label: "createNewVersion",
      icon: "mdi-source-branch-plus",
      action: (item: Question) => this.newVersionDialog(item, false),
    },
  ];

  startQuestion(question: Question) {
    this.selectedQuestion = question;
  }

  newVersionDialog(item: Question, translate: boolean) {
    dialogStore.openDialog({
      componentName: "new-version-dialog",
      data: {
        baseElement: { ...item, type: "question" },
        translate: translate,
      },
      customDialog: true,
    });
  }

  openQuestionModalTrans(item: Question) {
    this.translate = true;
    this.selectedQuestion = item;
  }

  openQuestionModal(item: Question) {
    this.translate = false;
    this.selectedQuestion = item;
  }

  openModal(item: Question, lastest = false) {
    this.$router.push({
      name: ROUTES.SmartCollegeQuestionDetails,
      params: {
        questionId: item.id,
      },
      query: {
        version: lastest ? this.selectedVersion[item.id] : item.version,
        language: item.language,
      },
    });
  }

  async created() {}

  get getSelectedQuestion() {
    return this.selectedQuestion;
  }

  loadExpandedItems() {
    const expandedItems: TrainingElementVersions[] = [];
    if (this.filteredItems && this.filteredItems.length > 0) {
      this.filteredItems.forEach((element: TrainingElementVersions) => {
        this.selectedVersion[element.id] = element.latestPublished
          ? element.latestPublished.version
          : element.latest?.version || "";

        if (element.latestPublished && element.draft) {
          expandedItems.push(element);
        }
      });
    }

    this.expandedItems = expandedItems;
  }

  @Watch("filterBarItems", { deep: true })
  @Watch("searchInput")
  changeSearchInput() {
    this.filterStore.setAllFilters({
      element: this.elementPath,
      filters: this.filterBarItems,
      searchInput: this.searchInput,
    });
  }

  async mounted() {
    await this.questionsStore.fetchQuestions(this.selectedLanguage as languages);
    this.filteredItems = this.questionsStore.questionList[this.selectedLanguage];
    if (this.filterStore.filters[this.elementPath]) {
      this.filterBarItems = this.filterStore.filters[this.elementPath].filterItems;
      this.searchInput = this.filterStore.filters[this.elementPath].searchInput;
      this.uiStore.setSearchInput(this.searchInput);
    } else {
      this.filterStore.setAllFilters({
        element: this.elementPath,
        filters: this.filterBarItems,
        searchInput: this.searchInput,
      });
    }

    this.loadExpandedItems();
  }

  @Watch("questionsStore.getQuestions")
  onQuestionStoreChanged() {
    this.filteredItems = this.questionsStore.questionList[this.selectedLanguage];

    this.loadExpandedItems();
  }

  deleteQuestion(id: string, language: string, version: string) {
    this.questionsStore.removeQuestion({ id, language, version });

    this.reload();
  }

  get selectedLanguage(): languages {
    if (this.filterBarItems[1].value == "Default") return this.$i18n.locale as languages;
    return this.filterBarItems[1].value as languages;
  }

  get selectedStatus(): string {
    return this.getStatusMap[this.filterBarItems[0].value];
  }

  getSelectedVersion(id: string): string {
    return this.selectedVersion[id] ?? "";
  }

  openChangelog(sublesson: Sublesson) {
    this.changelogData = sublesson;
    this.showChangelog = true;
  }

  changeVersion(id: string, e: string): any {
    this.selectedVersion[id] = e;
  }

  closeChangelog(): void {
    this.showChangelog = !this.showChangelog;
  }

  @Watch("selectedMachineType")
  @Watch("selectedTargetGroup")
  @Watch("selectedStatus")
  @Watch("questionsStore.getQuestions", { immediate: true })
  @Watch("questionsStore.questionList")
  async reloadFilteredItems() {
    const questions = this.questionsStore.questionList[this.selectedLanguage];

    const filteredContent = refilterContent(
      questions,
      this.searchInput,
      this.selectedStatus,
      this.selectedVersion
    );

    this.filteredItems = filteredContent.filteredItems;
    this.expandedItems = filteredContent.expandedItems;
  }

  @Watch("searchInput.length")
  @Watch("questionsStore.questionList")
  @Watch("questionsStore.getQuestions", { immediate: true })
  @Watch("questionsStore.questionList")
  async reload() {
    this.reloadFilteredItems();
    this.loadExpandedItems();
  }

  @Watch("selectedLanguage")
  async languageChanged() {
    await this.questionsStore.fetchQuestions(this.selectedLanguage);
    const questions = this.questionsStore.questionList[this.selectedLanguage];

    const filteredContent = refilterContent(
      questions,
      this.searchInput,
      this.selectedStatus,
      this.selectedVersion
    );

    this.filteredItems = filteredContent.filteredItems;
    this.loadExpandedItems();
  }

  createQuestion(type: string) {
    this.$router.push({
      name: ROUTES.SmartCollegeQuestionNew,
      query: {
        type: type,
      },
    });
  }

  @Watch("uiStore.getSearchInput")
  searchInputs() {
    return (this.searchInput = this.uiStore.getSearchInput);
  }
}
