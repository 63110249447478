
import lodash from "lodash";
import { PickerElement } from "@/models";
import { Component, VModel, Emit, Vue, Watch } from "vue-property-decorator";
import { Lesson, Quiz, Sublesson, TrainingElementReference } from "shared-alva/models";
import { languages } from "shared-alva/languages";
import ActionBar from "@/components/lessons/ActionBar.vue";
import ElementPicker from "@/components/ElementPicker.vue";
import ElementDropZone from "@/components/ElementDropZone.vue";
import OsdiTextField from "shared-alva/components/inputs/OsdiTextField.vue";
import OsdiIconButton from "shared-alva/components/buttons/OsdiIconButton.vue";
import OsdiButton from "shared-alva/components/buttons/OsdiButton.vue";
import OsdiTooltipButton from "shared-alva/components/buttons/OsdiTooltipButton.vue";
import OsdiTextArea from "shared-alva/components/inputs/OsdiTextArea.vue";
import OsdiSearchField from "shared-alva/components/inputs/OsdiSearchField.vue";
import OsdiCloseButton from "shared-alva/components/buttons/OsdiCloseButton.vue";
import OsdiSnackbar from "shared-alva/components/snackbars/OsdiSnackbar.vue";
import OsdiConfirmDialog from "shared-alva/components/dialogs/OsdiConfirmDialog.vue";
import TargetSettings from "@/components/TargetSettings.vue";
import OsdiMenu from "shared-alva/components/menus/OsdiMenu.vue";
import { hasPermission } from "@/authentication/authentication.service";
import LessonsModule from "@/store/modules/lessons-store_v2";
import sublessonStore from "@/store/modules/sublessons-store_v2";
import PublishDialog from "../PublishDialog.vue";
import QuizModule from "@/store/modules/quiz-store";
import { getModule } from "vuex-module-decorators";
import { ROUTES } from "@/router";
import { valid } from "semver";
import {
  VProgressLinear,
  VCard,
  VCardText,
  VContainer,
  VRow,
  VCol,
  VToolbar,
  VTooltip,
  VBtn,
  VIcon,
  VSpacer,
  VForm,
  VSelect,
  VNavigationDrawer,
  VTabs,
  VTab,
  VTabsItems,
  VTabItem,
} from "vuetify/lib";

@Component({
  components: {
    OsdiSearchField,
    OsdiTextArea,
    ElementDropZone,
    ActionBar,
    ElementPicker,
    OsdiTextField,
    OsdiButton,
    OsdiCloseButton,
    OsdiSnackbar,
    OsdiConfirmDialog,
    OsdiIconButton,
    OsdiMenu,
    PublishDialog,
    TargetSettings,
    OsdiTooltipButton,
  },
})
export default class LessonDetail extends Vue {
  isDropZoneHighlighted = false;

  private clonedLesson?: Lesson;
  private titleRules = [
    (v: string) => !!v || "required",
    (v: string) => v.length <= 80 || "max 80",
  ];
  descriptionRules = [
    (v: string) => !!v || "required",
    (v: string) => v.length <= 120 || "max 120",
  ];
  drawer = false;

  $refs!: {
    snackbar: OsdiSnackbar;
    confirmDialog: OsdiConfirmDialog;
    elementPicker: ElementPicker;
    confirmDeleteDialog: OsdiConfirmDialog;
  };

  snackbarMessage = "";
  private languages: languages[] = Object.values(languages);
  private selectedLanguage: languages = this.$i18n.locale as languages;
  tabs = null;
  showSaveButton = false;
  private showExportButton = false;
  private showDeleteButton = false;
  loaded = false;
  hasError = false;
  private quizStore = getModule(QuizModule);
  selectedQuiz = "";
  valid = false;
  unsavedChanges = false;
  quizTitle = "";
  showPublish = false;
  quizElement: TrainingElementReference | undefined = undefined;
  private currentLesson: Lesson = {} as Lesson;

  pickerElements: PickerElement[] = [];
  pickedElements: PickerElement[] = [];
  private firstOpen = true;
  isSaved = false;
  hasErrorPub = false;

  public lesson: Lesson = {} as Lesson;

  get isLoading() {
    return LessonsModule.isLoading || sublessonStore.isLoading;
  }

  get quizzes() {
    if (this.quizStore.quizList[this.lesson.language]) {
      let quizzes = this.quizStore.quizList[this.lesson.language].map((q) => q.latest);

      quizzes = quizzes.filter((q) => q !== undefined);

      return quizzes as Quiz[];
    } else {
      return [];
    }
  }

  get getQuizTitles() {
    if (this.quizzes.length > 0) {
      const quizTitle = this.quizzes.map((e) => {
        if (e) {
          return e.status + " - " + e.title;
        }
      });
      return quizTitle;
    }
    return [];
  }

  get getOverflow() {
    return this.drawer ? "initial" : "hidden";
  }

  // @VModel()
  // lesson!: Lesson;

  @Watch("lesson", { deep: true })
  onLessonChanged() {
    this.unsavedChanges = !lodash.isEqual(this.lesson, this.clonedLesson) && !this.firstOpen;
    this.firstOpen = false;
  }

  @Watch("pickedElements", { deep: true })
  async updateSublessons() {
    this.hasError = false;
    this.hasErrorPub = false;
    this.lesson.sublessons = this.pickedElements.map((el) => {
      return {
        type: el.type,
        owner: el.owner,
        id: el.id,
        language: el.language,
        version: el.version,
        title: el.title,
        element: el.element,
      } as unknown as Sublesson;
    });

    try {
      // check if there is a version, if there is not throw error
      const sublessons = this.lesson.sublessons;
      for (let i = 0; i < sublessons.length; i++) {
        const sublesson = sublessons[i];

        if (sublesson.language !== this.lesson.language) {
          this.hasError = true;
        }

        const availableVersion = await sublessonStore.getAvailableVersions(sublesson);

        if (availableVersion.length === 0 && !(sublesson.element === "-Not found-")) {
          throw new Error("No version available");
        }
      }
    } catch (e) {
      this.hasError = true;
    }
  }

  onChange(event: any) {
    const quizArray = this.quizStore.quizList[this.lesson.language].map((q) => q.latest) as Quiz[];

    let selectedQuizID = "";

    quizArray.forEach((e) => {
      if (event.split(" - ")[1] === e.title) {
        selectedQuizID = e.id;
      }
    });

    this.selectedQuiz = selectedQuizID;

    if (this.quizStore.quizList[this.lesson.language].length > 0) {
      this.quizTitle =
        this.quizStore.quizList[this.lesson.language].find((q) => q.id === this.selectedQuiz)
          ?.latest?.title || "";
    }

    this.updateQuiz();
  }

  updateQuiz(iSDelete = false) {
    if (!iSDelete) {
      const quizzes = this.quizStore.quizList[this.lesson.language];
      if (quizzes.length !== 0) {
        const quizzes = this.quizStore.quizList[this.lesson.language];
        const quiz = quizzes.find((q) => q.id === this.selectedQuiz)?.latest;
        if (quiz) {
          this.quizElement = {
            id: quiz.id,
            owner: { tenant: "fettecompacting", siteId: "global" },
            language: quiz.language,
            version: quiz.version,
            type: "quiz",
          } as TrainingElementReference;
        }
      }
    } else {
      this.quizElement = undefined;
      this.lesson.quiz = undefined;
      this.quizTitle = "";
      this.selectedQuiz = "";
    }
    this.unsavedChanges = true;
  }

  @Emit()
  public async close() {
    if (this.unsavedChanges) {
      await LessonsModule.resetLesson({
        owner: this.lesson.owner,
        id: this.lesson.id,
        language: this.lesson.language as languages,
        version: this.lesson.version,
      });
    }

    this.$router.push({ name: ROUTES.SmartCollegeLessons });
  }

  async saveAndClose() {
    await this.save();
    await this.close();
  }

  private sublessons: (Sublesson | undefined)[] = [];

  // @Watch("lesson")
  // async loadSublessons() {
  //   const sublessonPromises = this.lesson.sublessons.map((s) => {
  //     return sublessonStore.getSublesson({
  //       owner: s.owner,
  //       id: s.id,
  //       language: s.language,
  //       version: s.version,
  //     });
  //   });

  //   this.sublessons = await Promise.all(sublessonPromises);

  //   if (this.lesson.quiz && this.quizStore.getQuizzes[this.lesson.quiz?.id]) {
  //     this.quizTitle = this.quizStore.getQuizzes[this.lesson.quiz?.id].title;
  //   }
  //   this.clonedLesson = lodash.cloneDeep(this.lesson);
  // }

  async loadLessonElements() {
    // Improve this by fake caching
    await this.quizStore.fetchQuizzes(this.lesson.language as languages);
    const sublessonsOrig = await sublessonStore.getAllSublessonVersions(
      this.lesson.language as languages
    );
    const sublessons = sublessonsOrig.map((el: any) => el.latest);
    this.pickerElements = [
      ...sublessons.map((s) => {
        const pickerElement: PickerElement = {
          owner: s.owner,
          description: s.description || "",
          id: s.id,
          language: s.language,
          type: "sublesson",
          version: s.version,
          title: s.title,
          element: s.element,
          parentLanguage: this.lesson.language,
          status: s.status,
        };
        return pickerElement;
      }),
    ];

    if (this.lesson.quiz) {
      this.selectedQuiz = this.lesson.quiz.id;
    }

    const sublessonPromises = this.lesson.sublessons.map((s) => {
      return sublessonStore.getSublesson({
        owner: s.owner,
        id: s.id,
        language: s.language,
        version: s.version,
      });
    });
    this.sublessons = await Promise.all(sublessonPromises);

    this.pickedElements = (this.sublessons as Sublesson[]).map((el) => {
      const ref: PickerElement = {
        owner: el.owner,
        id: el.id,
        description: el.description || "",
        language: el.language,
        version: el.version,
        type: "sublesson",
        title: el.title,
        element: el.element,
        parentLanguage: this.lesson.language,
        status: el.status || "unknown",
      };
      return ref;
    });

    this.clonedLesson = lodash.cloneDeep(this.lesson);

    this.showSaveButton =
      (await hasPermission("updateLesson")) && this.lesson.status !== "published";
    this.showExportButton = await hasPermission("readLesson");
    this.showDeleteButton = await hasPermission("deleteLesson");
    this.loaded = true;
    if (this.lesson.quiz && this.quizStore.getQuizzes[this.lesson.quiz?.id]) {
      this.quizTitle = this.quizStore.getQuizzes[this.lesson.quiz?.id].title;
    }

    this.validatePickedElements();
  }

  @Watch("pickedElements")
  validatePickedElements() {
    this.hasErrorPub = false;
    if (this.pickedElements.length > 0) {
      this.pickedElements.forEach((el) => {
        if (el.status !== "published") {
          this.hasErrorPub = true;
        }
      });
    }
  }

  async created() {
    const language = (this.$route.query.language as string) || "";
    const version = (this.$route.query.version as string) || "";
    const lesson = await LessonsModule.fetchLesson({
      owner: { siteId: "global", tenant: "fettecompacting" },
      id: this.$route.params.lessonId || "",
      language: language,
      version: version,
    });

    this.lesson = (lesson as Lesson) || {};

    await this.loadLessonElements();
    this.unsavedChanges = false;
    this.isSaved = true;
  }

  unique(value: string, index: number, self: any) {
    return self.indexOf(value) === index;
  }

  highlightDropZone(highlight: boolean) {
    this.isDropZoneHighlighted = highlight;
  }

  //@see app.vue
  public onCloseDialog() {
    if (this.unsavedChanges) {
      this.$refs.confirmDialog.open();
      return;
    }
    this.close();
    this.$router.push({
      path: `/lessons`,
    });
  }

  async save() {
    let hasError = false;

    try {
      // check if there is a version, if there is now throw error
      const sublessons = this.lesson.sublessons;
      for (let i = 0; i < sublessons.length; i++) {
        const sublesson = sublessons[i];
        const availableVersion = await sublessonStore.getAvailableVersions(sublesson);
        if (availableVersion.length === 0 && !(sublesson.element === "-Not found-")) {
          hasError = true;
          throw new Error("No language version available");
        }
      }

      if (hasError) {
        throw new Error("No language version available");
      }

      if (this.selectedQuiz !== "") {
        this.updateQuiz();
      }
      this.currentLesson = { ...this.lesson, quiz: this.quizElement };
      await LessonsModule.saveLesson(this.currentLesson);
      if (this.quizElement) {
        this.quizTitle = this.quizzes.find((q) => q.id === this.quizElement?.id)!.title;
      } else {
        this.quizTitle = "";
      }
      this.unsavedChanges = false;
      this.isSaved = true;

      this.clonedLesson = lodash.cloneDeep(this.currentLesson);
      this.$refs.snackbar.open();
      this.snackbarMessage = "Saved!";
    } catch (error) {
      this.$refs.snackbar.open();
      this.snackbarMessage = error as string;
    }
  }

  async deleteLesson() {
    this.$refs.confirmDeleteDialog.open({
      confirmCallback: async () => {
        await LessonsModule.deleteLesson(this.lesson);
        this.close();
        this.$router.go(-1);
      },
      message: this.$t("smartcollege.list.deleteNotification.message", {
        name: this.lesson.title,
      }).toString(),
    });
  }

  toggleDrawer() {
    this.drawer = !this.drawer;
  }

  onElementRemovedFromDropZone() {
    this.$refs.elementPicker.refreshDraggables();
  }

  get canBePublished() {
    return (
      this.unsavedChanges &&
      this.lesson.status !== "published" &&
      this.sublessons.every((s) => {
        return s && s!.status == "published" && s!.language == this.lesson.language;
      })
    );
  }

  quizStatus() {
    if (this.selectedQuiz !== "") {
      const trainingElement = this.quizStore.quizList[this.lesson.language].find(
        (q) => q.id === this.selectedQuiz
      );

      if (trainingElement) {
        const status = trainingElement.latest ? trainingElement!.latest!.status : "unknown";

        return status === "published";
      }
      return false;
    }
    return false;
  }

  get canBeSaved() {
    return (
      this.unsavedChanges &&
      this.lesson.status !== "published" &&
      this.lesson.sublessons.every((s) => {
        return s.version !== "" && s.language !== "";
      })
    );
  }

  publish() {
    this.showPublish = true;
  }

  onPublishCanceled() {
    this.showPublish = false;
  }
  onPublished() {
    this.showPublish = false;
    this.close();
  }
}
