
import QuestionModule from "@/store/modules/questions-store";
import { Choice, MediaEntity, Question, QuestionType, ElementStatusType } from "shared-alva/models";
import OsdiButton from "osdi-design-system/molecules/button/button.vue";
import OsdiTextArea from "shared-alva/components/inputs/OsdiTextArea.vue";
import OsdiSnackbar from "shared-alva/components/snackbars/OsdiSnackbar.vue";
import OsdiTextField from "shared-alva/components/inputs/OsdiTextField.vue";
import { Component, Watch, Vue, Prop, Emit } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import { v4 as uuid } from "uuid";
import MediaBlock from "@/components/sublessons/blocks/MediaBlock.vue";
import { remove } from "lodash";
import { languages } from "shared-alva/languages";
import { ROUTES } from "@/router";
import { title } from "process";
import { text } from "stream/consumers";
import { VContainer, VRow, VCol, VForm, VSelect } from "vuetify/lib";
import { loadSharedConfigFiles } from "@aws-sdk/shared-ini-file-loader";

@Component({
  components: {
    OsdiButton,
    OsdiSnackbar,
    OsdiTextField,
    OsdiTextArea,
    MediaBlock,
  },
})
export default class ChooseOne extends Vue {
  questionStore = getModule(QuestionModule);
  @Prop() question!: Question;
  @Prop() save!: boolean;
  @Prop() publish!: boolean;

  text: string = "";
  title: string = "";
  element: string = "";
  description: string = "";
  actionLabel: string = "";
  points: number = 0;
  currentQuestion = { ...this.question };
  choices: Choice[] = [];
  hasInfo: { [id: string]: boolean } = {};
  media: { [id: string]: MediaEntity[] } = {};
  sublessonId = "02d2a892-34c0-4534-a1cf-5e5901b3bfd8";
  languageItems = Object.values(languages);

  public requiredRules = [(v: string) => !!v || "Required"];

  async mounted() {
    this.generateChoice();

    if (this.question) {
      this.title = this.question.title;
      this.text = this.question.text;
      this.element = this.question.element;
      this.description = this.question.description;
      this.actionLabel = this.question.actionLabel;
      this.points = this.question.points;
      this.choices = this.question.choices;
      const hasInfo = { ...this.hasInfo };
      this.choices.forEach((choice) => {
        hasInfo[choice.id] = choice.additionalInfo.length > 0 ? true : false;
      });
      this.hasInfo = hasInfo;
      this.question.choices.forEach((choice) => {
        if (choice.mediaValue) {
          this.media[choice.id] = [choice.mediaValue as MediaEntity];
        } else {
          this.media[choice.id] = [];
        }
      });
    }
    this.loaded();
  }

  @Emit()
  loaded() {}

  $refs!: {
    snackbar: OsdiSnackbar;
    form: HTMLFormElement;
  };

  @Watch("questionStore.questions")
  onQuestionStoreChanged() {
    this.currentQuestion = { ...this.questionStore.getQuestions[this.question.id] };
  }

  openAdditionalInfo(id: string) {
    const hasInfo = { ...this.hasInfo };
    hasInfo[id] = !hasInfo[id];
    this.hasInfo = hasInfo;
  }

  // change to vanilla js
  deleteChoice(index: number) {
    const choicesCopy = [...this.choices];
    remove(choicesCopy, (choice) => choice.id === this.choices[index].id);
    this.choices = choicesCopy;
  }

  generateChoice() {
    const choice: Choice = {
      owner: { siteId: "global", tenant: "fettecompacting" },
      id: uuid(),
      language: "en",
      version: "1.0",
      status: "published",
      textValue: "",
      mediaValue: undefined,
      additionalInfo: "",
      correctPlace: 0,
    };
    const hasInfo = { ...this.hasInfo };
    hasInfo[choice.id] = false;
    this.hasInfo = hasInfo;
    this.choices.push(choice);
    const mediaCopy = { ...this.media };
    mediaCopy[choice.id] = [];
    this.media = mediaCopy;
  }

  @Watch("text")
  @Watch("title")
  @Watch("element")
  @Watch("actionLabel")
  @Watch("points")
  changeText() {
    this.currentQuestion.text = this.text;
    this.currentQuestion.title = this.title;
    this.currentQuestion.element = this.element;
    this.currentQuestion.description = "";
    this.currentQuestion.actionLabel = this.actionLabel;
    this.currentQuestion.points = this.points;
    this.change();
  }

  @Emit()
  change() {}

  @Watch("save")
  async onSave() {
    if (this.$refs.form.validate()) {
      await this.onSubmit(this.currentQuestion.status);
      if (this.$route.path.includes("new")) {
        this.$router.push({
          name: ROUTES.SmartCollegeQuestionDetails,
          params: {
            questionId: this.currentQuestion.id,
          },
          query: {
            version: this.currentQuestion.version,
            language: this.currentQuestion.language,
          },
        });
      }
    } else {
      return false;
    }
  }

  @Watch("publish")
  async onPublish() {
    if (this.$refs.form.validate()) {
      await this.onSubmit("published");
    } else {
      return false;
    }
  }

  async onSubmit(status: string) {
    this.choices.forEach((choice) => {
      choice.mediaValue = this.media[choice.id][0];
    });

    if (this.currentQuestion.id === "" || this.currentQuestion.id === undefined) {
      this.currentQuestion.id = uuid();
    }

    this.choices.forEach((choice) => {
      if (choice.id === "") {
        choice.id = uuid();
      }

      if (choice.status === undefined || choice.status.length === 0) {
        choice.status = "published";
      }
    });

    this.currentQuestion.type = QuestionType.CHOOSE_ONE;
    this.currentQuestion.choices = this.choices;
    this.currentQuestion.text = this.text;
    this.currentQuestion.title = this.title;
    this.currentQuestion.element = this.element;
    this.currentQuestion.description = "";
    this.currentQuestion.actionLabel = this.actionLabel;
    this.currentQuestion.points = 1;
    this.currentQuestion.status = status as ElementStatusType;
    this.currentQuestion.version = this.currentQuestion.version ?? "1.0";
    this.currentQuestion.references = [];
    this.currentQuestion.owner = {
      siteId: "global",
      tenant: "fettecompacting",
    };

    await this.questionStore.saveQuestion(this.currentQuestion);

    this.$refs.snackbar.open({
      message: this.$t("smartcollege.question.textfield.questionSaved").toString(),
      //message: this.$t("smartcollege.notification.changesSaved").toString(),
    });
  }
}
