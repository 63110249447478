
import { Component, Vue } from "vue-property-decorator";
import { FilterBarItems } from "@/models";
import OsdiTooltipButton from "shared-alva/components/buttons/OsdiTooltipButton.vue";
import { LearningPath, Training } from "shared-alva/models";
import { getModule } from "vuex-module-decorators";
import OsdiTable, { OsdiTableRowAction } from "shared-alva/components/tables/OsdiTable.vue";
import DialogStore from "@/store/modules/dialog-store";
import TopFiltersBar from "@/components/TopFiltersBar.vue";
import OsdiConfirmDialog from "shared-alva/components/dialogs/OsdiConfirmDialog.vue";
import LearningPathModule from "@/store/modules/learningPath-store";
import Changelog from "@/components/Changelog.vue";
import { sortItems } from "@/service/contentHandlers";
import i18n from "@/i18n";
import { DataTableHeader } from "vuetify";
import { ROUTES } from "@/router";
import { tableHeaders } from "@/utils/tableHeaders";
import OsdiTableItem from "./TableItem/LearningPathTableItem.vue";
import OsdiDraftTableItem from "@/components/tableTools/TableItemDraft.vue";
import OsdiTableFooter from "@/components/tableTools/TableFooter.vue";
import { v4 as uuid } from "uuid";
import { languages } from "shared-alva";

const dialogStore = getModule(DialogStore);

@Component({
  components: {
    OsdiConfirmDialog,
    OsdiTable,
    TopFiltersBar,
    OsdiTooltipButton,
    Changelog,
    OsdiTableItem,
    OsdiDraftTableItem,
    OsdiTableFooter,
  },
})
export default class LearningPathOverview extends Vue {
  actions: OsdiTableRowAction[] = [];
  learningPathStore = LearningPathModule;
  filterBarItems: FilterBarItems[] = [];
  learningPathsFilter: LearningPath[] = [];
  targetGroupFilterSelected = "all";
  machineTypeFilterSelected = "all";

  async created() {
    await this.learningPathStore.fetchLearningPath();
    this.learningPathsFilter = this.learningPathStore.learningPaths;
    this.filterBarItems = this.getFilterBarItems;
  }

  get getTypeMap(): { [id: string]: string } {
    return {
      [i18n.t("smartcollege.filters.all").toString()]: "All",
      [i18n.t("smartcollege.targetGroups.MECHANIC").toString()]: "MECHANIC",
      [i18n.t("smartcollege.targetGroups.ELECTRICIAN").toString()]: "ELECTRICIAN",
      [i18n.t("smartcollege.targetGroups.OPERATOR").toString()]: "OPERATOR",
    };
  }

  get tableHeaders(): DataTableHeader[] {
    return [
      {
        text: i18n.t("smartcollege.overviewTable.headers.title").toString(),
        sortable: true,
        value: "title",
      },
      {
        text: i18n.t("smartcollege.overviewTable.headers.targetGroups").toString(),
        value: "targetGroups",
        sortable: false,
      },
      {
        text: i18n.t("smartcollege.overviewTable.headers.assetTypes").toString(),
        value: "assetTypes",
        sortable: false,
      },
    ];
  }

  applyFilterLPath() {
    this.learningPathsFilter = this.learningPathStore.learningPaths.filter((lPath) => {
      if (this.targetGroupFilterSelected.toLowerCase() === "all") return lPath;
      return lPath.targetGroups.some(
        (tGroup) => this.targetGroupFilterSelected.toLowerCase() === tGroup.toLowerCase()
      );
    });
    this.learningPathsFilter = this.learningPathsFilter.filter((lPath) => {
      if (this.machineTypeFilterSelected.toLowerCase() === "all") return lPath;
      return lPath.assetTypes.some(
        (machineType) => this.machineTypeFilterSelected.toLowerCase() === machineType.toLowerCase()
      );
    });
  }

  handleOnChangeFilterTargetGroup(targetGroupSelected: string) {
    this.targetGroupFilterSelected = targetGroupSelected;
    this.applyFilterLPath();
  }

  handleOnChangeFilterMachineType(machineTypeSelected: string) {
    this.machineTypeFilterSelected = machineTypeSelected;
    this.applyFilterLPath();
  }

  get getFilterBarItems(): FilterBarItems[] {
    return [
      {
        label: i18n.t("smartcollege.overviewTable.headers.targetGroups").toString(),
        items: [
          i18n.t("smartcollege.filters.all").toString(),
          i18n.t("smartcollege.targetGroups.MECHANIC").toString(),
          i18n.t("smartcollege.targetGroups.ELECTRICIAN").toString(),
          i18n.t("smartcollege.targetGroups.OPERATOR").toString(),
        ],
        value: i18n.t("smartcollege.filters.all").toString(),
        onChange: this.handleOnChangeFilterTargetGroup,
      },

      {
        label: i18n.t("smartcollege.overviewTable.headers.assetTypes").toString(),
        items: [
          i18n.t("smartcollege.filters.all").toString(),
          ...this.learningPathStore.machineTypes,
        ],
        value: i18n.t("smartcollege.filters.all").toString(),
        onChange: this.handleOnChangeFilterMachineType,
      },
    ];
  }

  handleResetFilters() {
    this.learningPathsFilter = this.learningPathStore.learningPaths;
  }

  newVersionDialog(training: Training, translate: boolean) {
    dialogStore.openDialog({
      componentName: "new-version-dialog",
      data: {
        baseElement: { ...training, type: "LearningPath" },
        translate,
      },
      customDialog: true,
    });
  }

  sortTable(items: any, index: string, isDescending: string) {
    const sortedItems = sortItems(items, index, isDescending, true);
    return sortedItems;
  }

  createNewTraining() {
    this.$router.push({
      name: ROUTES.SmartCollegesLearningPathNew,
    });
  }

  goToTraining(learningPathId: string, version: string) {
    this.$router.push({
      name: ROUTES.SmartCollegeLearningPathDetail,
      params: {
        learningPathId: learningPathId,
      },
    });
  }

  genId() {
    return uuid();
  }
}
