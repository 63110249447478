
import Vue from "vue";
import { Component, Emit, Prop } from "vue-property-decorator";
import OsdiButton from "osdi-design-system/molecules/button/button.vue";
import OsdiIconButton from "shared-alva/components/buttons/OsdiIconButton.vue";
import MediaUploadStatus from "@/components/media/MediaUploadStatus.vue";
import { MediaMetadata } from "shared-alva/models";

@Component({ components: { OsdiButton, OsdiIconButton, MediaUploadStatus } })
export default class Upload extends Vue {
  @Prop({ default: false, type: Boolean })
  link!: boolean;

  @Prop({ default: true, type: Boolean })
  isUndoDisabled!: boolean;

  showUploadStatus = false;
  dropFiles: File[] = [];
  showDropzone = 0;

  onMediaAdded(media: MediaMetadata) {
    this.$emit("mediaAdded", media);
  }

  onDragOver(e: Event) {
    e.preventDefault();
  }

  onDragEnter(e: Event) {
    e.preventDefault();
    this.showDropzone++;
  }
  onDragLeave(e: Event) {
    e.preventDefault();
    this.showDropzone--;
    if (this.showDropzone == 0) {
    }
  }

  onDrop(e: any) {
    e.stopPropagation();
    e.preventDefault();
    this.showDropzone = 0;
    e.dataTransfer.files.forEach((element: never) => this.dropFiles.push(element));
    this.showUploadStatus = true;
  }

  @Emit()
  applyUndo() {}

  focusInput() {
    (this.$refs.input as any).$el.querySelector("input").click();
  }

  @Emit()
  clearInput(event: MouseEvent) {}
}
