import AuthModule from "@/store/modules/auth-store";
import { IVueI18n } from "vue-i18n";
import { SidebarItem } from "shared-alva/interfaces/sidebarProps";
import { getModule } from "vuex-module-decorators";
import { signOut } from "@/authentication/authentication.service";

export default (i18n: IVueI18n) => ({
  get user(): any {
    const userInfo = getModule(AuthModule).user?.attributes.email.split("@")[0];
    return userInfo;
  },

  typeApp(): SidebarItem[] {
    return [
      {
        title: i18n.t("navigation.alva.title").toString(),
        icon: "mdi-book-open-outline",
      },
    ];
  },
  top(): SidebarItem[] {
    return [
      {
        title: i18n.t("navigation.smartcollege.learningPaths.title").toString(),
        icon: "mdi-format-list-group-plus",
        to: "/learningPaths",
      },
      {
        title: i18n.t("navigation.smartcollege.trainings.title").toString(),
        icon: "mdi-television-guide",
        to: "/trainings",
      },
      {
        title: i18n.t("navigation.smartcollege.lessons.title").toString(),
        icon: "mdi-folder-text-outline",
        to: "/lessons",
      },
      {
        title: i18n.t("navigation.smartcollege.sublessons.title").toString(),
        icon: "mdi-file-document-outline",
        to: "/sublessons",
      },
      {
        title: i18n.t("navigation.smartcollege.quiz.title").toString(),
        icon: "mdi-file-question-outline",
        to: "/quizzes",
      },
      {
        title: i18n.t("navigation.smartcollege.questions.title").toString(),
        icon: "mdi-beaker-question-outline",
        to: "/questions",
      },
      {
        title: "Machine Types",
        icon: "mdi-toolbox-outline",
        to: "/machine-types",
      },
    ];
  },

  bottom(): SidebarItem[] {
    return [
      {
        title: i18n.t("navigation.apps").toString(),
        icon: "mdi-apps",
        to: "/",
      },
      {
        title: this.user,
        icon: "mdi-account-circle-outline",
        language: true,
      },
      {
        title: i18n.t("navigation.logout").toString(),
        icon: "mdi-logout",
        onClick: async () => {
          const authModule = getModule(AuthModule);
          await signOut();
          authModule.logout();
        },
      },
    ];
  },
});
