
import { Component, Prop, Vue } from "vue-property-decorator";
import { MachineTypes, Owner, TrainingElement } from "shared-alva/models";
import { MachineTypesAPI } from "shared-alva";
import awsconfig from "@/aws-config";
import { VChipGroup, VChip } from "vuetify/lib";

@Component({
  components: {},
})
export default class TargetSettings extends Vue {
  @Prop()
  readonly element!: TrainingElement & { assetTypes: string; targetGroups: string };
  @Prop({ default: true })
  readonly multipleTargetGroup!: boolean;
  @Prop({ default: true })
  readonly multipleMachineTypes!: boolean;
  @Prop()
  readonly machineTypes: string[] | undefined;

  private machineTypesAPI = new MachineTypesAPI(awsconfig.API_V2);

  globalAssetTypes: string[] = [];
  filteredAssetTypes: string[] = [];

  async mounted() {
    if (this.machineTypes && this.machineTypes.length > 0) {
      this.globalAssetTypes = [...this.machineTypes];
      return;
    }
    const owner: Owner = {
      tenant: "fettecompacting",
      siteId: "global",
    };
    const { types }: MachineTypes = (await this.machineTypesAPI.getMachineTypes(
      owner
    )) as MachineTypes;
    this.globalAssetTypes = types ?? [];
    this.filteredAssetTypes = this.globalAssetTypes;
  }
}
