
import OsdiConfirmDialog from "shared-alva/components/dialogs/OsdiConfirmDialog.vue";
import OsdiTooltipButton from "shared-alva/components/buttons/OsdiTooltipButton.vue";
import OsdiTextField from "shared-alva/components/inputs/OsdiTextField.vue";
import OsdiTextArea from "shared-alva/components/inputs/OsdiTextArea.vue";
import { VProgressLinear, VForm, VRadioGroup, VRadio } from "vuetify/lib";
import { Component, Emit, Vue, Watch } from "vue-property-decorator";
import ElementDropZone from "@/components/ElementDropZone.vue";
import ElementPreview from "@/components/ElementPreview.vue";
import LessonsModule from "@/store/modules/lessons-store_v2";
import TargetSettings from "@/components/TargetSettings.vue";
import CreateLayout from "@/components/Layouts/CreateLayout.vue";
import ActionBar from "@/components/trainings/ActionBar.vue";
import Upload from "../sublessons/blocks/Upload.vue";
import { languages } from "shared-alva/languages";
import { Lesson } from "shared-alva/models";
import draggable from "vuedraggable";
import { v4 as uuid } from "uuid";
import { ROUTES } from "@/router";

@Component({
  components: {
    OsdiTextArea,
    ElementDropZone,
    ElementPreview,
    ActionBar,
    draggable,
    OsdiTextField,
    OsdiTooltipButton,
    OsdiConfirmDialog,
    Upload,
    TargetSettings,
    CreateLayout,
  },
})
export default class LessonCreate extends Vue {
  valid = false;
  unsavedChanges = false;
  languages = languages;
  lesson: Lesson = {} as Lesson;

  $refs!: { confirmDialog: OsdiConfirmDialog };

  async mounted() {
    this.lesson = {
      id: uuid(),
      title: "",
      element: "",
      status: "draft",
      description: "",
      sublessons: [],
      owner: { tenant: "fettecompacting", siteId: "global" },
      language: languages.de,
      version: "1.0",
      lastUpdated: 0,
      targetGroups: [],
      assetTypes: [],
    } as Lesson;
  }

  @Watch("lesson", { deep: true })
  onLessonChanged() {
    this.unsavedChanges = true;
  }

  get isLoading() {
    return LessonsModule.isLoading;
  }

  async save() {
    await LessonsModule.saveLesson(this.lesson);

    this.$refs.confirmDialog.close();

    this.$router.push({
      name: ROUTES.SmartCollegeLessonDetail,
      params: {
        lessonId: this.lesson.id,
      },
      query: {
        version: this.lesson.version,
        language: this.lesson.language,
      },
    });
  }

  /**
   * @see app.vue
   **/
  public onCloseDialog() {
    this.$router.push({ name: ROUTES.SmartCollegeLessons });
  }

  itemExists = [
    (value: string) => {
      if (value) return true;
      return this.$t("smartcollege.fieldRequired");
    },
  ];

  get filteredLanguages() {
    const filtered = [];
    for (const lang in languages) {
      if (lang != "all") filtered.push(lang);
    }
    return filtered;
  }

  @Emit()
  public close() {}

  async saveAndClose() {
    await this.save();
    await this.close();
  }
}
