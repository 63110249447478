
import Vue from "vue";
import { Component, Emit, Prop } from "vue-property-decorator";

import OsdiMenuOption from "@/components/tableTools/MenuOptions.vue";
import OsdiExpandBtn from "@/components/tableTools/ExpandBtn.vue";
import { LearningPath } from "shared-alva";

@Component({ components: { OsdiMenuOption, OsdiExpandBtn } })
export default class LearningPathTableItem extends Vue {
  @Prop({ required: true }) readonly item!: LearningPath;
  @Prop({ required: true }) readonly leftIcon!: string;

  @Emit()
  onClickRow() {}

  extractFirstLetter(text: string) {
    return text && text[0];
  }

  @Emit()
  onClickExpandItem() {}
}
