
import Vue from "vue";
import { MediaReferences } from "shared-alva";
import { Component, Prop } from "vue-property-decorator";

@Component
export default class ExtraInfoPanel extends Vue {
  @Prop({ required: true }) readonly extraMediaInfo!: MediaReferences[];

  getDateWithTime(date: number): string {
    if (date === 0) return "-";

    return new Date(date).toLocaleDateString("de-DE");
  }
}
