
import Vue from "vue";
import OsdiButton from "osdi-design-system/molecules/button/button.vue";
import { Component, Prop } from "vue-property-decorator";
import MachineTypesModule from "@/store/modules/machine-types-store";
import { getModule } from "vuex-module-decorators";
import OsdiSnackbar from "osdi-design-system/molecules/snackbar/Snackbar.vue";
import OsdiConfirmDialog from "shared-alva/components/dialogs/OsdiConfirmDialog.vue";

@Component({ components: { OsdiButton, OsdiConfirmDialog, OsdiSnackbar } })
export default class MachineTypeFooter extends Vue {
  @Prop({ required: true }) readonly itemsRef!: HTMLDivElement | null;
  machineTypesStore = getModule(MachineTypesModule);
  snackLabel = this.$t("smartcollege.machineType.SuccessSnackLabel");
  snackType = "osdiInfo";
  $refs!: {
    snackbarRef: OsdiSnackbar;
    confirmSaveDialog: OsdiConfirmDialog;
  };

  onClickAdd() {
    this.machineTypesStore.addMachineType();
    window.setTimeout(() => {
      const scrollHeight = this.itemsRef?.scrollHeight;
      this.itemsRef?.scrollTo({ top: scrollHeight, behavior: "smooth" });
    }, 10);
  }

  onClickSave() {
    this.$refs.confirmSaveDialog.open({
      message: this.$t("smartcollege.machineType.ConfirmSaveDialog") as string,
      confirmCallback: async () => {
        const hasSaved = await this.machineTypesStore.saveMachineTypes();
        if (!hasSaved) {
          this.snackType = "osdiWarning";
          this.snackLabel = this.$t("smartcollege.machineType.WarningSnackLabel");
        }
        this.$refs.snackbarRef.onShow();
      },
    });
  }
}
