
import { Component, Watch, Vue, Prop } from "vue-property-decorator";
import { MediaMetadata } from "shared-alva/models";
import { MediaAPI } from "shared-alva";
import { v4 as uuid } from "uuid";
import awsconfig from "@/aws-config";
import AuthModule from "@/store/modules/auth-store";
import { getModule } from "vuex-module-decorators";
import MediaStore from "@/store/modules/media-store_v2";

const authStore = getModule(AuthModule);

type ProgressChangedEvent = { total: number; loaded: number };
type RepositoryUpload = {
  file: File;
  metadata: Promise<MediaMetadata> | undefined;
  status: "COMPLETED" | "CANCELED" | "IN_PROGRESS" | "ERROR";
  progress: number;
  lastStatusEvent: any | undefined;
  objectURL: string;
};

@Component({
  components: {},
})
export default class MediaUploadStatus extends Vue {
  @Prop({
    default: () => {
      [];
    },
  })
  files!: File[];

  currentUploads: RepositoryUpload[] = [];
  private oldFiles: File[] = [];
  private mediaApi = new MediaAPI({
    graphQl: awsconfig.API_V2,
    bucket: awsconfig.Storage.AWSS3.bucket,
  });

  @Watch("files", { immediate: true, deep: true })
  async filesChanged(newVal: File[]) {
    const newFiles = newVal.filter((x: File) => {
      return !this.oldFiles.includes(x) && x;
    });
    this.oldFiles = [...this.files];

    newFiles.forEach((f: File) => {
      this.currentUploads.push({
        file: f,
        objectURL: URL.createObjectURL(f),
        metadata: undefined,
        status: "IN_PROGRESS",
        progress: 0,
        lastStatusEvent: undefined,
      });
      const newUpload = this.currentUploads[this.currentUploads.length - 1];
      newUpload.metadata = this.startUpload(newUpload);
    });
  }

  cancel(i: number) {
    const upload = this.currentUploads[i];
    URL.revokeObjectURL(upload.objectURL);
    this.currentUploads.splice(i, 1);
  }

  async save(i: number, fileName: string) {
    const upload = this.currentUploads[i];
    const metadata = await upload.metadata!;
    metadata.title = (document.getElementById("titleInput__" + fileName) as any).value;
    if (metadata.title == "") metadata.title = upload.file.name;

    metadata.comments = (document.getElementById("commentsInput__" + fileName) as any).value;
    await MediaStore.saveMedia(metadata);
    const newMetadata = await MediaStore.getMediaMetadata({
      owner: metadata.owner,
      mediaId: metadata.uuid,
    });
    this.$emit("mediaAdded", newMetadata);
    URL.revokeObjectURL(upload.objectURL);
    this.currentUploads.splice(i, 1);
  }
  onError(e: any, repositoryUploadStatus: RepositoryUpload) {
    repositoryUploadStatus.status = "ERROR";
  }

  onCompleted(e: any, repositoryUploadStatus: RepositoryUpload) {
    repositoryUploadStatus.status = "COMPLETED";
    repositoryUploadStatus.progress = 100;
  }

  onProgressChange(e: ProgressChangedEvent, repositoryUploadStatus: RepositoryUpload) {
    repositoryUploadStatus.status = "IN_PROGRESS";
    if (e.loaded == e.total) {
      this.onCompleted(e, repositoryUploadStatus);
    } else repositoryUploadStatus.progress = (e.loaded / e.total) * 100;
  }

  async startUpload(repositoryUploadStatus: RepositoryUpload): Promise<MediaMetadata> {
    const onProgressChange = (repositoryUploadStatus: RepositoryUpload) => {
      const progressChangedCallback = (updateProgressEvent: ProgressChangedEvent) => {
        this.onProgressChange(updateProgressEvent, repositoryUploadStatus);
      };
      return progressChangedCallback.bind(this);
    };
    const onError = (repositoryUploadStatus: RepositoryUpload) => {
      const errorEvent = (e: any) => {
        this.onError(e, repositoryUploadStatus);
      };
      return errorEvent.bind(this);
    };
    const onCompleted = (repositoryUploadStatus: RepositoryUpload) => {
      const completedEventCallback = (updateProgressEvent: ProgressChangedEvent) => {
        this.onProgressChange(updateProgressEvent, repositoryUploadStatus);
      };
      return completedEventCallback.bind(this);
    };
    const file = repositoryUploadStatus.file;
    let mediaMetadata: MediaMetadata = {
      title: file.name,
      mimeType: file.type,
      fileName: file.name,
      fileSize: file.size,
      fileExtension: file.name.substring(file.name.lastIndexOf(".") + 1),
      owner: { tenant: authStore.tenant, siteId: "global" },
      uuid: uuid(),
      creationTimestamp: Date.now(),
    };

    // Get image height and width
    const mediaStore = MediaStore;
    if (file.type.includes("image")) {
      var img = new Image();
      img.onload = async function (this: any) {
        var sizes = {
          width: this.width!,
          height: this.height!,
        };
        URL.revokeObjectURL(this.src);
        mediaMetadata.width = sizes.width;
        mediaMetadata.height = sizes.height;

        mediaMetadata = await mediaStore.uploadMediaFile({
          file,
          metadata: mediaMetadata,
          onProgressChange: onProgressChange(repositoryUploadStatus),
          onCompleted: onCompleted(repositoryUploadStatus),
          onError: onError(repositoryUploadStatus),
        });
      };
      var objectURL = URL.createObjectURL(file);
      img.src = objectURL;
    } else if (file.type.includes("video")) {
      var vid = document.createElement("video");
      vid.onloadedmetadata = async function (this: any) {
        var sizes = {
          width: this.videoWidth!,
          height: this.videoHeight!,
        };
        mediaMetadata.duration = this.duration;
        mediaMetadata.width = sizes.width;
        mediaMetadata.height = sizes.height;
        URL.revokeObjectURL(this.src);
        mediaMetadata = await mediaStore.uploadMediaFile({
          file,
          metadata: mediaMetadata,
          onProgressChange: onProgressChange(repositoryUploadStatus),
          onCompleted: onCompleted(repositoryUploadStatus),
          onError: onError(repositoryUploadStatus),
        });
      };
      var objectURL = URL.createObjectURL(file);
      vid.src = objectURL;
    }
    return mediaMetadata;
  }

  thumbnail(item: RepositoryUpload): string {
    return item.objectURL;
  }

  fileName(item: RepositoryUpload): string {
    const fileName = item.file.name;
    if (fileName.length > 25) return fileName.substring(0, 22) + "...";
    else return fileName;
  }

  async title(item: RepositoryUpload): Promise<string> {
    return (await item.metadata)!.title || "";
  }

  fileSize(item: RepositoryUpload): string {
    const bytesToMB = 1048576;
    const bytesToKB = 1024;

    if (item.file.size > bytesToMB / 10) {
      return (item.file.size / bytesToMB).toFixed(2) + "MB";
    } else return (item.file.size / bytesToKB).toFixed(0) + "KB";
  }

  progress(item: RepositoryUpload): number {
    return item.progress;
  }
}
