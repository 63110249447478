
import { Component, Watch, Vue } from "vue-property-decorator";
import logoNeg from "shared-alva/assets/images/alva_logo_negativ_RGB.svg";
import UIStateModule from "@/store/modules/uistate-store";

@Component({})
export default class TopBar extends Vue {
  private uiStore = UIStateModule;

  valueToSearch = "";

  get logoNeg(): any {
    return logoNeg;
  }

  searchValue() {
    if (this.uiStore.setSearchInput) {
      this.uiStore.setSearchInput(this.valueToSearch);
    }
    return this.valueToSearch;
  }
}
