
import Draggable from "vuedraggable";
import ElementPreview from "@/components/ElementPreview.vue";
import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";
import { PickerElement } from "@/models";
import draggable from "vuedraggable";
import { VList } from "vuetify/lib";

@Component({
  components: { Draggable, ElementPreview },
})
export default class ElementDropZone extends Vue {
  @Prop()
  isDropZoneHighlighted!: boolean;

  @Prop()
  isLearningPath!: boolean;

  @Prop()
  elements!: PickerElement[];

  @Prop()
  elementAction?: string;

  @Emit("on-change")
  onChange(result: boolean) {
    return result;
  }

  languageChange(result: boolean) {
    this.onChange(result);
  }

  @Emit()
  private onElementRemovedFromDropZone() {}

  removeElement(element: PickerElement) {
    const index = this.elements.indexOf(element);
    this.elements.splice(index, 1);
    this.onElementRemovedFromDropZone();
  }
}
