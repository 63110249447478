
import Vue from "vue";
import { Component, Emit, Prop, Watch } from "vue-property-decorator";
import BadgeElement from "@/components/sublessons/blocks/BadgeElement.vue";
import { Badge, BadgeType } from "shared-alva/models";

@Component({
  components: {
    BadgeElement,
  },
})
export default class BadgeCarousel extends Vue {
  @Prop()
  selectedBadge?: Badge;

  selectedBadgeIndex = 0;
  badges: Badge[] = [
    { text: BadgeType.EMPTY, color: "#F0F2F3" },
    { text: BadgeType.OK, color: "#2DCC2D" },
    { text: BadgeType.NOT_OK, color: "#FF1F00" },
  ];

  mounted() {
    if (this.selectedBadge) {
      let currentBadgeIndex = this.badges.findIndex((b) => b.text === this.selectedBadge?.text);
      this.selectedBadgeIndex = currentBadgeIndex;
    }
  }

  @Watch("selectedBadgeIndex")
  onSelectedBadge(): void {
    this.currentBadge();
  }

  @Emit()
  currentBadge(): Badge {
    return this.badges[this.selectedBadgeIndex];
  }
}
