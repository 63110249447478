
import Vue from "vue";
import Component from "vue-class-component";
import SublessonMetadataEditor from "@/components/sublessons/SublessonMetadataEditor.vue";
import TargetSettings from "@/components/TargetSettings.vue";
import CreateLayout from "@/components/Layouts/CreateLayout.vue";
import OsdiButton from "shared-alva/components/buttons/OsdiButton.vue";
import { Emit, VModel, Watch } from "vue-property-decorator";
import { Sublesson, TemplateType } from "shared-alva/models";
import { getModule } from "vuex-module-decorators";
import DialogStore from "@/store/modules/dialog-store";
import OsdiConfirmDialog from "shared-alva/components/dialogs/OsdiConfirmDialog.vue";
import { filteredLanguages, languages, defaultLanguage } from "shared-alva/languages";
import SublessonsModule from "@/store/modules/sublessons-store_v2";
import { v4 as uuid } from "uuid";
import { ROUTES } from "@/router";

const dialogStore = getModule(DialogStore);

@Component({
  components: {
    OsdiConfirmDialog,
    TargetSettings,
    SublessonMetadataEditor,
    OsdiButton,
    CreateLayout,
  },
})
export default class SublessonCreate extends Vue {
  valid = false;
  private unsavedChanges = false;
  languages = filteredLanguages;
  private selectedLanguage: languages = defaultLanguage;
  sublesson: Sublesson = {} as Sublesson;

  // @VModel()
  // sublesson!: Sublesson;

  $refs!: { confirmDialog: OsdiConfirmDialog };

  get loading() {
    return SublessonsModule.isLoading;
  }

  async mounted() {
    this.sublesson = {
      id: uuid(),
      title: "",
      element: "",
      status: "draft",
      description: "",
      durationMinutes: 5,
      targetGroups: [],
      assetTypes: [],
      slides: [
        {
          id: uuid(),
          templateType: TemplateType.SlideTextRightImageLeft,
          title: "",
          bodyText: "",
          media: [],
          secondaryMedia: [],
          hints: [],
          warnings: [],
        },
      ],
      owner: { tenant: "fettecompacting", siteId: "global" },
      language: languages.de,
      version: "1.0",
      lastUpdated: 0,
    } as Sublesson;
  }

  @Watch("sublesson", { deep: true })
  onSublessonChanged() {
    this.unsavedChanges = true;
  }

  async save() {
    await SublessonsModule.saveSublesson(this.sublesson);

    this.$router.push({
      name: ROUTES.SmartCollegeSublessonDetail,
      params: {
        sublessonId: this.sublesson.id,
      },
      query: {
        version: this.sublesson.version,
        language: this.sublesson.language,
      },
    });
  }

  /**
   * @see app.vue
   **/
  public onCloseDialog() {
    if (this.unsavedChanges) {
      this.$refs.confirmDialog.open();
      return;
    }

    this.close();
  }

  @Emit()
  public close() {
    this.$router.push({ name: ROUTES.SmartCollegeSublessons });
  }

  async saveAndClose() {
    await this.save();
    await this.close();
  }

  private restoreSublessonAndCloseDialog() {
    this.close();
  }
}
