
import Vue from "vue";
import { Component } from "vue-property-decorator";
import MachineTypeList from "./MachineTypeList/MachineTypeList.vue";
import MachineTypeFooter from "./MachineTypeFooter/MachineTypeFooter.vue";

@Component({ components: { MachineTypeList, MachineTypeFooter } })
export default class MachineTypes extends Vue {
  machineTypeListRefState: HTMLDivElement | null = null;
  $refs!: {
    machineTypeListRef: MachineTypeList;
  };

  mounted() {
    this.machineTypeListRefState = this.$refs.machineTypeListRef.$refs.itemsRef;
  }
}
