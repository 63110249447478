import { Action, Module, Mutation, VuexModule, getModule } from "vuex-module-decorators";
import { MediaFilter, MediaMetadata, MediaReferences, Owner } from "shared-alva/models";

import { MediaAPI } from "shared-alva";
import awsconfig from "@/aws-config";
import store from "@/store/index";

@Module({ dynamic: true, namespaced: true, store, name: "media_v2" })
class MediaModule_v2 extends VuexModule {
  private client = new MediaAPI({
    graphQl: awsconfig.API_V2,
    bucket: awsconfig.Storage.AWSS3.bucket,
  });

  public media: MediaMetadata[] = [];
  public isLoading = false;

  @Mutation
  setMedia(media: MediaMetadata[]) {
    this.media = media;
  }

  @Mutation
  setLoading(loading: boolean) {
    this.isLoading = loading;
  }

  @Action({ rawError: true })
  async saveMedia(media: MediaMetadata) {
    this.setLoading(true);

    const success = await this.client.setMediaMetadata(media);
    if (success) {
      const newMetadata = await this.client.getMediaMetadata(media.owner, media.uuid);
      if (newMetadata) {
        const newMedia = [...this.media];
        const mediaIndex = newMedia.findIndex((el) => {
          return el.uuid == media.uuid;
        });
        if (mediaIndex == -1) newMedia.push(newMetadata);
        else this.media.splice(mediaIndex, 1, newMetadata);
        this.setMedia(newMedia);
      }
    }
    this.setLoading(false);
  }

  @Action({ rawError: true })
  async changeMedia(media: MediaMetadata & { thumbnail?: string }) {
    const { thumbnail, ...newMedia } = media;
    const success = await this.client.setMediaMetadata(newMedia);
    if (success) {
      await this.getMediaMetadata({ owner: media.owner, mediaId: media.uuid });
    }
  }

  @Action({ rawError: true })
  async getMediaReferences({
    id,
    owner,
  }: {
    id: string;
    owner: Owner;
  }): Promise<MediaReferences[] | []> {
    this.setLoading(true);
    try {
      const refs = await this.client.getMediaReferences(id, owner);
      this.setLoading(false);
      return refs || [];
    } catch (e) {
      this.setLoading(false);
      return [];
    }
  }

  @Action
  async deleteMedia(media: MediaMetadata) {
    this.setLoading(true);
    const success = await this.client.deleteMedia(media);
    if (success) {
      this.setMedia([...this.media.filter((t) => t.uuid != media.uuid)]);
    }
    this.setLoading(false);
  }

  @Action({ commit: "setMedia" })
  async fetchMedia(filter: MediaFilter) {
    this.setLoading(true);
    const media = await this.client.listMedia(filter);
    this.setLoading(false);
    return media;
  }

  @Action
  async getMedia(filter: MediaFilter): Promise<MediaMetadata[]> {
    if (this.media.length > 0) {
      return this.media;
    }

    this.setLoading(true);
    const media = await this.client.listMedia(filter);
    this.setMedia(media);
    this.setLoading(false);
    return media;
  }

  @Action({})
  async addMedia(media: MediaMetadata): Promise<MediaMetadata | undefined> {
    this.setLoading(true);
    const mediaFromServer = await this.getMediaMetadata({
      owner: media.owner,
      mediaId: media.uuid,
    });

    if (mediaFromServer) this.setMedia([...this.media, mediaFromServer!]);
    this.setLoading(false);
    return mediaFromServer;
  }

  @Action
  async uploadMediaFile({
    file,
    metadata,
    onProgressChange,
    onCompleted,
    onError,
  }: {
    file: File;
    metadata: MediaMetadata;
    onProgressChange: any;
    onCompleted: any;
    onError: any;
  }): Promise<MediaMetadata> {
    this.setLoading(true);
    const mediaMetadata = await this.client.uploadMediaFile(
      file,
      metadata,
      onProgressChange,
      onCompleted,
      onError
    );
    this.setLoading(false);
    return mediaMetadata;
  }

  @Action({ rawError: true })
  async getMediaMetadata({
    owner,
    mediaId,
  }: {
    owner: Owner;
    mediaId: string;
  }): Promise<MediaMetadata | undefined> {
    this.setLoading(true);
    const mediaFromServer = await this.client.getMediaMetadata(owner, mediaId);

    if (mediaFromServer) {
      const newMedia = [...this.media];
      const mediaIndex = newMedia.findIndex((el) => {
        return el.uuid == mediaFromServer.uuid;
      });
      if (mediaIndex == -1) newMedia.push(mediaFromServer);
      else newMedia[mediaIndex] = mediaFromServer;
      this.setMedia(newMedia);
    }
    this.setLoading(false);
    return mediaFromServer;
  }
}

export default getModule(MediaModule_v2);
