
import { Component, Emit, Vue, Watch, VModel } from "vue-property-decorator";
import { MediaMetadata } from "shared-alva/models";

@Component({
  components: {},
})
export default class MediaPreviewDialog extends Vue {
  @VModel()
  media!: MediaMetadata;

  @Emit()
  public close() {}

  public onCloseDialog() {
    this.$emit("close");
  }
}
