
import Vue from "vue";
import { Component, Emit, Prop } from "vue-property-decorator";
import LearningPathModule from "@/store/modules/learningPath-store";
import OsdiConfirmDialog from "shared-alva/components/dialogs/OsdiConfirmDialog.vue";
import OsdiSnackbar from "shared-alva/components/snackbars/OsdiSnackbar.vue";
import PublishBtn from "./PublishBtn/PublishBtn.vue";
import { ROUTES } from "@/router";

@Component({ components: { OsdiConfirmDialog, OsdiSnackbar, PublishBtn } })
export default class LearningPathToolbar extends Vue {
  @Prop({ required: true }) readonly title!: string;
  @Prop({ required: true }) readonly version!: string;
  @Prop({ required: true }) readonly status!: string;
  snackbarMessage = "";
  learningPathStore = LearningPathModule;
  $refs!: {
    confirmDeleteDialog: OsdiConfirmDialog;
    snackbar: OsdiSnackbar;
  };

  async deleteLPath() {
    const isLPathDeleted = await this.learningPathStore.deleteLearningPath();
    if (!isLPathDeleted) {
      this.$refs.snackbar.open();
      this.snackbarMessage = this.$t(
        "smartcollege.learningPathdetails.delete.snackbar.wrong"
      ) as string;
      return;
    }
    this.$router.push({
      name: ROUTES.SmartCollegeLearningPaths,
    });
  }

  onClickDelete() {
    this.$refs.confirmDeleteDialog.open({
      confirmCallback: this.deleteLPath,
      message: this.$t("smartcollege.list.deleteNotification.message", {
        name: this.learningPathStore.learningPathUI.title,
      }) as string,
    });
  }

  @Emit()
  onClickEdit() {}

  @Emit()
  onClickSave() {}

  @Emit()
  onClickBack() {
    this.$router.push({
      name: ROUTES.SmartCollegeLearningPaths,
    });
  }

  @Emit()
  onClickTogglePublish() {}
}
