
import Vue from "vue";
import { Component, Emit, Prop } from "vue-property-decorator";
import LearningPathModule from "@/store/modules/learningPath-store";

@Component
export default class PublishBtn extends Vue {
  @Prop({ required: true }) draft!: boolean;
  learningPathStore = LearningPathModule;

  @Emit()
  onClick() {}
}
