
import { Component, Prop, Vue, Emit, Watch } from "vue-property-decorator";
import OsdiIconButton from "shared-alva/components/buttons/OsdiIconButton.vue";
import OsdiButton from "osdi-design-system/molecules/button/button.vue";
import OsdiTextField from "shared-alva/components/inputs/OsdiTextField.vue";
import OsdiTextArea from "shared-alva/components/inputs/OsdiTextArea.vue";
import { languages, availableLanguages } from "shared-alva/languages";
import SublessonStore from "@/store/modules/sublessons-store_v2";
import LessonsStore from "@/store/modules/lessons-store_v2";
import { PickerElement } from "@/models";
import { getModule } from "vuex-module-decorators";
import QuestionModule from "@/store/modules/questions-store";
import TrainingsModule from "@/store/modules/trainings-store_v2";

const maxSublessonsToDisplay = 5;

@Component({
  components: { OsdiTextArea, OsdiTextField, OsdiButton, OsdiIconButton },
})
export default class ElementPreview extends Vue {
  @Prop()
  element!: PickerElement;

  @Prop({ default: false, type: Boolean })
  isLearningPath!: boolean;

  @Prop({ default: false, type: Boolean })
  deletable!: boolean;

  @Prop()
  removeElement!: (element: PickerElement) => void;

  @Prop({ default: false, type: Boolean })
  readonly!: boolean;

  @Prop({ default: false, type: Boolean })
  dense!: boolean;

  availableVersions: string[] = [];
  questionStore = getModule(QuestionModule);

  get languages() {
    return availableLanguages;
  }

  async created() {
    await this.onLanguageChange();
  }

  @Watch("element.language", { immediate: true })
  @Watch("element.version", { deep: true })
  async onLanguageChange() {
    try {
      if (!this.element || this.element.language == undefined) return;

      if (this.element.type == "sublesson") {
        const sublessons = await SublessonStore.getAllSublessonVersions(
          this.element.language as languages
        );
        const versions = sublessons.find((el) => {
          return el.id == this.element.id;
        });
        this.availableVersions = await SublessonStore.getAvailableVersions(this.element);
      } else if (this.element.type == "lesson") {
        const lessons = await LessonsStore.getAllLessonVersions(this.element.language as languages);
        const versions = lessons.find((el) => {
          return el.id == this.element.id;
        });
        this.availableVersions = await LessonsStore.getAvailableVersions(this.element);
      } else if (this.element.type == "training") {
        this.availableVersions = await TrainingsModule.getAvailableVersions(this.element);
      } else {
        this.availableVersions = await this.questionStore.getAvailableVersions(this.element);
      }
    } catch (error) {
      return this.languageChange(false);
    }

    if (this.availableVersions.length == 0) {
      return this.languageChange(false);
    } else {
      return this.languageChange(true);
    }
  }

  @Emit("language-change")
  languageChange(result: boolean) {
    return result;
  }

  get icon() {
    return this.element.type == "sublesson" ? "ic_sublesson.svg" : "ic_lesson.svg";
  }

  get isSublesson() {
    return this.element.type == "sublesson";
  }

  get numberOfChildSublessons() {
    return 0;
    // return this.isSublesson ? 0 : (this.element as Lesson).sublessons.length;
  }

  get childSublessonDifferenceToMax() {
    return this.numberOfChildSublessons - maxSublessonsToDisplay;
  }

  get childSublessons() {
    if (this.isSublesson) {
      return [];
    }
  }

  get isDraft() {
    return this.element.status !== "published";
  }

  get otherLanguage() {
    return this.element.language != this.element.parentLanguage;
  }

  get noVersionInLanguage() {
    const availableVersions = this.availableVersions.length === 0;

    return availableVersions;
  }
}
