import logger from "@/logger";
import router from "@/router";
import Auth, { CognitoUser } from "@aws-amplify/auth";
import AuthModule from "@/store/modules/auth-store";
import { getModule } from "vuex-module-decorators";

export async function getCurrentAuthenticatedUser(): Promise<CognitoUser | any> {
  return Auth.currentAuthenticatedUser();
}

export async function getTenant(): Promise<string> {
  const session = await Auth.currentSession();
  return session.getIdToken().payload["custom:tenant"] as string;
}

export async function hasPermission(permission: string): Promise<boolean> {
  const authStore = getModule(AuthModule);

  if (authStore.userPermissions.length) {
    return !!authStore.userPermissions.find((perm) => perm === permission);
  } else {
    await authStore.requestUserPermissions();
    if (!authStore.userPermissions.length) {
      await Auth.signOut();
      try {
        authStore.logout();
        router.push({ name: "Login", params: { to: "/" } }).catch(() => {});
      } catch (error) {
        logger.error("No Permissions");
      }
    }
    return !!authStore.userPermissions.find((perm) => perm === permission);
  }
}

export async function getAllStatus(element: string): Promise<string[]> {
  const authStore = getModule(AuthModule);
  let currentPermissions = authStore.userPermissions;

  currentPermissions = currentPermissions
    .filter((items) => items.startsWith(`update${element}:`))
    .map((permission) => permission.split(":")[2]);

  return currentPermissions;
}

export async function getJwToken(): Promise<string> {
  const session = await Auth.currentSession();
  return session.getIdToken().getJwtToken();
}

export async function signOut() {
  return Auth.signOut()
    .then(() => router.push("/login"))
    .catch((error) => {
      logger.error("Unable to log user out.", error);
    });
}
