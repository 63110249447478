import { Action, Module, Mutation, getModule, VuexModule } from "vuex-module-decorators";
import store from "@/store/index";

@Module({ dynamic: true, namespaced: true, store, name: "uistate" })
class UIState extends VuexModule {
  saveButtonIsPressed: boolean = false;
  warningsDialogIsOpen: boolean = false;
  searchInput: string = "";

  get getSaveButtonIsPressed() {
    return this.saveButtonIsPressed;
  }
  get getSearchInput() {
    return this.searchInput;
  }

  get getWarningsDialogIsOpen() {
    return this.warningsDialogIsOpen;
  }

  @Mutation
  SET_SAVEBUTTONSTATUS(value: boolean) {
    this.saveButtonIsPressed = value;
  }

  @Mutation
  SET_SEARCHINPUT(value: string) {
    this.searchInput = value;
  }

  @Action({ commit: "SET_SAVEBUTTONSTATUS" })
  applySaveButtonStatus(value: boolean) {
    return value;
  }

  @Action({ commit: "SET_SEARCHINPUT" })
  setSearchInput(value: string) {
    return value;
  }

  @Mutation
  setWarningsDialogState(value: boolean) {
    this.warningsDialogIsOpen = value;
  }
}

export default getModule(UIState);
