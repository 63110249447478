
import Vue from "vue";
import { Component, Emit, Prop } from "vue-property-decorator";

import { TableItemDefault } from "@/components/tableTools/TableItem.interface";
import { TableMenuAction } from "@/models";
import OsdiMenuOption from "@/components/tableTools/MenuOptions.vue";
import OsdiExpandBtn from "@/components/tableTools/ExpandBtn.vue";

@Component({ components: { OsdiMenuOption, OsdiExpandBtn } })
export default class SublessonTableItem extends Vue {
  @Prop({ required: true }) readonly item!: TableItemDefault;
  @Prop({ required: true }) readonly tableMenu!: TableMenuAction[];
  @Prop({ default: false }) readonly isExpanded!: boolean;
  readonly targetGroupList = this.item.latestPublished
    ? this.item.latestPublished.targetGroups
    : this.item.latest.targetGroups;
  readonly assetTypesList = this.item.latestPublished
    ? this.item.latestPublished.assetTypes
    : this.item.latest.assetTypes;

  hasDraftChild() {
    return (
      this.item.draft &&
      this.item.publishedVersions &&
      Object.keys(this.item.publishedVersions)?.length > 0
    );
  }

  @Emit()
  onClickRow() {}

  extractFirstLetter(text: string) {
    return text && text[0];
  }

  @Emit()
  onClickExpandItem() {}
}
