
import Vue from "vue";
import { Component, Emit, Prop } from "vue-property-decorator";
import OsdiButton from "osdi-design-system/molecules/button/button.vue";

@Component({ components: { OsdiButton } })
export default class TableFooter extends Vue {
  @Prop() readonly createBtnLabel!: string;

  @Emit()
  onCreate() {}
}
