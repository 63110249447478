
import { Component, Vue } from "vue-property-decorator";

import { getModule } from "vuex-module-decorators";
import DialogStore from "@/store/modules/dialog-store";
import OsdiDialog from "shared-alva/components/dialogs/OsdiDialog.vue";
import SublessonDetail from "@/components/sublessons/SublessonDetail.vue";
import LessonDetail from "@/components/lessons/LessonDetail.vue";
import LessonCreate from "@/components/lessons/LessonCreate.vue";
import TrainingDetail from "@/components/trainings/TrainingDetail.vue";
import TrainingCreate from "@/components/trainings/TrainingCreate.vue";
import SublessonCreate from "@/components/sublessons/SublessonCreate.vue";
import DefaultLayoutSidebar from "shared-alva/components/layouts/sidebar/DefaultLayoutSidebar.vue";
import { SidebarItems, SidebarLogoProps } from "shared-alva/interfaces/sidebarProps";
import SideBarItems from "@/sidebar-items";
import TopBar from "@/components/TopBar.vue";

import NewVersionDialog from "@/components/NewVersionDialog.vue";
import LanguageModule from "@/store/modules/language-store";
import sidebarItems from "@/sidebar-items";
import { LanguageSwitcherProps } from "shared-alva/interfaces/language-switcherProps";
import { VApp, VMain } from "vuetify/lib";

const dialogStore = getModule(DialogStore);

@Component({
  components: {
    DefaultLayoutSidebar,
    OsdiDialog,
    SublessonDetail,
    SublessonCreate,
    LessonCreate,
    LessonDetail,
    TrainingDetail,
    TrainingCreate,
    TopBar,
    NewVersionDialog,
  },
})
export default class App extends Vue {
  languageStore = getModule(LanguageModule);

  get isLoggedIn() {
    return this.$route.name !== "Login";
  }

  get currentDialog() {
    return dialogStore.getComponentName;
  }

  get componentData() {
    return dialogStore.data;
  }

  closeDialog() {
    dialogStore.closeDialog();
  }
  get sidebarItems(): SidebarItems {
    return {
      topItems: SideBarItems(this.$i18n).top(),
      bottomItems: SideBarItems(this.$i18n).bottom(),
      typeApp: SideBarItems(this.$i18n).typeApp(),
    };
  }

  get languageSwitcherProps(): LanguageSwitcherProps {
    return {
      availableLanguages: this.$i18n.availableLocales,
      onClickHandler: (newLanguage: string) => {
        this.$i18n.locale = newLanguage;
        this.languageStore.setLanguage(newLanguage);
      },
    };
  }

  get sidebarLogoProps(): SidebarLogoProps {
    return {
      imgVariant: require("shared-alva/assets/images/sidebar-logo.svg"),
      textVariant: require("shared-alva/assets/images/sidebar-logo-text.svg"),
    };
  }
}
